// src/services/adminService.js
import { db, auth, storage } from '../config/firebase.config';
import { doc, updateDoc, deleteDoc, collection, getDocs, query, where, addDoc, orderBy, limit, getCountFromServer, getDoc, writeBatch } from 'firebase/firestore';
import { ref, listAll, deleteObject } from 'firebase/storage';

import { handleError } from '../utils/errorHandler';

export const disableUserAndIntegrations = async (userId) => {
    if (!userId) {
        throw new Error('User ID is required to disable user and integrations');
    }

    const userRef = doc(db, 'users', userId);

    try {
        const userDoc = await getDoc(userRef);
        if (!userDoc.exists()) {
            throw new Error('User not found');
        }

        const userData = userDoc.data();
        const currentIntegrations = userData.integrations || {};

        // Desactivar todas las integraciones
        const updatedIntegrations = Object.keys(currentIntegrations).reduce((acc, key) => {
            acc[key] = { ...currentIntegrations[key], enabled: false };
            return acc;
        }, {});

        // Actualizar el documento del usuario
        await updateDoc(userRef, {
            disabled: true,
            integrations: updatedIntegrations
        });

        // // Registrar la actividad del administrador
        // await logAdminActivity('adminId', 'disableUserAndIntegrations', { userId });

        return true;
    } catch (error) {
        console.error('Error in disableUserAndIntegrations:', error);
        throw handleError(error);
    }
};

export const disableUser = async (userId) => {
    await disableUserAndIntegrations(userId);
};

export const enableUser = async (userId) => {
    await updateDoc(doc(db, 'users', userId), { disabled: false });
};

export const getTotalUsers = async () => {
    const usersRef = collection(db, 'users');
    const snapshot = await getCountFromServer(usersRef);
    return snapshot.data().count;
};

export const getTotalJobs = async () => {
    const jobsRef = collection(db, 'jobs');
    const snapshot = await getCountFromServer(jobsRef);
    return snapshot.data().count;
};

export const getTotalCandidates = async () => {
    const candidatesRef = collection(db, 'candidates');
    const snapshot = await getCountFromServer(candidatesRef);
    return snapshot.data().count;
};

export const getAllJobs = async () => {
    const jobsRef = collection(db, 'jobs');
    const querySnapshot = await getDocs(jobsRef);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

export const getAllUsers = async () => {
    const usersRef = collection(db, 'users');
    const querySnapshot = await getDocs(usersRef);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

export const deleteJob = async (jobId) => {
    if (!jobId) {
        throw new Error('Job ID is required');
    }

    await deleteDoc(doc(db, 'jobs', jobId));

    // Eliminar candidatos asociados al trabajo
    const candidatesQuery = query(collection(db, 'candidates'), where('jobId', '==', jobId));
    const candidatesSnapshot = await getDocs(candidatesQuery);
    candidatesSnapshot.forEach(async (doc) => {
        await deleteDoc(doc.ref);
    });
};

export const deleteManyJobs = async (jobIds) => {
    if (!jobIds || jobIds.length === 0) {
        throw new Error('Job IDs are required to delete multiple jobs');
    }

    const batch = writeBatch(db);

    jobIds.forEach((id) => {
        const jobRef = doc(db, 'jobs', id);
        batch.delete(jobRef);

        // También eliminamos los candidatos asociados a este trabajo
        const candidatesQuery = query(collection(db, 'candidates'), where('jobId', '==', id));
        getDocs(candidatesQuery).then((snapshot) => {
            snapshot.docs.forEach((doc) => {
                batch.delete(doc.ref);
            });
        });
    });

    try {
        await batch.commit();
    } catch (error) {
        throw handleError(error);
    }
};

const logAdminActivity = async (adminId, action, details) => {
    await addDoc(collection(db, 'adminLogs'), {
        adminId,
        action,
        details,
        timestamp: new Date()
    });
};

export const getRecentUsers = async (userLimit = 5) => {
    const q = query(collection(db, 'users'), orderBy('createdAt', 'desc'), limit(userLimit));
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

export const getRecentJobs = async (jobLimit = 5) => {
    const q = query(collection(db, 'jobs'), orderBy('createdAt', 'desc'), limit(jobLimit));
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

export const getUserJobCount = async (userId) => {
    if (!userId) {
        return 0;
    }
    const jobsRef = collection(db, 'jobs');
    const q = query(jobsRef, where('userId', '==', userId));
    const snapshot = await getCountFromServer(q);
    return snapshot.data().count;
};

export const getJobCandidateCount = async (jobId) => {
    if (!jobId) {
        return 0;
    }
    const candidatesRef = collection(db, 'candidates');
    const q = query(candidatesRef, where('jobId', '==', jobId));
    const snapshot = await getCountFromServer(q);
    return snapshot.data().count;
};

export const getUserDetails = async (userId) => {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (!userDoc.exists()) {
        throw new Error('User not found');
    }
    return { id: userDoc.id, ...userDoc.data() };
};

export const getUserJobs = async (userId) => {
    const jobsQuery = query(collection(db, 'jobs'), where('userId', '==', userId));
    const querySnapshot = await getDocs(jobsQuery);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

export const getJobDetails = async (jobId) => {
    if (!jobId) {
        throw new Error('Job ID is required');
    }
    const jobDoc = await getDoc(doc(db, 'jobs', jobId));
    if (!jobDoc.exists()) {
        throw new Error('Job not found');
    }
    return { id: jobDoc.id, ...jobDoc.data() };
};

export const getJobCandidates = async (jobId) => {
    if (!jobId) {
        throw new Error('Job ID is required');
    }
    const candidatesQuery = query(collection(db, 'candidates'), where('jobId', '==', jobId));
    const querySnapshot = await getDocs(candidatesQuery);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

// Implementar otras funciones necesarias para el panel de administración