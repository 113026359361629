import React from 'react';
import { Link } from 'react-router-dom';
import { CalendarDays, Clock, User, ChevronRight, Globe, Search, Briefcase, Star } from 'lucide-react';
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { AnimatedPage } from '../../utils/animations';

export const articleMeta = {
    title: "10 Best Job Sites in the UK for 2024",
    description: "Discover the top job sites in the UK to help you find your next career opportunity or the perfect candidate for your company.",
    date: "August 11, 2024",
    readTime: "8 min read",
};

const BestJobSitesUK = () => {
    const { title, description, date, readTime } = articleMeta;

    const jobSites = [
        {
            name: "Indeed",
            description: "One of the largest job search engines globally, with a strong presence in the UK.",
            features: ["Vast number of job listings", "Company reviews", "Salary information"]
        },
        {
            name: "Reed",
            description: "A popular UK-specific job board with a wide range of industries and roles.",
            features: ["Career advice section", "Salary calculator", "Course listings"]
        },
        {
            name: "CV-Library",
            description: "One of the UK's largest independent job boards with millions of registered candidates.",
            features: ["CV database", "Recruitment solutions for employers", "Career advice"]
        },
        {
            name: "Totaljobs",
            description: "A leading UK job board offering a range of tools for job seekers and employers.",
            features: ["Personality assessments", "Interview tips", "Salary checker"]
        },
        {
            name: "LinkedIn",
            description: "Professional networking site with a robust job board and recruitment features.",
            features: ["Professional networking", "Company insights", "Skill assessments"]
        },
        {
            name: "Guardian Jobs",
            description: "Job board associated with The Guardian newspaper, known for quality listings.",
            features: ["Focus on professional and public sector roles", "Career advice articles", "Email job alerts"]
        },
        {
            name: "Jobsite",
            description: "Part of the Totaljobs Group, offering a wide range of job opportunities across the UK.",
            features: ["Advanced search filters", "Career advice hub", "Mobile app"]
        },
        {
            name: "Monster",
            description: "Global job board with a strong UK presence and a variety of tools for job seekers.",
            features: ["Resume assessment", "Career advice articles", "Company profiles"]
        },
        {
            name: "Adzuna",
            description: "Job search engine that aggregates listings from various sources across the UK.",
            features: ["Salary stats", "Company reviews", "Value My CV tool"]
        },
        {
            name: "Glassdoor",
            description: "Known for company reviews and insights, also offering a comprehensive job board.",
            features: ["Company reviews and ratings", "Salary information", "Interview questions and reviews"]
        }
    ];

    return (
        <AnimatedPage>
            <article className="max-w-5xl mx-auto px-4 py-12">
                {/* Breadcrumb */}
                <nav className="flex mb-8 text-sm" aria-label="Breadcrumb">
                    <ol className="inline-flex items-center space-x-1 md:space-x-3">
                        <li className="inline-flex items-center">
                            <Link to="/" className="inline-flex items-center text-muted-foreground hover:text-primary">
                                Home
                            </Link>
                        </li>
                        <li>
                            <div className="flex items-center">
                                <ChevronRight className="w-4 h-4 text-muted-foreground" />
                                <Link to="/blog" className="ml-1 text-muted-foreground hover:text-primary md:ml-2">
                                    Blog
                                </Link>
                            </div>
                        </li>
                        <li aria-current="page">
                            <div className="flex items-center">
                                <ChevronRight className="w-4 h-4 text-muted-foreground" />
                                <span className="ml-1 text-primary md:ml-2">Best Job Sites UK</span>
                            </div>
                        </li>
                    </ol>
                </nav>

                <header className="mb-12 text-center">
                    <h1 className="text-5xl font-bold mb-6">{title}</h1>
                    <div className="flex items-center justify-center text-muted-foreground mb-6">
                        <User className="mr-2 h-5 w-5" />
                        <span className="mr-4">PrimeApplicants Team</span>
                        <CalendarDays className="mr-2 h-5 w-5" />
                        <span className="mr-4">{date}</span>
                        <Clock className="mr-2 h-5 w-5" />
                        <span>{readTime}</span>
                    </div>
                    <p className="text-xl text-muted-foreground max-w-3xl mx-auto">{description}</p>
                </header>

                <section className="prose max-w-none mb-12">
                    <h2 className="text-3xl font-bold mb-6">The UK Job Market Landscape</h2>
                    <p>The UK job market is dynamic and competitive, with a diverse range of industries and opportunities. As of 2024, the job search process has become increasingly digital, with online job boards playing a crucial role in connecting employers with potential candidates. In fact, recent statistics show that over 64% of job searches in the UK are conducted through online job boards.</p>
                    <p>Whether you're a job seeker looking for your next career move or an employer seeking to attract top talent, knowing the best job sites in the UK is essential. This guide will explore the top 10 job sites that stand out in the UK market, helping you navigate the vast landscape of online job searching and recruitment.</p>
                </section>

                <section className="mb-16">
                    <h2 className="text-3xl font-bold mb-8">Top 10 Job Sites in the UK</h2>
                    <div className="grid md:grid-cols-2 gap-8">
                        {jobSites.map((site, index) => (
                            <Card key={index} className="hover:shadow-lg transition-shadow duration-300">
                                <CardHeader>
                                    <CardTitle className="text-2xl mb-2">{index + 1}. {site.name}</CardTitle>
                                </CardHeader>
                                <CardContent>
                                    <p className="mb-4">{site.description}</p>
                                    <h4 className="font-semibold mb-2">Key Features:</h4>
                                    <ul className="list-disc pl-5 space-y-1">
                                        {site.features.map((feature, fIndex) => (
                                            <li key={fIndex}>{feature}</li>
                                        ))}
                                    </ul>
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                </section>

                {/* Banner de llamado a la acción adicional */}
                <section className="bg-primary text-primary-foreground py-12 px-8 rounded-lg mb-16">
                    <h2 className="text-3xl font-bold mb-4">Streamline Your Job Search and Recruitment Process</h2>
                    <p className="mb-6">Use PrimeApplicants to manage your job applications or streamline your hiring process across multiple UK job boards. Start your free trial today and experience the difference.</p>
                    <Button asChild size="lg" variant="secondary">
                        <Link to="/register">Start Free Trial</Link>
                    </Button>
                </section>

                <section className="prose max-w-none mb-12">
                    <h2 className="text-3xl font-bold mb-6">Making the Most of UK Job Sites</h2>
                    <p>To maximize your success on these platforms, consider the following tips:</p>
                    <ul className="list-disc pl-5 space-y-2">
                        <li><strong>Tailor your approach:</strong> Each platform has its strengths. Use Indeed for a wide range of options, LinkedIn for professional networking, and specialized sites like Guardian Jobs for specific industries.</li>
                        <li><strong>Optimize your profile:</strong> Ensure your CV and profile are up-to-date and keyword-optimized for your desired roles.</li>
                        <li><strong>Set up job alerts:</strong> Most of these platforms offer email notifications for new relevant listings. Use these to stay on top of opportunities.</li>
                        <li><strong>Research companies:</strong> Utilize company reviews and insights available on platforms like Glassdoor to inform your applications or hiring decisions.</li>
                        <li><strong>Leverage networking features:</strong> Especially on LinkedIn, engage with industry professionals and join relevant groups to expand your opportunities.</li>
                        <li><strong>Consider niche job boards:</strong> While not in our top 10, niche job boards for specific industries can be valuable for specialized roles.</li>
                    </ul>
                </section>

                <section className="prose max-w-none mb-12">
                    <h2 className="text-3xl font-bold mb-6">The Future of Job Search in the UK</h2>
                    <p>As the job market continues to evolve, we can expect these platforms to innovate with new features such as:</p>
                    <ul className="list-disc pl-5 space-y-2">
                        <li>Enhanced AI-driven job matching algorithms</li>
                        <li>Virtual reality job fairs and interviews</li>
                        <li>Increased focus on remote and flexible work options</li>
                        <li>Integration of skills assessments and micro-credentialing</li>
                        <li>Greater emphasis on diversity and inclusion in hiring practices</li>
                    </ul>
                    <p>Staying informed about these trends and leveraging the right platforms can give both job seekers and employers a significant advantage in the UK job market.</p>
                </section>

                <section className="prose max-w-none mb-12">
                    <h2 className="text-3xl font-bold mb-6">Conclusion</h2>
                    <p>The UK job market offers a wealth of opportunities, and these top job sites provide excellent platforms for connecting talent with employers. Whether you're a job seeker looking to advance your career or an employer seeking top talent, utilizing these platforms effectively can significantly improve your chances of success.</p>
                    <p>Remember, while these sites are powerful tools, they're most effective when used as part of a comprehensive job search or recruitment strategy. Combine online job searching with networking, skill development, and targeted applications or hiring practices for the best results.</p>
                </section>
            </article>
            {/* Llamado a la acción estándar */}
            <section className="bg-secondary text-secondary-foreground py-20 text-center">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold mb-4">Ready to Elevate Your Job Search or Recruitment Process?</h2>
                    <p className="text-xl mb-8">Try PrimeApplicants and experience how our AI-powered platform can streamline your hiring or job application process across multiple UK job boards.</p>
                    <Button size="lg" asChild>
                        <Link to="/register">Start Your Free Trial Today</Link>
                    </Button>
                </div>
            </section>
        </AnimatedPage>
    );
};

export default BestJobSitesUK;