import React from 'react';
import { Link } from 'react-router-dom';
import { CalendarDays, Clock, User, ChevronRight, ExternalLink } from 'lucide-react';
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { AnimatedPage } from '../../utils/animations';

export const articleMeta = {
    title: "8 Best Job Sites in Australia for 2024",
    description: "Discover the top job sites in Australia to help you find your next career opportunity or the perfect candidate for your company.",
    date: "August 14, 2024",
    readTime: "6 min read",
};

const JobSitesAustralia = () => {
    const { title, description, date, readTime } = articleMeta;

    const jobSites = [
        {
            name: "Seek",
            description: "Australia's largest job site with millions of visitors monthly.",
            features: ["Wide range of industries", "Advanced search filters", "Company reviews"]
        },
        {
            name: "Indeed",
            description: "Global job search engine with a strong presence in Australia.",
            features: ["Free job postings", "Resume database", "Company pages"]
        },
        {
            name: "LinkedIn",
            description: "Professional networking site with a robust job board.",
            features: ["Professional networking", "Company insights", "Skill assessments"]
        },
        {
            name: "CareerOne",
            description: "Australian job board offering various recruitment solutions.",
            features: ["Job alerts", "Career advice articles", "Resume builder"]
        },
        {
            name: "Jora",
            description: "Job search engine aggregating listings from various sources.",
            features: ["Local job search", "Mobile app", "Easy application process"]
        },
        {
            name: "Adzuna",
            description: "UK-based job search engine with a growing Australian presence.",
            features: ["Salary statistics", "Company reviews", "CV upload"]
        },
        {
            name: "Gumtree",
            description: "Classifieds site with a substantial job section.",
            features: ["Local job listings", "Casual and part-time work", "Direct employer contact"]
        },
        {
            name: "Ethical Jobs",
            description: "Focused on non-profit and community sector jobs.",
            features: ["Socially responsible jobs", "Environmental sector focus", "Community-oriented roles"]
        }
    ];

    return (
        <AnimatedPage>
            <article className="max-w-5xl mx-auto px-4 py-12">
                {/* Breadcrumb */}
                <nav className="flex mb-8 text-sm" aria-label="Breadcrumb">
                    <ol className="inline-flex items-center space-x-1 md:space-x-3">
                        <li className="inline-flex items-center">
                            <Link to="/" className="inline-flex items-center text-muted-foreground hover:text-primary">
                                Home
                            </Link>
                        </li>
                        <li>
                            <div className="flex items-center">
                                <ChevronRight className="w-4 h-4 text-muted-foreground" />
                                <Link to="/blog" className="ml-1 text-muted-foreground hover:text-primary md:ml-2">
                                    Blog
                                </Link>
                            </div>
                        </li>
                        <li aria-current="page">
                            <div className="flex items-center">
                                <ChevronRight className="w-4 h-4 text-muted-foreground" />
                                <span className="ml-1 text-primary md:ml-2">Best Job Sites in Australia</span>
                            </div>
                        </li>
                    </ol>
                </nav>

                <header className="mb-12 text-center">
                    <h1 className="text-5xl font-bold mb-6">{title}</h1>
                    <div className="flex items-center justify-center text-muted-foreground mb-6">
                        <User className="mr-2 h-5 w-5" />
                        <span className="mr-4">PrimeApplicants Team</span>
                        <CalendarDays className="mr-2 h-5 w-5" />
                        <span className="mr-4">{date}</span>
                        <Clock className="mr-2 h-5 w-5" />
                        <span>{readTime}</span>
                    </div>
                    <p className="text-xl text-muted-foreground max-w-3xl mx-auto">{description}</p>
                </header>

                <section className="prose max-w-none mb-12">
                    <p>The Australian job market is dynamic and competitive, with numerous online platforms catering to job seekers and employers. Whether you're looking for your next career move or trying to find the best talent for your organization, knowing where to look is crucial.</p>
                    <p>In this article, we'll explore the eight best job sites in Australia, highlighting their unique features and benefits to help you make the most of your job search or recruitment efforts.</p>
                </section>

                <section className="mb-16">
                    <h2 className="text-3xl font-bold mb-8">Top Job Sites in Australia</h2>
                    <div className="grid md:grid-cols-2 gap-8">
                        {jobSites.map((site, index) => (
                            <Card key={index} className="hover:shadow-lg transition-shadow duration-300">
                                <CardHeader>
                                    <CardTitle className="text-2xl mb-2">{site.name}</CardTitle>
                                </CardHeader>
                                <CardContent>
                                    <p className="mb-4">{site.description}</p>
                                    <h4 className="font-semibold mb-2">Key Features:</h4>
                                    <ul className="list-disc pl-5 space-y-1">
                                        {site.features.map((feature, fIndex) => (
                                            <li key={fIndex}>{feature}</li>
                                        ))}
                                    </ul>
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                </section>

                {/* Banner de llamado a la acción adicional */}
                <section className="bg-primary text-primary-foreground py-12 px-8 rounded-lg mb-16">
                    <h2 className="text-3xl font-bold mb-4">Streamline Your Job Search and Recruitment Process</h2>
                    <p className="mb-6">Use PrimeApplicants to manage your job applications or streamline your hiring process. Start your free trial today and experience the difference.</p>
                    <Button asChild size="lg" variant="secondary">
                        <Link to="/register">Start Free Trial</Link>
                    </Button>
                </section>

                <section className="prose max-w-none mb-12">
                    <h2 className="text-3xl font-bold mb-6">Making the Most of Australian Job Sites</h2>
                    <p>To maximize your success on these platforms, consider the following tips:</p>
                    <ul className="list-disc pl-5 space-y-2">
                        <li><strong>Tailor your approach:</strong> Each platform has its strengths. Use Seek for a wide range of options, LinkedIn for professional networking, and specialized sites like Ethical Jobs for niche sectors.</li>
                        <li><strong>Optimize your profile:</strong> Ensure your resume and profile are up-to-date and keyword-optimized for your desired roles.</li>
                        <li><strong>Set up job alerts:</strong> Most of these platforms offer email notifications for new relevant listings. Use these to stay on top of opportunities.</li>
                        <li><strong>Research companies:</strong> Utilize company reviews and insights available on platforms like Seek and Indeed to inform your applications or hiring decisions.</li>
                        <li><strong>Leverage networking features:</strong> Especially on LinkedIn, engage with industry professionals and join relevant groups to expand your opportunities.</li>
                        <li><strong>Consider local options:</strong> For certain roles, platforms like Gumtree can be excellent for finding local or casual work opportunities.</li>
                    </ul>
                </section>

                <section className="prose max-w-none mb-12">
                    <h2 className="text-3xl font-bold mb-6">The Future of Job Search in Australia</h2>
                    <p>As the job market continues to evolve, we can expect these platforms to innovate with new features such as:</p>
                    <ul className="list-disc pl-5 space-y-2">
                        <li>Enhanced AI-driven job matching algorithms</li>
                        <li>Virtual reality job fairs and interviews</li>
                        <li>Increased focus on remote and flexible work options</li>
                        <li>Integration of skills assessments and micro-credentialing</li>
                        <li>Greater emphasis on diversity and inclusion in hiring practices</li>
                    </ul>
                    <p>Staying informed about these trends and leveraging the right platforms can give both job seekers and employers a significant advantage in the Australian job market.</p>
                </section>

                <section className="prose max-w-none mb-12">
                    <h2 className="text-3xl font-bold mb-6">Conclusion</h2>
                    <p>The Australian job market offers a wealth of opportunities, and these top job sites provide excellent platforms for connecting talent with employers. Whether you're a job seeker looking to advance your career or an employer seeking top talent, utilizing these platforms effectively can significantly improve your chances of success.</p>
                    <p>Remember, while these sites are powerful tools, they're most effective when used as part of a comprehensive job search or recruitment strategy. Combine online job searching with networking, skill development, and targeted applications or hiring practices for the best results.</p>
                </section>
            </article>
            {/* Llamado a la acción estándar */}
            <section className="bg-secondary text-secondary-foreground py-20 text-center">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold mb-4">Ready to Elevate Your Job Search or Recruitment Process?</h2>
                    <p className="text-xl mb-8">Try PrimeApplicants and experience how our AI-powered platform can streamline your hiring or job application process.</p>
                    <Button size="lg" asChild>
                        <Link to="/register">Start Your Free Trial Today</Link>
                    </Button>
                </div>
            </section>
        </AnimatedPage>
    );
};

export default JobSitesAustralia;