import React from 'react';
import { Link } from 'react-router-dom';
import { CalendarDays, Clock, User, ChevronRight, ExternalLink } from 'lucide-react';
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { AnimatedPage } from '../../utils/animations';

export const articleMeta = {
    title: "8 Best Free Job Posting Sites in the Philippines for 2024",
    description: "Discover the top free job posting sites in the Philippines to help you find the best talent without breaking the bank.",
    date: "August 20, 2024",
    readTime: "6 min read",
};

const FreeJobPostingSitesPhilippines = () => {
    const { title, description, date, readTime } = articleMeta;

    const jobSites = [
        {
            name: "JobStreet",
            description: "One of the largest job portals in Southeast Asia with a strong presence in the Philippines.",
            features: ["Wide range of job categories", "Advanced search filters", "Company reviews"]
        },
        {
            name: "Kalibrr",
            description: "A popular job matching platform in the Philippines using AI technology.",
            features: ["AI-powered job matching", "Skills assessments", "Mobile app"]
        },
        {
            name: "Indeed Philippines",
            description: "The local version of the global job search engine, tailored for the Philippine market.",
            features: ["Free job postings", "Resume database", "Company pages"]
        },
        {
            name: "LinkedIn",
            description: "Professional networking site with a robust job board, popular among Filipino professionals.",
            features: ["Professional networking", "Company insights", "Skill endorsements"]
        },
        {
            name: "PhilJobNet",
            description: "The official job portal of the Philippine government.",
            features: ["Government-endorsed listings", "Local and overseas jobs", "Free for both employers and job seekers"]
        },
        {
            name: "Bossjob",
            description: "A job search platform focusing on white-collar jobs in the Philippines.",
            features: ["Chat-based application process", "Salary information", "Company profiles"]
        },
        {
            name: "Mynimo",
            description: "A job board specializing in jobs outside Metro Manila.",
            features: ["Focus on provincial job opportunities", "Industry-specific job categories", "Company directories"]
        },
        {
            name: "Trabaho.ph",
            description: "A Filipino job portal catering to various industries and job levels.",
            features: ["Local job focus", "Resume builder", "Career resources"]
        }
    ];

    return (
        <AnimatedPage>
            <article className="max-w-5xl mx-auto px-4 py-12">
                {/* Breadcrumb */}
                <nav className="flex mb-8 text-sm" aria-label="Breadcrumb">
                    <ol className="inline-flex items-center space-x-1 md:space-x-3">
                        <li className="inline-flex items-center">
                            <Link to="/" className="inline-flex items-center text-muted-foreground hover:text-primary">
                                Home
                            </Link>
                        </li>
                        <li>
                            <div className="flex items-center">
                                <ChevronRight className="w-4 h-4 text-muted-foreground" />
                                <Link to="/blog" className="ml-1 text-muted-foreground hover:text-primary md:ml-2">
                                    Blog
                                </Link>
                            </div>
                        </li>
                        <li aria-current="page">
                            <div className="flex items-center">
                                <ChevronRight className="w-4 h-4 text-muted-foreground" />
                                <span className="ml-1 text-primary md:ml-2">Free Job Posting Sites Philippines</span>
                            </div>
                        </li>
                    </ol>
                </nav>

                <header className="mb-12 text-center">
                    <h1 className="text-5xl font-bold mb-6">{title}</h1>
                    <div className="flex items-center justify-center text-muted-foreground mb-6">
                        <User className="mr-2 h-5 w-5" />
                        <span className="mr-4">PrimeApplicants Team</span>
                        <CalendarDays className="mr-2 h-5 w-5" />
                        <span className="mr-4">{date}</span>
                        <Clock className="mr-2 h-5 w-5" />
                        <span>{readTime}</span>
                    </div>
                    <p className="text-xl text-muted-foreground max-w-3xl mx-auto">{description}</p>
                </header>

                <section className="prose max-w-none mb-12">
                    <p>The Philippine job market is vibrant and competitive, with a growing workforce eager to find opportunities in various industries. For employers and recruiters, knowing where to post job openings effectively and efficiently is crucial. Fortunately, there are several free job posting sites in the Philippines that can help you reach a wide pool of qualified candidates.</p>
                    <p>In this article, we'll explore the eight best free job posting sites in the Philippines, highlighting their unique features and benefits to help you optimize your recruitment efforts.</p>
                </section>

                <section className="mb-16">
                    <h2 className="text-3xl font-bold mb-8">Top Free Job Posting Sites in the Philippines</h2>
                    <div className="grid md:grid-cols-2 gap-8">
                        {jobSites.map((site, index) => (
                            <Card key={index} className="hover:shadow-lg transition-shadow duration-300">
                                <CardHeader>
                                    <CardTitle className="text-2xl mb-2">{site.name}</CardTitle>
                                </CardHeader>
                                <CardContent>
                                    <p className="mb-4">{site.description}</p>
                                    <h4 className="font-semibold mb-2">Key Features:</h4>
                                    <ul className="list-disc pl-5 space-y-1">
                                        {site.features.map((feature, fIndex) => (
                                            <li key={fIndex}>{feature}</li>
                                        ))}
                                    </ul>
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                </section>

                {/* Banner de llamado a la acción adicional */}
                <section className="bg-primary text-primary-foreground py-12 px-8 rounded-lg mb-16">
                    <h2 className="text-3xl font-bold mb-4">Streamline Your Recruitment Process in the Philippines</h2>
                    <p className="mb-6">Use PrimeApplicants to manage your job postings across multiple platforms efficiently. Start your free trial today and experience the difference.</p>
                    <Button asChild size="lg" variant="secondary">
                        <Link to="/register">Start Free Trial</Link>
                    </Button>
                </section>

                <section className="prose max-w-none mb-12">
                    <h2 className="text-3xl font-bold mb-6">Tips for Effective Job Posting in the Philippines</h2>
                    <p>To maximize the effectiveness of your job postings on these platforms, consider the following tips:</p>
                    <ul className="list-disc pl-5 space-y-2">
                        <li><strong>Use local language:</strong> While English is widely used, consider including Tagalog or other local languages to reach a broader audience.</li>
                        <li><strong>Highlight company culture:</strong> Filipino job seekers often value company culture and work environment. Showcase what makes your organization unique.</li>
                        <li><strong>Be clear about requirements:</strong> Clearly state the qualifications, skills, and experience needed for the position to attract the most suitable candidates.</li>
                        <li><strong>Optimize for mobile:</strong> Many Filipino job seekers use mobile devices. Ensure your job postings are mobile-friendly.</li>
                        <li><strong>Leverage social media:</strong> Share your job postings on social media platforms popular in the Philippines, such as Facebook and Twitter.</li>
                        <li><strong>Provide competitive information:</strong> Include details about salary range, benefits, and growth opportunities to attract top talent.</li>
                    </ul>
                </section>

                <section className="prose max-w-none mb-12">
                    <h2 className="text-3xl font-bold mb-6">The Future of Job Posting in the Philippines</h2>
                    <p>As the Philippine job market continues to evolve, we can expect these platforms to innovate with new features such as:</p>
                    <ul className="list-disc pl-5 space-y-2">
                        <li>Enhanced AI-driven candidate matching</li>
                        <li>Integration of video interviews and virtual job fairs</li>
                        <li>Increased focus on remote and flexible work options</li>
                        <li>More comprehensive skills assessment tools</li>
                        <li>Greater emphasis on employer branding and company culture showcases</li>
                    </ul>
                    <p>Staying informed about these trends and leveraging the right platforms can give employers a significant advantage in attracting top talent in the Philippines.</p>
                </section>

                <section className="prose max-w-none mb-12">
                    <h2 className="text-3xl font-bold mb-6">Conclusion</h2>
                    <p>The Philippines offers a rich talent pool for employers, and these free job posting sites provide excellent platforms for connecting with potential candidates. By utilizing these platforms effectively and following best practices in job posting, you can significantly improve your recruitment outcomes without incurring substantial costs.</p>
                    <p>Remember, while these sites offer free posting options, consider your specific needs and budget when deciding whether to upgrade to premium features for increased visibility and advanced tools.</p>
                </section>
            </article>
            {/* Llamado a la acción estándar */}
            <section className="bg-secondary text-secondary-foreground py-20 text-center">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold mb-4">Ready to Optimize Your Recruitment in the Philippines?</h2>
                    <p className="text-xl mb-8">Try PrimeApplicants and experience how our AI-powered platform can streamline your hiring process across multiple job boards.</p>
                    <Button size="lg" asChild>
                        <Link to="/register">Start Your Free Trial Today</Link>
                    </Button>
                </div>
            </section>
        </AnimatedPage>
    );
};

export default FreeJobPostingSitesPhilippines;