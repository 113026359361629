import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { verifyEmail } from '../../../services/authService';
import { useAuth } from '../../../contexts/AuthContext';
import { Card, CardHeader, CardContent, CardFooter, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import ErrorAlert from '../../common/ErrorAlert';
import { getUserProfile } from '../../../services/userService';

const VerifyEmail = () => {
    const [verifying, setVerifying] = useState(true);
    const [error, setError] = useState(null);
    const [verificationStatus, setVerificationStatus] = useState('pending');
    const location = useLocation();
    const { setUser } = useAuth();

    useEffect(() => {
        const verifyToken = async () => {
            const params = new URLSearchParams(location.search);
            const token = params.get('token');

            if (!token) {
                setVerificationStatus('no_token');
                setVerifying(false);
                return;
            }

            try {
                const { userId } = await verifyEmail(token);
                const updatedUser = await getUserProfile(userId);
                setUser(updatedUser);
                setVerificationStatus('success');
            } catch (err) {
                console.error('Error verifying email:', err);
                setError(err.message === 'You do not have permission to perform this action.'
                    ? 'You need to be authenticated to verify your email. Please log in and try again.'
                    : err.message || 'An error occurred during email verification');
                setVerificationStatus('error');
            } finally {
                setVerifying(false);
            }
        };

        verifyToken();
    }, [location, setUser]);

    if (verifying) {
        return <div className='text-center mt-8'>Verifying your email...</div>;
    }

    const renderContent = () => {
        switch (verificationStatus) {
            case 'no_token':
                return (
                    <>
                        <CardHeader>
                            <CardTitle className="text-center text-3xl font-extrabold">
                                404 - Page Not Found
                            </CardTitle>
                        </CardHeader>
                        <CardContent>
                            <p className="text-center mb-4">
                                The page you're looking for doesn't exist or the verification link is invalid.
                            </p>
                        </CardContent>
                        <CardFooter>
                            <Link to="/dashboard" className="w-full">
                                <Button className="w-full">Go to Dashboard</Button>
                            </Link>
                        </CardFooter>
                    </>
                );
            case 'success':
                return (
                    <>
                        <CardHeader>
                            <CardTitle className="text-center text-3xl font-extrabold">
                                Email Verification Successful
                            </CardTitle>
                        </CardHeader>
                        <CardContent>
                            <p className="text-center mb-4">
                                Your email has been successfully verified. You can now use the app.
                            </p>
                        </CardContent>
                        <CardFooter>
                            <Link to="/dashboard" className="w-full">
                                <Button className="w-full">Go to Dashboard</Button>
                            </Link>
                        </CardFooter>
                    </>
                );
            case 'error':
                return (
                    <>
                        <CardHeader>
                            <CardTitle className="text-center text-3xl font-extrabold">
                                Email Verification Error
                            </CardTitle>
                        </CardHeader>
                        <CardContent>
                            <ErrorAlert message={error} />
                        </CardContent>
                        <CardFooter>
                            <Link to="/login" className="w-full">
                                <Button className="w-full">Go to Login</Button>
                            </Link>
                        </CardFooter>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <Card className="w-full max-w-md">
                {renderContent()}
            </Card>
        </div>
    );
};

export default VerifyEmail;