// src/components/common/ConsentBanner.js
import React, { useState, useEffect } from 'react';
import { Button } from "@/components/ui/button";

const ConsentBanner = () => {
    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
        const consent = localStorage.getItem('userConsent');
        if (!consent) {
            setTimeout(() => setShowBanner(true), 500);
        }
    }, []);

    const handleAccept = () => {
        setShowBanner(false);
        setTimeout(() => localStorage.setItem('userConsent', 'true'), 300);
    };

    return (
        <div className={`fixed bottom-0 left-0 right-0 bg-background border-t border-border shadow-md transition-all duration-300 ease-in-out ${showBanner ? 'translate-y-0' : 'translate-y-full'}`}>
            <div className="max-w-6xl mx-auto px-4 py-6 flex flex-col items-center justify-center">
                <div className="mb-4 text-center">
                    <h3 className="text-lg font-semibold mb-2">We value your privacy</h3>
                    <p className="text-sm text-muted-foreground max-w-xl">
                        We use cookies to enhance your browsing experience, serve personalized content, and analyze our traffic. By clicking "Accept", you consent to our use of cookies.
                    </p>
                </div>
                <div className="flex space-x-4 mb-4">
                    <Button 
                        variant="outline"
                        className="text-muted-foreground hover:text-foreground"
                        onClick={() => window.open('/privacy-policy', '_blank')}
                    >
                        Learn More
                    </Button>
                    <Button 
                        variant="default"
                        className="bg-primary text-primary-foreground hover:bg-primary/90"
                        onClick={handleAccept}
                    >
                        Accept
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ConsentBanner;