import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { AnimatedPage } from '../utils/animations';
import { CalendarDays, Clock, ChevronLeft, ChevronRight, Search } from 'lucide-react';
import { blogArticles } from '../data/blogArticles';

const BlogListPage = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const postsPerPage = 12;

    const filteredPosts = blogArticles.filter(post =>
        (post.title?.toLowerCase().includes(searchTerm.toLowerCase()) || false) ||
        (post.description?.toLowerCase().includes(searchTerm.toLowerCase()) || false)
    );

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = filteredPosts.slice(indexOfFirstPost, indexOfLastPost);

    const totalPages = Math.ceil(filteredPosts.length / postsPerPage);

    const handleNextPage = () => {
        setCurrentPage(prev => Math.min(prev + 1, totalPages));
    };

    const handlePrevPage = () => {
        setCurrentPage(prev => Math.max(prev - 1, 1));
    };

    return (
        <AnimatedPage>
            <div className="space-y-0">
                {/* Hero Section */}
                <section className="bg-gradient-to-tl from-primary to-muted-foreground text-primary-foreground py-20">
                    <div className="container mx-auto px-4 text-center">
                        <h1 className="text-5xl font-bold mb-4">PrimeApplicants Blog</h1>
                        <p className="text-xl mb-8 max-w-2xl mx-auto">Stay updated with the latest trends and insights in recruitment and HR technology.</p>
                        <div className="max-w-md mx-auto">
                            <div className="relative">
                                <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400" />
                                <Input
                                    type="text"
                                    placeholder="Search articles..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    className="pl-10 pr-4 py-2 w-full text-black"
                                />
                            </div>
                        </div>
                    </div>
                </section>

                {/* Blog Posts Section */}
                <section className="py-20">
                    <div className="container mx-auto px-4">
                        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
                            {currentPosts.map((post, index) => (
                                <Link to={post.link} key={index}>
                                    <Card className="flex flex-col h-full hover:shadow-lg transition-shadow duration-300">
                                        <CardHeader>
                                            <CardTitle className="text-xl mb-2">{post.title || 'Untitled'}</CardTitle>
                                            <div className="flex items-center text-muted-foreground text-sm mb-2">
                                                <CalendarDays className="mr-2 h-4 w-4" />
                                                <span className="mr-4">{post.date || 'No date'}</span>
                                                <Clock className="mr-2 h-4 w-4" />
                                                <span>{post.readTime || 'Unknown read time'}</span>
                                            </div>
                                        </CardHeader>
                                        <CardContent className="flex-grow flex flex-col justify-between">
                                            <p className="text-sm">{post.description || 'No description available'}</p>
                                            <div className="mt-4 pt-4 border-t border-gray-200">
                                                <Button asChild variant="link" className="px-0">
                                                    <span>Read More</span>
                                                </Button>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Link>
                            ))}
                        </div>
                        {/* Pagination */}
                        <div className="flex justify-between items-center mt-12">
                            <Button
                                onClick={handlePrevPage}
                                disabled={currentPage === 1}
                                variant="outline"
                            >
                                <ChevronLeft className="mr-2 h-4 w-4" /> Previous
                            </Button>
                            <span>Page {currentPage} of {totalPages}</span>
                            <Button
                                onClick={handleNextPage}
                                disabled={currentPage === totalPages}
                                variant="outline"
                            >
                                Next <ChevronRight className="ml-2 h-4 w-4" />
                            </Button>
                        </div>
                    </div>
                </section>

                {/* CTA Section */}
                <section className="bg-secondary text-secondary-foreground py-20 text-center">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold mb-4">Ready to Optimize Your Recruitment Process?</h2>
                        <p className="text-xl mb-8">Join PrimeApplicants and stay ahead in the world of recruitment.</p>
                        <Button size="lg" asChild>
                            <Link to="/register">Start Your Free Trial Today</Link>
                        </Button>
                    </div>
                </section>
            </div>
        </AnimatedPage>
    );
};

export default BlogListPage;