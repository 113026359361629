import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAdminAuth } from '../../../contexts/adminAuthContext';
import { Button } from "@/components/ui/button";
import ThemeToggle from "../../common/ThemeToggle";
import {
    HomeIcon, UsersIcon, BriefcaseIcon, LogOutIcon, UserIcon
} from 'lucide-react';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

const AdminNavbar = () => {
    const { logOutAdmin, adminUser } = useAdminAuth();
    const location = useLocation();
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            await logOutAdmin();
            navigate('/admin/login');
        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    const navItems = [
        { name: 'Dashboard', icon: HomeIcon, path: '/admin' },
        { name: 'Users', icon: UsersIcon, path: '/admin/users' },
        { name: 'Jobs', icon: BriefcaseIcon, path: '/admin/jobs' },
    ];

    return (
        <nav className="bg-background border-b border-border">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16">
                    <div className="flex items-center">
                        <Link to="/admin">
                            <img src="/assets/img/logoBlack.png" alt="PrimeApplicants Admin" className="h-8 w-auto dark:hidden" />
                            <img src="/assets/img/logoWhite.png" alt="PrimeApplicants Admin" className="h-8 w-auto hidden dark:block" />
                        </Link>
                        <div className="hidden md:ml-10 md:flex md:items-baseline md:space-x-4">
                            {navItems.map((item) => (
                                <Link
                                    key={item.path}
                                    to={item.path}
                                    className={`flex items-center px-3 py-2 rounded-md text-sm font-medium ${location.pathname === item.path
                                            ? 'bg-primary text-primary-foreground'
                                            : 'text-foreground hover:bg-accent hover:text-accent-foreground'
                                        }`}
                                >
                                    <item.icon className="mr-1.5 h-5 w-5" />
                                    {item.name}
                                </Link>
                            ))}
                        </div>
                    </div>
                    <div className="flex items-center">
                        <ThemeToggle />
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <Button variant="ghost" size="icon" className="ml-4">
                                    <UserIcon className="h-5 w-5" />
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent align="end">
                                <DropdownMenuItem className="text-sm font-medium text-muted-foreground">
                                    {adminUser.email}
                                </DropdownMenuItem>
                                <DropdownMenuSeparator />
                                <DropdownMenuItem
                                    onClick={handleLogout}
                                    className="cursor-pointer hover:bg-accent hover:text-accent-foreground"
                                >
                                    <LogOutIcon className="mr-2 h-4 w-4" />
                                    Log out
                                </DropdownMenuItem>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default AdminNavbar;