import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { submitJobApplication } from '../../../services/candidateService';
import { getCareerPageSettings, getUserBySlug } from '../../../services/userService';
import { Card, CardContent } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Label } from "@/components/ui/label";
import ErrorAlert from '../../common/ErrorAlert';
import { handleError } from '../../../utils/errorHandler';

const JobApplicationForm = ({ jobId }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        resume: null,
        acceptTerms: false
    });
    const [requiredFields, setRequiredFields] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const { slug } = useParams();
    const location = useLocation();
    const source = new URLSearchParams(location.search).get('source') || 'Career Page';

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const user = await getUserBySlug(slug);
                if (!user) {
                    throw new Error('User not found');
                }
                const settings = await getCareerPageSettings(user.id);
                setRequiredFields(settings.requiredFields || []);
            } catch (err) {
                const { message } = handleError(err);
                setError(message);
            }
        };
        fetchSettings();
    }, [slug]);

    const handleChange = (name, value) => {
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        if (!formData.acceptTerms) {
            setError('You must accept the terms and conditions and the privacy policy to continue.');
            setLoading(false);
            return;
        }

        const missingFields = requiredFields.filter(field => !formData[field]);
        if (missingFields.length > 0) {
            setError(`Please fill in the following required fields: ${missingFields.join(', ')}`);
            setLoading(false);
            return;
        }

        try {
            await submitJobApplication(slug, jobId, { ...formData, source });
            setSuccess(true);
        } catch (err) {
            const { message } = handleError(err);
            setError(message);
        }
        setLoading(false);
    };

    if (success) return (
        <Card>
            <CardContent className="py-4">
                <p className="text-green-600">
                    Your application has been successfully received. We will review it and get in touch with you as soon as possible.
                </p>
            </CardContent>
        </Card>
    );

    return (
        <Card>
            <CardContent className="py-6">
                <h2 className="text-2xl font-bold mb-4">Apply for this position</h2>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <Label htmlFor="name">Full Name</Label>
                        <Input
                            id="name"
                            value={formData.name}
                            onChange={(e) => handleChange('name', e.target.value)}
                            required={requiredFields.includes('name')}
                        />
                    </div>
                    <div>
                        <Label htmlFor="email">Email</Label>
                        <Input
                            id="email"
                            type="email"
                            value={formData.email}
                            onChange={(e) => handleChange('email', e.target.value)}
                            required={requiredFields.includes('email')}
                        />
                    </div>
                    <div>
                        <Label htmlFor="phone">Phone</Label>
                        <Input
                            id="phone"
                            type="tel"
                            value={formData.phone}
                            onChange={(e) => handleChange('phone', e.target.value)}
                            required={requiredFields.includes('phone')}
                        />
                    </div>
                    <div>
                        <Label htmlFor="resume">Resume</Label>
                        <Input
                            id="resume"
                            type="file"
                            onChange={(e) => handleChange('resume', e.target.files[0])}
                            required={requiredFields.includes('resume')}
                            accept=".pdf,.doc,.docx"
                        />
                    </div>
                    <div className="flex items-center space-x-2">
                        <Checkbox
                            id="acceptTerms"
                            checked={formData.acceptTerms}
                            onCheckedChange={(checked) => handleChange('acceptTerms', checked)}
                        />
                        <Label htmlFor="acceptTerms" className="text-sm">
                            I accept the {' '}
                            <a href="/terms-of-service" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                                terms and conditions
                            </a>
                            {' '} and the {' '}
                            <a href="/privacy-policy" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                                privacy policy
                            </a>
                        </Label>
                    </div>
                    {error && <ErrorAlert message={error} />}
                    <Button type="submit" disabled={loading}>
                        {loading ? 'Submitting...' : 'Submit Application'}
                    </Button>
                </form>
            </CardContent>
        </Card>
    );
};

export default JobApplicationForm;