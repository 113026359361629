import React, { useState, useRef, useEffect } from 'react';
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import { sendChatMessage } from '../../services/geminiChatService';
import { MessageCircle, X, Send, Home, User, Bot } from 'lucide-react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

const SupportChat = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showPopup, setShowPopup] = useState(true);
    const [showFrequentQuestions, setShowFrequentQuestions] = useState(true);
    const chatEndRef = useRef(null);
    const textareaRef = useRef(null);

    const frequentQuestions = [
        "What is PrimeApplicants?",
        "How can I start using the platform?",
        "What are the main features?",
        "What subscription plans do you offer?",
        "How does job posting work?",
        "Can I customize my career page?",
        "What kind of support do you provide?",
        "Is there a mobile app available?",
        "How secure is my data on PrimeApplicants?",
        "Can I integrate PrimeApplicants with other HR systems?"
    ];

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowPopup(false);
        }, 10000); // Hide popup after 10 seconds

        return () => clearTimeout(timer);
    }, []);

    const scrollToBottom = () => {
        chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const sendMessage = async (message) => {
        setShowFrequentQuestions(false);
        const newUserMessage = { role: "user", parts: [{ text: message }] };
        setMessages(prev => [...prev, newUserMessage]);
        setIsLoading(true);

        try {
            const response = await sendChatMessage(message, messages);
            const newAIMessage = { role: "model", parts: [{ text: response }] };
            setMessages(prev => [...prev, newAIMessage]);
        } catch (error) {
            console.error('Error sending message:', error);
            const errorMessage = {
                role: "model",
                parts: [{
                    text: `
                    <p>I apologize, but I'm having trouble processing your request at the moment. This could be due to a temporary issue. Here are some suggestions:</p>
                    <ul>
                        <li>Please try asking your question again.</li>
                        <li>If the problem persists, you might want to refresh the page and start a new conversation.</li>
                        <li>For urgent matters, consider contacting our support team directly at info@primeapplicants.com.</li>
                    </ul>
                    <p>Thank you for your patience and understanding!</p>
                `}]
            };
            setMessages(prev => [...prev, errorMessage]);
        } finally {
            setIsLoading(false);
        }
    };

    const renderMessage = (message, index) => {
        const isUser = message.role === 'user';
        return (
            <div key={index} className="mb-4 flex justify-start">
                <div className="flex items-start space-x-2">
                    <div className={`p-2 rounded-full ${isUser ? 'bg-primary text-primary-foreground' : 'bg-secondary text-secondary-foreground'}`}>
                        {isUser ? <User size={24} /> : <Bot size={24} />}
                    </div>
                    <div className={`max-w-[80%] p-3 rounded-lg ${isUser ? 'bg-primary text-primary-foreground' : 'bg-secondary text-secondary-foreground'}`}>
                        {isUser ? (
                            <span>{message.parts[0].text}</span>
                        ) : (
                            <ReactQuill
                                value={message.parts[0].text}
                                readOnly={true}
                                theme="bubble"
                                modules={{ toolbar: false }}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const adjustTextareaHeight = (reset = false) => {
        const textarea = textareaRef.current;
        if (textarea) {
            if (reset) {
                textarea.style.height = '40px'; // Reset to initial height
            } else {
                textarea.style.height = 'auto';
                textarea.style.height = `${Math.min(Math.max(textarea.scrollHeight, 40), 120)}px`;
            }
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (inputMessage.trim()) {
            sendMessage(inputMessage);
            setInputMessage('');
            adjustTextareaHeight(true); // Reset textarea height
        }
    };

    const resetChat = () => {
        setMessages([]);
        setShowFrequentQuestions(true);
    };

    return (
        <div className="fixed bottom-4 right-4 z-50 flex flex-col items-end">
            {showPopup && !isOpen && (
                <div className="bg-primary text-primary-foreground p-2 rounded-lg mb-2 flex items-center">
                    Need help? Chat with Primy!
                    <Button variant="ghost" size="sm" onClick={() => setShowPopup(false)} className="ml-2">
                        <X size={16} />
                    </Button>
                </div>
            )}
            {!isOpen && (
                <Button
                    onClick={() => setIsOpen(true)}
                    className="rounded-full aspect-square w-12 h-12"
                    size="icon"
                >
                    <MessageCircle size={28} />
                </Button>
            )}
            {isOpen && (
                <Card className="fixed inset-0 z-50 flex flex-col w-full h-full sm:inset-auto sm:bottom-4 sm:right-4 sm:w-[400px] md:w-[450px] lg:w-[500px] sm:h-[600px] sm:max-h-[calc(100vh-2rem)]">
                    <CardHeader className="flex flex-row items-center justify-between space-y-0 p-4">
                        <div className="flex items-center">
                            {!showFrequentQuestions && (
                                <Button variant="ghost" size="sm" onClick={resetChat} className="mr-2">
                                    <Home size={20} />
                                </Button>
                            )}
                            <div>
                                <CardTitle className="text-lg">
                                    {showFrequentQuestions ? "Ask anything to Primy" : "Primy"}
                                </CardTitle>
                                {!showFrequentQuestions && (
                                    <p className="text-sm text-muted-foreground">
                                        Your AI assistant
                                    </p>
                                )}
                            </div>
                        </div>
                        <Button variant="ghost" size="sm" onClick={() => setIsOpen(false)}>
                            <X size={20} />
                        </Button>
                    </CardHeader>
                    <CardContent className="flex-grow overflow-hidden flex flex-col p-0">
                        <ScrollArea className="flex-grow px-4 py-2">
                            <div className="space-y-4">
                                {messages.map(renderMessage)}
                                {isLoading && (
                                    <div className="flex justify-start">
                                        <div className="flex items-start space-x-2">
                                            <div className="p-2 rounded-full bg-secondary text-secondary-foreground">
                                                <Bot size={24} />
                                            </div>
                                            <div className="p-3 rounded-lg bg-secondary text-secondary-foreground animate-pulse">
                                                Thinking...
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {showFrequentQuestions && (
                                    <div className="mt-4 space-y-2">
                                        <h4 className="font-semibold">Frequently Asked Questions:</h4>
                                        {frequentQuestions.map((question, index) => (
                                            <Button
                                                key={index}
                                                variant="outline"
                                                className="w-full text-left justify-start h-auto py-2 px-3 whitespace-normal"
                                                onClick={() => sendMessage(question)}
                                            >
                                                {question}
                                            </Button>
                                        ))}
                                    </div>
                                )}
                                <div ref={chatEndRef} />
                            </div>
                        </ScrollArea>
                    </CardContent>
                    <CardFooter className="p-2">
                        <form onSubmit={handleSubmit} className="w-full flex items-end space-x-2">
                            <div className="flex-grow">
                                <Textarea
                                    ref={textareaRef}
                                    value={inputMessage}
                                    onChange={(e) => {
                                        setInputMessage(e.target.value);
                                        adjustTextareaHeight();
                                    }}
                                    placeholder="Type your message..."
                                    className="min-h-[40px] max-h-[120px] resize-none py-2 px-3"
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter' && !e.shiftKey) {
                                            e.preventDefault();
                                            handleSubmit(e);
                                        }
                                    }}
                                    rows={1}
                                />
                            </div>
                            <Button
                                type="submit"
                                className="h-10"
                                disabled={!inputMessage.trim()}
                            >
                                <Send size={20} />
                            </Button>
                        </form>
                    </CardFooter>
                </Card>
            )}
        </div>
    );
};

export default SupportChat;