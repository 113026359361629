import React from 'react';
import { Link } from 'react-router-dom';
import {
    Users,
    Lightbulb,
    MessageSquare,
    Sparkles,
    Globe,
    BarChart,
    Heart,
    BadgeCheck
} from 'lucide-react';
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { AnimatedPage } from '../../utils/animations';

const EmployerBrandingPage = () => {
    const brandingElements = [
        {
            icon: Users,
            title: "Company Culture",
            description: "Define and communicate your unique corporate culture and values."
        },
        {
            icon: Lightbulb,
            title: "Employee Value Proposition",
            description: "Clearly articulate what makes your company a great place to work."
        },
        {
            icon: MessageSquare,
            title: "Reputation Management",
            description: "Actively manage your company's reputation across various platforms."
        },
        {
            icon: Sparkles,
            title: "Candidate Experience",
            description: "Ensure a positive experience for all candidates throughout the recruitment process."
        }
    ];

    const brandingStrategies = [
        "Leverage social media to showcase your company culture",
        "Encourage employee advocacy and testimonials",
        "Create engaging content that reflects your company values",
        "Participate in industry events and thought leadership opportunities",
        "Offer unique perks and benefits that align with your brand",
        "Ensure consistency in all communications and touchpoints",
        "Invest in employee development and growth opportunities"
    ];

    return (
        <AnimatedPage>
            <div className="space-y-0">
                {/* Hero Section */}
                <section className="bg-gradient-to-tl from-primary to-muted-foreground text-primary-foreground py-20">
                    <div className="container mx-auto px-4 text-center">
                        <h1 className="text-5xl font-bold mb-4">Employer Branding</h1>
                        <p className="text-xl mb-8 max-w-2xl mx-auto">Build a compelling employer brand to attract, engage, and retain top talent.</p>
                        <Button asChild>
                            <Link to="/register">Start Free Trial</Link>
                        </Button>
                    </div>
                </section>

                {/* Key Elements Section */}
                <section className="py-20">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold text-center mb-12">Key Elements of a Strong Employer Brand</h2>
                        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
                            {brandingElements.map((element, index) => (
                                <Card key={index}>
                                    <CardHeader>
                                        <element.icon className="h-8 w-8 mb-2" />
                                        <CardTitle>{element.title}</CardTitle>
                                    </CardHeader>
                                    <CardContent>
                                        <p>{element.description}</p>
                                    </CardContent>
                                </Card>
                            ))}
                        </div>
                    </div>
                </section>

                {/* Branding Strategies Section */}
                <section className="bg-muted py-20">
                    <div className="container mx-auto px-4">
                        <div className="flex flex-col md:flex-row items-center">
                            <div className="md:w-1/2 mb-8 md:mb-0">
                                <h2 className="text-3xl font-bold mb-4">Strategies to Enhance Your Employer Brand</h2>
                                <p className="text-xl mb-6">Implement these strategies to strengthen your employer brand and attract top talent.</p>
                                <ul className="list-disc list-inside space-y-2">
                                    {brandingStrategies.map((strategy, index) => (
                                        <li key={index}>{strategy}</li>
                                    ))}
                                </ul>
                            </div>
                            <div className="md:w-1/2 md:pl-12 flex justify-center">
                                <div className="grid grid-cols-2 gap-4">
                                    <Globe className="h-16 w-16 text-primary" />
                                    <BarChart className="h-16 w-16 text-primary" />
                                    <Heart className="h-16 w-16 text-primary" />
                                    <BadgeCheck className="h-16 w-16 text-primary" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* How PrimeApplicants Helps Section */}
                <section className="py-20">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold text-center mb-12">How PrimeApplicants Strengthens Your Employer Brand</h2>
                        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
                            {[
                                { title: "Customizable Career Pages", description: "Create attractive, branded career pages that showcase your company culture and values." },
                                { title: "Enhanced Candidate Experience", description: "Provide a smooth, user-friendly application process that leaves a positive impression on candidates." },
                                { title: "Employer Brand Analytics", description: "Track key metrics related to your employer brand and candidate perceptions." },
                                { title: "Social Media Integration", description: "Easily share job postings and company updates across various social media platforms." }
                            ].map((feature, index) => (
                                <Card key={index}>
                                    <CardHeader>
                                        <CardTitle>{feature.title}</CardTitle>
                                    </CardHeader>
                                    <CardContent>
                                        <p>{feature.description}</p>
                                    </CardContent>
                                </Card>
                            ))}
                        </div>
                    </div>
                </section>

                {/* CTA Section */}
                <section className="bg-secondary text-secondary-foreground py-20 text-center">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold mb-4">Elevate Your Employer Brand</h2>
                        <p className="text-xl mb-8">Discover how PrimeApplicants can help you build a compelling employer brand and attract top talent.</p>
                        <Button size="lg" asChild>
                            <Link to="/register">Start Your Free Trial Today</Link>
                        </Button>
                    </div>
                </section>
            </div>
        </AnimatedPage>
    );
};

export default EmployerBrandingPage;