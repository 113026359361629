// src/services/jobService.js

import { db } from '../config/firebase.config';
import { collection, addDoc, updateDoc, deleteDoc, doc, getDocs, getDoc, query, where, writeBatch, serverTimestamp, getCountFromServer } from 'firebase/firestore';
import { FIRESTORE_COLLECTIONS } from '../constants/appConstants';
import { handleError } from '../utils/errorHandler';
import { getCandidates } from './candidateService';

const JOBS_COLLECTION = FIRESTORE_COLLECTIONS.JOBS;
const CANDIDATES_COLLECTION = FIRESTORE_COLLECTIONS.CANDIDATES;

export const createJob = async (jobData) => {
    if (!jobData.userId) {
        throw handleError("User ID is required to create a job");
    }
    try {
        const docRef = await addDoc(collection(db, JOBS_COLLECTION), {
            ...jobData,
            createdAt: serverTimestamp()
        });
        return { id: docRef.id, ...jobData };
    } catch (error) {
        throw handleError(error);
    }
};

export const getJobs = async (userId) => {
    if (!userId) {
        throw handleError("User ID is required to fetch jobs");
    }
    try {
        const q = query(collection(db, JOBS_COLLECTION), where("userId", "==", userId));
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
    } catch (error) {
        throw handleError(error);
    }
};

export const getJob = async (id) => {
    if (!id) {
        throw handleError("Job ID is required to fetch a job");
    }
    try {
        const docRef = doc(db, JOBS_COLLECTION, id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            return { id: docSnap.id, ...docSnap.data() };
        } else {
            return null;
        }
    } catch (error) {
        throw handleError(error);
    }
};

export const updateJob = async (id, jobData) => {
    if (!id) {
        throw handleError("Job ID is required to update a job");
    }
    try {
        const docRef = doc(db, JOBS_COLLECTION, id);

        // Update the job data in Firestore
        await updateDoc(docRef, {
            ...jobData,
            updatedAt: serverTimestamp()
        });

        return { id, ...jobData };
    } catch (error) {
        throw handleError(error);
    }
};

export const deleteJob = async (id) => {
    if (!id) {
        throw handleError("Job ID is required to delete a job");
    }
    try {
        await deleteDoc(doc(db, JOBS_COLLECTION, id));
    } catch (error) {
        throw handleError(error);
    }
};

export const deleteManyJobs = async (ids) => {
    if (!ids) {
        throw handleError("Job IDs are required to delete multiple jobs");
    }
    const batch = writeBatch(db);
    ids.forEach((id) => {
        const docRef = doc(db, JOBS_COLLECTION, id);
        batch.delete(docRef);
    });

    try {
        await batch.commit();
    } catch (error) {
        throw handleError(error);
    }
};

export const getActiveJobsByUser = async (slug) => {
    if (!slug) {
        throw handleError("Slug is required to fetch active jobs by user");
    }
    try {
        const usersRef = collection(db, 'users');
        const userQuery = query(usersRef, where('slug', '==', slug));
        const userSnapshot = await getDocs(userQuery);

        if (userSnapshot.empty) {
            throw new Error('User not found');
        }

        const userId = userSnapshot.docs[0].id;

        const jobsRef = collection(db, JOBS_COLLECTION);
        const jobsQuery = query(jobsRef,
            where('userId', '==', userId),
            where('status', '==', 'Active')
        );

        const querySnapshot = await getDocs(jobsQuery);
        return querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
    } catch (error) {
        throw handleError(error);
    }
};

export const getActiveJobs = async (userId) => {
    if (!userId) {
        throw handleError("User ID is required to fetch active jobs");
    }
    try {
        const q = query(
            collection(db, JOBS_COLLECTION),
            where('userId', '==', userId),
            where('status', '==', 'Active')
        );
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
    } catch (error) {
        throw handleError(error);
    }
};

export const getJobsWithCandidateCounts = async (userId) => {
    if (!userId) {
        throw handleError("User ID is required to fetch jobs with candidate counts");
    }

    try {
        const jobsRef = collection(db, JOBS_COLLECTION);
        const q = query(jobsRef, where('userId', '==', userId));
        const jobsSnapshot = await getDocs(q);

        const jobPromises = jobsSnapshot.docs.map(async (jobDoc) => {
            const job = { id: jobDoc.id, ...jobDoc.data() };
            const candidatesRef = collection(db, CANDIDATES_COLLECTION);
            const candidateQuery = query(candidatesRef, where('jobId', '==', job.id));
            const candidateCount = await getCountFromServer(candidateQuery);
            return { ...job, candidateCount: candidateCount.data().count };
        });

        return Promise.all(jobPromises);
    } catch (error) {
        throw handleError(error);
    }
};