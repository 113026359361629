// src/services/userService.js

import { db, storage } from '../config/firebase.config';
import { doc, setDoc, getDoc, collection, query, where, getDocs, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { FIRESTORE_COLLECTIONS } from '../constants/appConstants';
import { handleError } from '../utils/errorHandler';

const USERS_COLLECTION = FIRESTORE_COLLECTIONS.USERS;

export const getUserBySlug = async (slug) => {
    const usersRef = collection(db, USERS_COLLECTION);

    const q = query(usersRef, where('slug', '==', slug));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0];
        return { id: userDoc.id, ...userDoc.data() };
    }

    return null;
};

export const getUserProfile = async (userId) => {
    if (!userId) {
        throw handleError("User ID is required to fetch a user profile");
    }
    try {
        const docRef = doc(db, USERS_COLLECTION, userId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const userData = docSnap.data();
            return {
                id: docSnap.id,
                ...userData,
                emailVerified: userData.emailVerified || false,
                verificationToken: userData.verificationToken || null,
                role: userData.role || 'user',
                disabled: userData.disabled || false
            };
        } else {
            throw handleError('User not found');
        }
    } catch (error) {
        throw handleError(error);
    }
};

export const updateUserProfile = async (userId, profileData) => {
    if (!userId) {
        throw handleError("User ID is required to update a user profile");
    }
    const docRef = doc(db, USERS_COLLECTION, userId);

    try {
        const updateData = { ...profileData };

        delete updateData.proactiveRefresh;
        delete updateData.reloadUserInfo;
        delete updateData.stsTokenManager;

        if ('twoFactorEnabled' in updateData) {
            updateData.twoFactorSecret = updateData.twoFactorEnabled ? updateData.twoFactorSecret : null;
        }

        Object.keys(updateData).forEach(key => updateData[key] === undefined && delete updateData[key]);

        if ('emailSettings' in updateData) {
            updateData.emailSettings = {
                sendConfirmationEmail: updateData.emailSettings.sendConfirmationEmail,
                confirmationSubject: updateData.emailSettings.confirmationSubject,
                confirmationBody: updateData.emailSettings.confirmationBody
            };
        }

        await updateDoc(docRef, updateData);
    } catch (error) {
        throw handleError(error);
    }
};

export const deleteLogo = async (userId) => {
    if (!userId) {
        throw handleError("User ID is required to delete a logo");
    }
    try {
        const userRef = doc(db, USERS_COLLECTION, userId);

        const userDoc = await getDoc(userRef);
        const userData = userDoc.data();

        if (userData.careerPageSettings && userData.careerPageSettings.companyLogo) {
            const logoRef = ref(storage, userData.careerPageSettings.companyLogo);

            await deleteObject(logoRef);

            await updateDoc(userRef, {
                'careerPageSettings.companyLogo': null
            });

        } else {
            throw handleError('No logo found to delete');
        }

        return true;
    } catch (error) {
        throw handleError(error);
    }
};

export const updateCareerPageSettings = async (userId, settings) => {
    if (!userId) {
        throw handleError("User ID is required to update career page settings");
    }
    const docRef = doc(db, USERS_COLLECTION, userId);
    try {
        await updateDoc(docRef, {
            careerPageSettings: settings
        });
    } catch (error) {
        throw handleError(error);
    }
};

export const getCareerPageSettings = async (userId) => {
    if (!userId) {
        throw handleError("User ID is required to get career page settings");
    }
    try {
        const docRef = doc(db, USERS_COLLECTION, userId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const userData = docSnap.data();
            return {
                ...defaultCareerPageSettings,
                ...userData.careerPageSettings
            };
        }

        return defaultCareerPageSettings;
    } catch (error) {
        throw handleError(error);
    }
};

const defaultCareerPageSettings = {
    requiredFields: ['name', 'email'],
    companyLogo: '',
    welcomeMessage: 'Join our team and make a difference!'
};

export const uploadLogo = async (userId, file) => {
    if (!userId) {
        throw handleError("User ID is required to upload a logo");
    }
    try {
        const fileRef = ref(storage, `company_logos/${userId}/${file.name}`);

        await uploadBytes(fileRef, file);

        const downloadURL = await getDownloadURL(fileRef);
        return downloadURL;
    } catch (error) {
        throw handleError(error);
    }
};