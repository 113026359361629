export const ROUTES = {
    HOME: '/',
    LOGIN: '/login',
    REGISTER: '/register',
    FORGOT_PASSWORD: '/forgot-password',
    DASHBOARD: '/dashboard',
    JOBS: '/jobs',
    JOB_CREATE: '/jobs/create',
    JOB_EDIT: '/jobs/edit/:id',
    JOB_DETAILS: '/jobs/:id',
    CANDIDATES: '/candidates',
    CANDIDATE_CREATE: '/candidates/create',
    CANDIDATE_EDIT: '/candidates/edit/:id',
    CANDIDATE_DETAILS: '/candidates/:id',
    RECRUITMENT: '/recruitment',
    INTERVIEWS: '/interviews',
    SETTINGS: '/profile/settings',
    CAREER_PAGE: '/careers/:slug',
    PUBLIC_JOB_DETAILS: '/careers/:slug/job/:id',
    ABOUT: '/about',
    CONTACT: '/contact',
    SERVICES: '/services',
    PRIVACY_POLICY: '/privacy-policy',
    TERMS_OF_SERVICE: '/terms-of-service',
    SETUP_2FA: '/setup-2fa',
    VERIFY_2FA: '/verify-2fa',
};

export const JOB_STATUS = {
    ACTIVE: 'Active',
    CLOSED: 'Closed',
    DRAFT: 'Draft',
};

export const JOB_TYPES = {
    ON_SITE: 'On-site',
    REMOTE: 'Remote',
    HYBRID: 'Hybrid',
};

export const WORK_TIMES = {
    FULL_TIME: 'Full-time',
    PART_TIME: 'Part-time',
    CONTRACT: 'Contract',
    INTERNSHIP: 'Internship',
    VOLUNTEER: 'Volunteer'
};

export const EXPERIENCE_LEVELS = {
    ENTRY_LEVEL: 'Entry-Level',
    MID_SENIOR_LEVEL: 'Mid-Senior Level',
    DIRECTOR: 'Director',
    EXECUTIVE: 'Executive',
    INTERNSHIP: 'Internship',
    ASSOCIATE: 'Associate'
};

export const CURRENCIES = {
    USD: 'USD',
    EUR: 'EUR',
    GBP: 'GBP',
};

export const RECRUITMENT_STAGES = {
    NEW: 'New',
    SCREENING: 'Screening',
    INTERVIEW: 'Interview',
    OFFER: 'Offer',
    HIRED: 'Hired',
    REJECTED: 'Rejected',
};

export const INTERVIEW_TYPES = {
    PHONE: 'Phone',
    VIDEO: 'Video',
    IN_PERSON: 'In-person',
};

export const FIRESTORE_COLLECTIONS = {
    USERS: 'users',
    JOBS: 'jobs',
    CANDIDATES: 'candidates',
    INTERVIEWS: 'interviews',
    USER_ROLES: 'userRoles',
};

export const USER_ROLES = {
    ADMIN: 'admin',
    RECRUITER: 'recruiter',
    HIRING_MANAGER: 'hiring_manager',
    USER: 'user',
};

export const PAGINATION = {
    ITEMS_PER_PAGE: 10,
};

export const ERROR_MESSAGES = {
    GENERAL: 'An error occurred. Please try again later.',
    NETWORK: 'Network error. Please check your internet connection.',
    UNAUTHORIZED: 'You are not authorized to perform this action.',
    NOT_FOUND: 'The requested resource was not found.',
};

export const NOTIFICATION_TYPES = {
    SUCCESS: 'success',
    ERROR: 'error',
    WARNING: 'warning',
    INFO: 'info',
};

export const THEMES = {
    LIGHT: 'light',
    DARK: 'dark',
};

export const DEFAULT_CAREER_PAGE_SETTINGS = {
    REQUIRED_FIELDS: ['name', 'email'],
    WELCOME_MESSAGE: 'Join our team and make a difference!',
};

export const AUTH_CONFIG = {
    MIN_PASSWORD_LENGTH: 6,
};

export const DATE_FORMATS = {
    DISPLAY: 'MMMM D, YYYY',
    INPUT: 'YYYY-MM-DD',
};

export const LIMITS = {
    MAX_FILE_SIZE: 5 * 1024 * 1024,
    MAX_CANDIDATES_IMPORT: 100,
};

export const ENABLE_REGISTRATION = true;

export const CONTACT_EMAIL = 'info@primeapplicants.com';

export const SESSION_CONFIG = {
    NORMAL_SESSION_EXPIRATION: 30 * 60 * 1000,
    REMEMBERED_SESSION_EXPIRATION: 7 * 24 * 60 * 60 * 1000,
};

export const JOB_BOARDS = {
    LINKEDIN: 'LinkedIn',
    JOOBLE: 'Jooble',
    CAREERJET: 'Careerjet',
    ADZUNA: 'Adzuna',
    JOBRAPIDO: 'Jobrapido',
    TALENT: 'Talent',
    // ZIPRECRUITER: 'ZipRecruiter',
};