import React, { useEffect, useState } from 'react';
import { Outlet, useParams, Link } from 'react-router-dom';
import { getUserBySlug, getCareerPageSettings } from '../../services/userService';

const CareerPageLayout = () => {
    const [company, setCompany] = useState(null);
    const [settings, setSettings] = useState(null);
    const { slug } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userData = await getUserBySlug(slug);
                if (userData) {
                    setCompany(userData);
                    const careerPageSettings = await getCareerPageSettings(userData.id);
                    setSettings(careerPageSettings);
                }
            } catch (error) {
                console.error("Error fetching company data:", error);
            }
        };

        fetchData();
    }, [slug]);

    if (!company || !settings) return <div className='text-center mt-8'>Loading...</div>;

    return (
        <div className="flex flex-col min-h-screen bg-background">
            <header className="bg-primary text-primary-foreground py-8">
                <div className="container mx-auto px-4">
                    <div className="flex flex-col items-center text-center">
                        {settings.companyLogo ? (
                            <img src={settings.companyLogo} alt={company.companyName} className="w-auto max-h-14 mb-4" />
                        ) : (
                            <h1 className="text-4xl font-bold mb-4">{company.companyName}</h1>
                        )}
                        <p className="text-xl mt-2">{settings.welcomeMessage}</p>
                    </div>
                </div>
            </header>

            <main className="flex-grow container mx-auto px-4 py-8">
                <Outlet />
            </main>

            <footer className="bg-primary text-primary-foreground text-center p-4">
                <p>Powered by <a href="https://primeapplicants.com" target="_blank" className="underline text-secondary">PrimeApplicants</a></p>
            </footer>
        </div>
    );
};

export default CareerPageLayout;