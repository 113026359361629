import React from 'react';
import { Link } from 'react-router-dom';
import {
    CheckCircle,
    Search,
    Clock,
    User,
    MessageSquare,
    Shield,
    BarChart2,
    AlertTriangle,
    ChevronRight
} from 'lucide-react';
import { AnimatedPage } from '../../utils/animations';
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

export const articleMeta = {
    title: "How AI is Revolutionizing Recruitment",
    description: "Explore how artificial intelligence is transforming the recruitment process and what it means for HR professionals.",
    date: "August 15, 2024",
    readTime: "5 min read",
};

const AIRecruitmentArticle = () => {
    const { title, description, date, readTime } = articleMeta;

    const aiApplications = [
        { icon: Search, title: "Resume Screening", description: "AI can quickly analyze resumes and match candidates to job requirements." },
        { icon: Clock, title: "Interview Scheduling", description: "Automated scheduling tools can streamline the interview process." },
        { icon: User, title: "Candidate Matching", description: "AI algorithms can identify the best candidates for specific roles." },
        { icon: MessageSquare, title: "Chatbots for Initial Screening", description: "AI-powered chatbots can handle initial candidate inquiries and screening." },
        { icon: Shield, title: "Bias Reduction", description: "AI can help minimize unconscious bias in the hiring process." },
        { icon: BarChart2, title: "Predictive Analytics", description: "AI can forecast hiring needs and predict candidate success." },
        { icon: CheckCircle, title: "Skills Assessment", description: "AI-driven tools can evaluate candidates' skills more objectively." },
        { icon: AlertTriangle, title: "Ethical Considerations", description: "Addressing potential biases and ensuring fairness in AI-driven recruitment." }
    ];

    return (
        <AnimatedPage>
            <article className="max-w-5xl mx-auto px-4 py-12">
                {/* Breadcrumb */}
                <nav className="flex mb-8 text-sm" aria-label="Breadcrumb">
                    <ol className="inline-flex items-center space-x-1 md:space-x-3">
                        <li className="inline-flex items-center">
                            <Link to="/" className="inline-flex items-center text-muted-foreground hover:text-primary">
                                Home
                            </Link>
                        </li>
                        <li>
                            <div className="flex items-center">
                                <ChevronRight className="w-4 h-4 text-muted-foreground" />
                                <Link to="/blog" className="ml-1 text-muted-foreground hover:text-primary md:ml-2">
                                    Blog
                                </Link>
                            </div>
                        </li>
                        <li aria-current="page">
                            <div className="flex items-center">
                                <ChevronRight className="w-4 h-4 text-muted-foreground" />
                                <span className="ml-1 text-primary md:ml-2">AI in Recruitment</span>
                            </div>
                        </li>
                    </ol>
                </nav>

                <header className="mb-12 text-center">
                    <h1 className="text-5xl font-bold mb-6">{title}</h1>
                    <div className="flex items-center justify-center text-muted-foreground mb-6">
                        <User className="mr-2 h-5 w-5" />
                        <span className="mr-4">PrimeApplicants Team</span>
                        <Clock className="mr-2 h-5 w-5" />
                        <span className="mr-4">{date}</span>
                        <Clock className="mr-2 h-5 w-5" />
                        <span>{readTime}</span>
                    </div>
                    <p className="text-xl text-muted-foreground max-w-3xl mx-auto">{description}</p>
                </header>

                <section className="prose max-w-none mb-12">
                    <p>Artificial Intelligence (AI) is rapidly transforming the recruitment landscape, offering new opportunities for efficiency and effectiveness in hiring processes. This article explores the various applications of AI in recruitment, its benefits, and the challenges that come with its implementation.</p>
                    <p>As we delve into this topic, we'll examine how AI is changing the way organizations attract, assess, and hire talent, while also considering the ethical implications and best practices for leveraging this technology responsibly.</p>
                </section>

                <section className="mb-16">
                    <h2 className="text-3xl font-bold mb-8">AI Applications in Recruitment</h2>
                    <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
                        {aiApplications.map((app, index) => (
                            <Card key={index} className="hover:shadow-lg transition-shadow duration-300">
                                <CardHeader>
                                    <app.icon className="h-12 w-12 mb-4 text-primary" />
                                    <CardTitle>{app.title}</CardTitle>
                                </CardHeader>
                                <CardContent>
                                    <p>{app.description}</p>
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                </section>

                {/* Banner de llamado a la acción adicional */}
                <section className="bg-primary text-primary-foreground py-12 px-8 rounded-lg mb-16">
                    <h2 className="text-3xl font-bold mb-4">Harness the Power of AI in Your Recruitment</h2>
                    <p className="mb-6">Discover how PrimeApplicants integrates AI to enhance your hiring process. Start your free trial and experience the future of recruitment.</p>
                    <Button asChild size="lg" variant="secondary">
                        <Link to="/register">Start Free Trial</Link>
                    </Button>
                </section>

                <section className="prose max-w-none mb-12">
                    <h2 className="text-3xl font-bold mb-6">Benefits of AI in Recruitment</h2>
                    <ul className="list-disc pl-5 space-y-4">
                        <li>Increased efficiency and time savings in the hiring process</li>
                        <li>Improved quality of hire through data-driven decision making</li>
                        <li>Enhanced candidate experience with faster response times</li>
                        <li>Reduction of unconscious bias in candidate selection</li>
                        <li>Ability to handle high-volume recruiting more effectively</li>
                        <li>Better matching of candidates to job requirements</li>
                        <li>Improved ability to identify and attract passive candidates</li>
                    </ul>
                </section>

                <section className="prose max-w-none mb-12">
                    <h2 className="text-3xl font-bold mb-6">Challenges and Considerations</h2>
                    <div className="space-y-6">
                        {[
                            { title: "Data Privacy and Security", description: "Ensuring compliance with data protection regulations and maintaining candidate privacy." },
                            { title: "Algorithmic Bias", description: "Addressing potential biases in AI algorithms that could lead to unfair hiring practices." },
                            { title: "Human Touch", description: "Balancing AI efficiency with the need for human interaction in the recruitment process." },
                            { title: "Technology Integration", description: "Ensuring smooth integration of AI tools with existing HR systems and processes." }
                        ].map((challenge, index) => (
                            <div key={index}>
                                <h3 className="text-xl font-semibold mb-2">{challenge.title}</h3>
                                <p>{challenge.description}</p>
                            </div>
                        ))}
                    </div>
                </section>

                <section className="prose max-w-none mb-12">
                    <h2 className="text-3xl font-bold mb-6">Conclusion</h2>
                    <p>AI is undoubtedly reshaping the recruitment landscape, offering powerful tools to enhance efficiency and effectiveness in hiring processes. However, it's crucial to approach AI implementation thoughtfully, considering both its potential benefits and challenges.</p>
                    <p>As we move forward, the key to success lies in striking the right balance between leveraging AI's capabilities and maintaining the human touch in recruitment. By doing so, organizations can create a more efficient, fair, and effective hiring process that benefits both employers and candidates alike.</p>
                </section>
            </article>
            <section className="bg-secondary text-secondary-foreground py-20 text-center">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold mb-4">Ready to Leverage AI in Your Recruitment Process?</h2>
                    <p className="text-xl mb-8">Discover how PrimeApplicants can help you implement AI-driven recruitment strategies.</p>
                    <Button size="lg" asChild>
                        <Link to="/register">Start Your Free Trial Today</Link>
                    </Button>
                </div>
            </section>
        </AnimatedPage>
    );
};

export default AIRecruitmentArticle;