import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Card, CardHeader, CardContent, CardFooter, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";

const VerifyEmailSent = () => {
    const { user, logOut, resendVerificationEmail } = useAuth();
    const navigate = useNavigate();
    const [resendDisabled, setResendDisabled] = useState(false);
    const [resendMessage, setResendMessage] = useState('');

    useEffect(() => {
        if (!user) {
            navigate('/login');
        } else if (user.emailVerified) {
            navigate('/dashboard');
        }
    }, [user, navigate]);

    const handleLogout = async () => {
        try {
            await logOut();
            navigate('/login');
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };

    const handleResendVerification = async () => {
        setResendDisabled(true);
        setResendMessage('');
        try {
            await resendVerificationEmail();
            setResendMessage('Verification email resent successfully!');
            setTimeout(() => setResendDisabled(false), 60000); // Disable for 1 minute
        } catch (error) {
            console.error('Error resending verification email:', error);
            setResendMessage('Failed to resend verification email. Please try again later.');
            setResendDisabled(false);
        }
    };

    if (!user || user.emailVerified) {
        return null;
    }

    return (
        <div className="flex items-center justify-center min-h-screen p-4">
            <Card className="w-full max-w-md">
                <CardHeader>
                    <CardTitle className="text-2xl font-semibold text-center">Verify Your Email</CardTitle>
                </CardHeader>
                <CardContent>
                    <p className="text-center text-muted-foreground mb-4">
                        We've sent a verification email to your address. Please check your inbox and click on the verification link.
                    </p>
                    <Button
                        className="w-full mb-2"
                        onClick={handleResendVerification}
                        disabled={resendDisabled}
                    >
                        Resend Verification Email
                    </Button>
                    {resendMessage && (
                        <p className="text-center text-sm text-muted-foreground mt-2">
                            {resendMessage}
                        </p>
                    )}
                </CardContent>
                <CardFooter>
                    <Button variant="outline" className="w-full" onClick={handleLogout}>
                        Log Out
                    </Button>
                </CardFooter>
            </Card>
        </div>
    );
};

export default VerifyEmailSent;