const ERROR_MESSAGES = {
    'auth/user-not-found': 'No account found with this email.',
    'auth/account-disabled': 'Your account has been disabled. Please contact support for more information.',
    'auth/invalid-credential': 'Invalid email or password.',
    'auth/wrong-password': 'Password is incorrect.',
    'auth/email-already-in-use': 'This email is already in use.',
    'auth/weak-password': 'The password must be at least 6 characters.',
    'auth/invalid-email': 'The email is not valid.',
    'auth/requires-recent-login': 'This operation is sensitive and requires recent authentication. Log in again before retrying.',
    'auth/popup-closed-by-user': 'The authentication popup was closed before completing the sign in.',
    'auth/invalid-verification-code': 'The verification code is invalid. Please try again.',
    '2fa/invalid-token': 'The two-factor authentication code is invalid. Please try again.',
    'permission-denied': 'You do not have permission to perform this action.',
    'not-found': 'The requested resource was not found.',
    'already-exists': 'The document already exists.',
    'unavailable': 'The service is not available at this time.',
    'invalid-argument': 'The argument is invalid.',
    'resource-exhausted': 'The resource has been exhausted.',
    'unauthenticated': 'The request does not have valid authentication credentials.',
    'unauthorized': 'The request is unauthorized.',
    'storage/unauthorized': 'User doesn\'t have permission to access the object',
    'storage/canceled': 'User canceled the upload',
    'storage/unknown': 'Unknown error occurred',
    'network-error': 'Connection error. Please check your internet connection.',
    'validation/required-field': 'This field is required.',
    'validation/invalid-format': 'The input format is invalid.',
    'operation-not-allowed': 'This operation is not allowed.',
    'quota-exceeded': 'Quota exceeded. Please try again later.',
    'api/rate-limit': 'API rate limit exceeded. Please try again later.',
    'api/invalid-request': 'Invalid API request.',
    'database/connection-error': 'Unable to connect to the database.',
    'database/query-error': 'Error executing database query.',
    'file/too-large': 'The file is too large.',
    'file/unsupported-format': 'Unsupported file format.',
    '2fa/invalid-token': 'Invalid two-factor authentication token.',
    '2fa/expired-token': 'Two-factor authentication token has expired.',
    'default': 'An error occurred. Please try again later.'
};

export const handleError = (error) => {
    console.error('Original Error:', error);

    let errorMessage;
    let errorCode;

    if (error.code) {
        errorCode = error.code;
        errorMessage = ERROR_MESSAGES[error.code] || ERROR_MESSAGES.default;
    } else if (error.message) {
        errorCode = 'custom-error';
        errorMessage = error.message;
    } else if (typeof error === 'string') {
        errorCode = 'custom-error';
        errorMessage = error;
    } else {
        errorCode = 'unknown-error';
        errorMessage = ERROR_MESSAGES.default;
    }

    return {
        code: errorCode,
        message: errorMessage
    };
};

export const isNetworkError = (error) => {
    return error.message === 'Network Error' || !navigator.onLine;
};

export const isForbiddenError = (error) => {
    return error.code === 'permission-denied' || error.code === 'auth/unauthorized';
};

export const isNotFoundError = (error) => {
    return error.code === 'not-found';
};

export const isValidationError = (error) => {
    return error.code && error.code.startsWith('validation/');
};

export const isAuthenticationError = (error) => {
    return error.code && error.code.startsWith('auth/');
};