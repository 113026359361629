import TopFreeATSSystems, {articleMeta as TopFreeATSSystemsMeta} from '../pages/blog/TopFreeATSSystems';
import AIRecruitmentArticle, {articleMeta as AIRecruitmentArticleMeta}  from '../pages/blog/AIRecruitmentArticle';
import ATSFeaturesArticle, {articleMeta as ATSFeaturesArticleMeta} from '../pages/blog/ATSFeaturesArticle';
import EffectiveJobDescriptionsArticle, {articleMeta as EffectiveJobDescriptionsArticleMeta} from '../pages/blog/EffectiveJobDescriptionsArticle';
import RemoteHiringGuideArticle, {articleMeta as RemoteHiringGuideArticleMeta} from '../pages/blog/RemoteHiringGuideArticle';
import FreeJobPostingSitesUSA, {articleMeta as FreeJobPostingSitesUSAMeta} from '../pages/blog/FreeJobPostingSitesUSA';
import BestApplicantTrackingSystems, {articleMeta as BestApplicantTrackingSystemsMeta} from '../pages/blog/BestApplicantTrackingSystems';
import DigitalTalentRecruitment, {articleMeta as DigitalTalentRecruitmentMeta} from '../pages/blog/DigitalTalentRecruitment';
import JobSitesAustralia, {articleMeta as JobSitesAustraliaMeta} from '../pages/blog/JobSitesAustralia';
import FreeJobPostingSitesPhilippines, {articleMeta as FreeJobPostingSitesPhilippinesMeta} from '../pages/blog/FreeJobPostingSitesPhilippines';
import FreeJobPostingSitesCanada, {articleMeta as FreeJobPostingSitesCanadaMeta} from '../pages/blog/FreeJobPostingSitesCanada';
import RecruitingTextingGuide, {articleMeta as RecruitingTextingGuideMeta} from '../pages/blog/RecruitingTextingGuide';
import BestJobSitesUK, {articleMeta as BestJobSitesUKMeta} from '../pages/blog/BestJobSitesUK';

const articles = [
    {
        ...TopFreeATSSystemsMeta,
        link: "/blog/top-free-ats-systems",
        component: TopFreeATSSystems
    },
    {
        ...AIRecruitmentArticleMeta,
        link: "/blog/ai-recruitment",
        component: AIRecruitmentArticle
    },
    {
        ...ATSFeaturesArticleMeta,
        link: "/blog/ats-features",
        component: ATSFeaturesArticle
    },
    {
        ...EffectiveJobDescriptionsArticleMeta,
        link: "/blog/effective-job-descriptions",
        component: EffectiveJobDescriptionsArticle
    },
    {
        ...RemoteHiringGuideArticleMeta,
        link: "/blog/remote-hiring-guide",
        component: RemoteHiringGuideArticle
    },
    {
        ...FreeJobPostingSitesUSAMeta,
        link: "/blog/free-job-posting-sites-usa",
        component: FreeJobPostingSitesUSA
    },
    {
        ...BestApplicantTrackingSystemsMeta,
        link: "/blog/best-applicant-tracking-systems",
        component: BestApplicantTrackingSystems
    },
    {
        ...DigitalTalentRecruitmentMeta,
        link: "/blog/digital-talent-recruitment",
        component: DigitalTalentRecruitment
    },
    {
        ...JobSitesAustraliaMeta,
        link: "/blog/job-sites-australia",
        component: JobSitesAustralia
    },
    {
        ...FreeJobPostingSitesPhilippinesMeta,
        link: "/blog/free-job-posting-sites-philippines",
        component: FreeJobPostingSitesPhilippines
    },
    {
        ...FreeJobPostingSitesCanadaMeta,
        link: "/blog/free-job-posting-sites-canada",
        component: FreeJobPostingSitesCanada
    },
    {
        ...RecruitingTextingGuideMeta,
        link: "/blog/recruiting-texting-guide",
        component: RecruitingTextingGuide
    },
    {
        ...BestJobSitesUKMeta,
        link: "/blog/best-job-sites-uk",
        component: BestJobSitesUK
    },
];


// Función para ordenar los artículos por fecha (del más reciente al más antiguo)
const sortArticlesByDate = (articles) => {
    return articles.sort((a, b) => new Date(b.date) - new Date(a.date));
};

// Exportamos los artículos ordenados
export const blogArticles = sortArticlesByDate(articles);