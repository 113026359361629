import React from 'react';
import { Link } from 'react-router-dom';
import {
    Users, ClipboardList, MessageSquare, BarChart2,
    Zap, BookOpen
} from 'lucide-react';
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { AnimatedPage } from '../utils/animations';
const RecruitmentResourcesGuidePage = () => {
    const resourceCategories = [
        { icon: ClipboardList, title: "Best Practices", description: "Learn industry-leading recruitment techniques", link: "/resources/best-practices" },
        { icon: MessageSquare, title: "Interview Strategies", description: "Improve your interviewing skills and techniques", link: "/resources/interview-strategies" },
        { icon: BarChart2, title: "Talent Analytics", description: "Leverage data to make better hiring decisions", link: "/resources/talent-analytics" },
        { icon: Users, title: "Employer Branding", description: "Build a strong employer brand to attract top talent", link: "/resources/employer-branding" }
    ];

    const featuredArticles = [
        { title: "10 Essential ATS Features for Modern Recruiting", link: "/blog/ats-features" },
        { title: "How to Write Job Descriptions That Attract Top Talent", link: "/blog/effective-job-descriptions" },
        { title: "The Ultimate Guide to Remote Hiring", link: "/blog/remote-hiring-guide" },
        { title: "How AI is Revolutionizing Recruitment", link: "/blog/ai-recruitment" }
    ];

    const quickTips = [
        "Use data-driven decisions in your recruitment process",
        "Personalize your outreach to passive candidates",
        "Optimize your job postings for SEO to increase visibility",
        "Implement a structured interview process for consistency",
        "Regularly update your talent pipeline",
        "Leverage social media for employer branding and candidate outreach"
    ];

    return (
        <AnimatedPage>
            <div className="space-y-0">
                {/* Hero Section */}
                <section className="bg-gradient-to-tl from-primary to-muted-foreground text-primary-foreground py-20">
                    <div className="container mx-auto px-4 text-center">
                        <h1 className="text-5xl font-bold mb-4">Recruitment Resources Guide</h1>
                        <p className="text-xl mb-8 max-w-2xl mx-auto">Empower your recruitment strategy with our comprehensive resources and expert insights.</p>
                        <Button asChild>
                            <Link to="#categories">Explore Resources</Link>
                        </Button>
                    </div>
                </section>

                {/* Resource Categories Section */}
                <section id="categories" className="py-20">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold text-center mb-12">Resource Categories</h2>
                        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
                            {resourceCategories.map((category, index) => (
                                <Card key={index}>
                                    <CardHeader>
                                        <category.icon className="h-12 w-12 mb-4 text-primary" />
                                        <CardTitle>{category.title}</CardTitle>
                                    </CardHeader>
                                    <CardContent>
                                        <p className="mb-4">{category.description}</p>
                                        <Button asChild>
                                            <Link to={category.link}>Read More</Link>
                                        </Button>
                                    </CardContent>
                                </Card>
                            ))}
                        </div>
                    </div>
                </section>

                {/* Featured Articles Section */}
                <section className="bg-muted py-20">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold text-center mb-12">Featured Articles</h2>
                        <div className="grid md:grid-cols-2 gap-6">
                            {featuredArticles.map((article, index) => (
                                <Card key={index}>
                                    <CardHeader>
                                        <BookOpen className="h-12 w-12 mb-4 text-primary" />
                                        <CardTitle>{article.title}</CardTitle>
                                    </CardHeader>
                                    <CardContent>
                                        <Button variant="link" asChild>
                                            <Link to={article.link}>Read Article</Link>
                                        </Button>
                                    </CardContent>
                                </Card>
                            ))}
                        </div>
                    </div>
                </section>

                {/* Quick Tips Section */}
                <section className="py-20">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold text-center mb-12">Quick Recruitment Tips</h2>
                        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
                            {quickTips.map((tip, index) => (
                                <Card key={index}>
                                    <CardContent className="pt-6">
                                        <Zap className="h-12 w-12 mb-4 text-primary" />
                                        <p>{tip}</p>
                                    </CardContent>
                                </Card>
                            ))}
                        </div>
                    </div>
                </section>

                {/* CTA Section */}
                <section className="bg-secondary text-secondary-foreground py-20 text-center">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold mb-4">Ready to Elevate Your Recruitment?</h2>
                        <p className="text-xl mb-8">Sign up for PrimeApplicants and get access to exclusive resources and tools.</p>
                        <Button size="lg" asChild>
                            <Link to="/register">Start Your Free Trial Today</Link>
                        </Button>
                    </div>
                </section>
            </div>
        </AnimatedPage>
    );
};

export default RecruitmentResourcesGuidePage;