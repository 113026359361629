// src/utils/animations.js

import { motion } from 'framer-motion';

export const pageTransition = {
    type: 'tween',
    ease: 'easeInOut',
    duration: 0.4,
};

export const pageVariants = {
    initial: { opacity: 0, y: 10 },
    in: { opacity: 1, y: 0 },
    out: { opacity: 0, y: -10 },
};

export const modalBackdropVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
};

export const modalContentVariants = {
    hidden: { y: -50, opacity: 0 },
    visible: { y: 0, opacity: 1 }
};

export const sidebarVariants = {
    open: { width: 240 },
    closed: { width: 80 }
};

export const fadeIn = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 0.3 }
};

export const AnimatedPage = ({ children }) => (
    <motion.div
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
        transition={pageTransition}
    >
        {children}
    </motion.div>
);

export const AnimatedModal = ({ children, isOpen }) => (
    <motion.div
        initial="hidden"
        animate={isOpen ? "visible" : "hidden"}
        exit="hidden"
        variants={modalBackdropVariants}
        transition={{ duration: 0.3 }}
    >
        <motion.div variants={modalContentVariants} transition={{ duration: 0.3 }}>
            {children}
        </motion.div>
    </motion.div>
);