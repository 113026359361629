import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { AnimatedPage } from '../utils/animations';
import rehypeRaw from 'rehype-raw';

const PrivacyPolicy = () => {
    const [policy, setPolicy] = useState('');

    useEffect(() => {
        fetch('/privacy-policy.md')
            .then(response => response.text())
            .then(text => setPolicy(text));
    }, []);

    return (
        <AnimatedPage>
            <div className="container mx-auto px-4 py-8">
                <div className="prose max-w-none dark:prose-invert prose-headings:text-primary-dark dark:prose-headings:text-primary-light prose-p:text-text-light dark:prose-p:text-text-dark prose-a:text-secondary-dark dark:prose-a:text-secondary-light">
                    <ReactMarkdown rehypePlugins={[rehypeRaw]} components={{
                        h1: ({node, ...props}) => <h1 className="text-4xl font-bold mb-4" {...props} />,
                        h2: ({node, ...props}) => <h2 className="text-3xl font-semibold mt-8 mb-4" {...props} />,
                        h3: ({node, ...props}) => <h3 className="text-2xl font-semibold mt-6 mb-3" {...props} />,
                        p: ({node, ...props}) => <p className="mb-4" {...props} />,
                        ul: ({node, ...props}) => <ul className="list-disc list-inside mb-4" {...props} />,
                        ol: ({node, ...props}) => <ol className="list-decimal list-inside mb-4" {...props} />,
                        li: ({node, ...props}) => <li className="mb-2" {...props} />,
                        a: ({node, ...props}) => <a className="text-blue-600 hover:underline" {...props} />,
                    }}>
                        {policy}
                    </ReactMarkdown>
                </div>
            </div>
        </AnimatedPage>
    );
};

export default PrivacyPolicy;