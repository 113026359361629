import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import ErrorAlert from '../../common/ErrorAlert';
import { handleError } from '../../../utils/errorHandler';
import { Card, CardContent, CardHeader, CardTitle, CardFooter } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "@/components/ui/select";
import { Checkbox } from "@/components/ui/checkbox";
import { Label } from "@/components/ui/label";
import { ENABLE_REGISTRATION, CONTACT_EMAIL } from '../../../constants/appConstants';
import { Mail, Lock, User, Briefcase, Globe, Eye, EyeOff, Loader2 } from 'lucide-react';

const personalEmailDomains = [
    'gmail.com', 'hotmail.com', 'yahoo.com', 'outlook.com', 'aol.com', 'icloud.com',
    'protonmail.com', 'mail.com', 'zoho.com', 'yandex.com', 'gmx.com'
];

const RegisterPage = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        companyName: '',
        jobTitle: '',
        companySize: '',
        companyWebsite: '',
        acceptTerms: false
    });
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');
    const { signUp, user, sendVerificationEmail } = useAuth();
    const navigate = useNavigate();
    const [registerStatus, setRegisterStatus] = useState('idle'); // 'idle', 'loading', 'success', 'error'

    useEffect(() => {
        if (user && user.emailVerified) {
            navigate('/dashboard');
        } else if (user && !user.emailVerified) {
            navigate('/verify-email-sent');
        }
    }, [user, navigate]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSelectChange = (value, name) => {
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleCheckboxChange = (checked) => {
        setFormData(prev => ({ ...prev, acceptTerms: checked }));
    };

    const isValidBusinessEmail = (email) => {
        const domain = email.split('@')[1].toLowerCase();
        return !personalEmailDomains.includes(domain);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setRegisterStatus('loading');
        try {
            if (!formData.acceptTerms) {
                setError('You must accept the terms and conditions and the privacy policy to continue.');
                return;
            }

            if (!isValidBusinessEmail(formData.email)) {
                setError('Please use a valid business email address. Personal email domains are not allowed.');
                return;
            }

            const websiteUrl = formData.companyWebsite.startsWith('http://') || formData.companyWebsite.startsWith('https://')
                ? formData.companyWebsite
                : `https://${formData.companyWebsite}`;

            const userData = {
                ...formData,
                companyWebsite: websiteUrl
            };

            await signUp(userData);
            setRegisterStatus('success');
            navigate('/verify-email-sent');
        } catch (error) {
            const { message } = handleError(error);
            setError(message);
            setRegisterStatus('error');
        }
    };

    const companySizes = [
        "1-10 employees",
        "11-50 employees",
        "51-200 employees",
        "201-500 employees",
        "501-1000 employees",
        "1001+ employees"
    ];

    if (!ENABLE_REGISTRATION) {
        return (
            <div className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                <Card className="w-full max-w-md">
                    <CardHeader>
                        <CardTitle className="text-center text-3xl font-extrabold">
                            Interested in Our Platform?
                        </CardTitle>
                    </CardHeader>
                    <CardContent>
                        <p className="text-center mb-6">
                            Thank you for your interest in our platform. We'd love to show you how it can benefit your business. Please contact us for a personalized demonstration at <a href={`mailto:${CONTACT_EMAIL}`} className="text-blue-600 hover:underline">{CONTACT_EMAIL}</a>.
                        </p>
                        <div className="flex flex-col space-y-4">
                            <Link to="/contact">
                                <Button className="w-full">
                                    Contact Us for a Demo
                                </Button>
                            </Link>
                            <Link to="/">
                                <Button variant="outline" className="w-full">
                                    Return to Home
                                </Button>
                            </Link>
                        </div>
                    </CardContent>
                </Card>
            </div>
        );
    }

    return (
        <div className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <Card className="w-full max-w-md shadow-lg">
                <CardHeader className="space-y-1">
                    <CardTitle className="text-3xl font-extrabold text-center">Create an account</CardTitle>
                    <p className="text-sm text-muted-foreground text-center">Enter your details to get started</p>
                </CardHeader>
                <CardContent>
                    <ErrorAlert message={error} />
                    <form onSubmit={handleSubmit} className="space-y-4 mt-4">
                        <div className="grid grid-cols-2 gap-4">
                            <div className="space-y-2">
                                <Label htmlFor="firstName">First Name</Label>
                                <div className="relative">
                                    <User className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                                    <Input
                                        id="firstName"
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleChange}
                                        className="pl-10"
                                        placeholder="John"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="space-y-2">
                                <Label htmlFor="lastName">Last Name</Label>
                                <div className="relative">
                                    <User className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                                    <Input
                                        id="lastName"
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleChange}
                                        className="pl-10"
                                        placeholder="Doe"
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="space-y-2">
                            <Label htmlFor="email">Business Email</Label>
                            <div className="relative">
                                <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                                <Input
                                    id="email"
                                    name="email"
                                    type="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className="pl-10"
                                    placeholder="john.doe@company.com"
                                    required
                                />
                            </div>
                        </div>
                        <div className="space-y-2">
                            <Label htmlFor="password">Password</Label>
                            <div className="relative">
                                <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                                <Input
                                    id="password"
                                    name="password"
                                    type={showPassword ? "text" : "password"}
                                    value={formData.password}
                                    onChange={handleChange}
                                    className="pl-10 pr-10"
                                    placeholder="••••••••"
                                    required
                                />
                                <Button
                                    type="button"
                                    variant="ghost"
                                    size="sm"
                                    className="absolute right-1 top-1/2 transform -translate-y-1/2"
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? <EyeOff size={18} /> : <Eye size={18} />}
                                </Button>
                            </div>
                        </div>
                        <div className="space-y-2">
                            <Label htmlFor="companyName">Company Name</Label>
                            <div className="relative">
                                <Briefcase className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                                <Input
                                    id="companyName"
                                    name="companyName"
                                    value={formData.companyName}
                                    onChange={handleChange}
                                    className="pl-10"
                                    placeholder="Acme Corporation"
                                    required
                                />
                            </div>
                        </div>
                        <div className="space-y-2">
                            <Label htmlFor="jobTitle">Job Title</Label>
                            <div className="relative">
                                <Briefcase className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                                <Input
                                    id="jobTitle"
                                    name="jobTitle"
                                    value={formData.jobTitle}
                                    onChange={handleChange}
                                    className="pl-10"
                                    placeholder="HR Manager"
                                    required
                                />
                            </div>
                        </div>
                        <div className="space-y-2">
                            <Label htmlFor="companySize">Company Size</Label>
                            <Select
                                name="companySize"
                                value={formData.companySize}
                                onValueChange={(value) => handleSelectChange(value, 'companySize')}
                                required
                            >
                                <SelectTrigger>
                                    <SelectValue placeholder="Select company size" />
                                </SelectTrigger>
                                <SelectContent>
                                    {companySizes.map((size) => (
                                        <SelectItem key={size} value={size}>
                                            {size}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>
                        <div className="space-y-2">
                            <Label htmlFor="companyWebsite">Company Website</Label>
                            <div className="relative">
                                <Globe className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                                <Input
                                    id="companyWebsite"
                                    name="companyWebsite"
                                    type="text"
                                    value={formData.companyWebsite}
                                    onChange={handleChange}
                                    className="pl-10"
                                    placeholder="example.com"
                                    required
                                />
                            </div>
                        </div>
                        <div className="flex items-center space-x-2">
                            <Checkbox
                                id="acceptTerms"
                                checked={formData.acceptTerms}
                                onCheckedChange={handleCheckboxChange}
                            />
                            <label htmlFor="acceptTerms" className="text-sm">
                                I accept the {' '}
                                <a href="/terms-of-service" target="_blank" rel="noopener noreferrer" className="text-primary hover:underline">
                                    terms and conditions
                                </a>
                                {' '} and the {' '}
                                <a href="/privacy-policy" target="_blank" rel="noopener noreferrer" className="text-primary hover:underline">
                                    privacy policy
                                </a>
                            </label>
                        </div>
                        <Button 
                            type="submit" 
                            className="w-full" 
                            disabled={registerStatus === 'loading'}
                        >
                            {registerStatus === 'loading' ? (
                                <>
                                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                    Registering...
                                </>
                            ) : (
                                'Register'
                            )}
                        </Button>
                    </form>
                </CardContent>
                <CardFooter className="text-center">
                    <p className="text-sm text-muted-foreground">
                        Already have an account?{' '}
                        <Link to="/login" className="text-primary hover:underline">
                            Sign in
                        </Link>
                    </p>
                </CardFooter>
            </Card>
        </div>
    );
};

export default RegisterPage;