const workerUrl = process.env.REACT_APP_WORKER_URL;

export const sendChatMessage = async (userMessage, chatHistory) => {
    try {
        const response = await fetch(`${workerUrl}/gemini-chat`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userMessage, chatHistory })
        });

        if (response.ok) {
            const result = await response.json();
            if (result.candidates && result.candidates.length > 0 && result.candidates[0].content.parts.length > 0) {
                // La respuesta ya viene formateada desde el worker
                return result.candidates[0].content.parts[0].text;
            } else {
                throw new Error('Respuesta inesperada del modelo Gemini');
            }
        } else {
            throw new Error('Error generando respuesta del chat');
        }
    } catch (error) {
        console.error('Error con el servicio de chat Gemini:', error);
        throw error;
    }
};