import React from 'react';
import { Link } from 'react-router-dom';
import {
    Users,
    MessageSquare,
    PuzzleIcon,
    Lightbulb,
    ClipboardList,
    Video,
    CheckCircle
} from 'lucide-react';
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { AnimatedPage } from '../../utils/animations';

const InterviewStrategiesPage = () => {
    const interviewTypes = [
        {
            icon: ClipboardList,
            title: "Structured Interviews",
            description: "Standardized questions asked in a specific order to all candidates."
        },
        {
            icon: MessageSquare,
            title: "Behavioral Interviews",
            description: "Questions about past experiences to predict future performance."
        },
        {
            icon: PuzzleIcon,
            title: "Situational Interviews",
            description: "Hypothetical scenarios to assess problem-solving skills."
        },
        {
            icon: Users,
            title: "Panel Interviews",
            description: "Multiple interviewers assess the candidate simultaneously."
        },
        {
            icon: Video,
            title: "Video Interviews",
            description: "Remote interviews conducted via video conferencing tools."
        }
    ];

    const interviewTips = [
        "Prepare thoroughly by reviewing the candidate's resume and job requirements",
        "Create a comfortable environment to help candidates perform their best",
        "Use open-ended questions to encourage detailed responses",
        "Listen actively and take notes during the interview",
        "Provide candidates with information about the role and company culture",
        "Allow time for candidates to ask their own questions",
        "Use a consistent evaluation method for all candidates"
    ];

    return (
        <AnimatedPage>
            <div className="space-y-0">
                {/* Hero Section */}
                <section className="bg-gradient-to-tl from-primary to-muted-foreground text-primary-foreground py-20">
                    <div className="container mx-auto px-4 text-center">
                        <h1 className="text-5xl font-bold mb-4">Interview Strategies</h1>
                        <p className="text-xl mb-8 max-w-2xl mx-auto">
                            Master the art of interviewing to identify the best candidates for your team.
                        </p>
                        <Button asChild>
                            <Link to="/register">Start Free Trial</Link>
                        </Button>
                    </div>
                </section>

                {/* Interview Types Section */}
                <section className="py-20">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold text-center mb-12">Types of Interviews</h2>
                        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
                            {interviewTypes.map((type, index) => (
                                <Card key={index}>
                                    <CardHeader>
                                        <type.icon className="h-8 w-8 mb-2" />
                                        <CardTitle>{type.title}</CardTitle>
                                    </CardHeader>
                                    <CardContent>
                                        <p>{type.description}</p>
                                    </CardContent>
                                </Card>
                            ))}
                        </div>
                    </div>
                </section>

                {/* Interview Tips Section */}
                <section className="bg-muted py-20">
                    <div className="container mx-auto px-4">
                        <div className="flex flex-col md:flex-row items-center">
                            <div className="md:w-1/2 mb-8 md:mb-0">
                                <h2 className="text-3xl font-bold mb-4">Key Interview Tips</h2>
                                <p className="text-xl mb-6">Improve your interviewing skills with these essential tips:</p>
                                <ul className="list-disc list-inside space-y-2">
                                    {interviewTips.map((tip, index) => (
                                        <li key={index}>{tip}</li>
                                    ))}
                                </ul>
                            </div>
                            <div className="md:w-1/2 md:pl-12 flex justify-center">
                                <Lightbulb className="h-64 w-64 text-primary" />
                            </div>
                        </div>
                    </div>
                </section>

                {/* How PrimeApplicants Helps Section */}
                <section className="py-20">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold text-center mb-12">How PrimeApplicants Enhances Your Interviews</h2>
                        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
                            {[
                                { title: "Interview Scheduling", description: "Simplify the process of scheduling interviews, coordinating with multiple interviewers and candidates." },
                                { title: "Customizable Question Banks", description: "Create and manage libraries of interview questions tailored to different roles and interview types." },
                                { title: "Collaborative Evaluation", description: "Enable multiple interviewers to share feedback and ratings on candidates in real-time." },
                                { title: "Interview Guides", description: "Access pre-built interview guides and templates to ensure consistency across your hiring process." }
                            ].map((feature, index) => (
                                <Card key={index}>
                                    <CardHeader>
                                        <CardTitle>{feature.title}</CardTitle>
                                    </CardHeader>
                                    <CardContent>
                                        <p>{feature.description}</p>
                                    </CardContent>
                                </Card>
                            ))}
                        </div>
                    </div>
                </section>

                {/* CTA Section */}
                <section className="bg-secondary text-secondary-foreground py-20 text-center">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold mb-4">Elevate Your Interview Process</h2>
                        <p className="text-xl mb-8">Experience how PrimeApplicants can transform your interviews and hiring decisions.</p>
                        <Button size="lg" asChild>
                            <Link to="/register">Start Your Free Trial Today</Link>
                        </Button>
                    </div>
                </section>
            </div>
        </AnimatedPage>
    );
};

export default InterviewStrategiesPage;