import React from 'react';
import { Card, CardContent } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Checkbox } from "@/components/ui/checkbox";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";

const CareerPageSettings = ({ careerPageSettings, handleCareerPageSettingsChange, handleLogoUpload, handleLogoDelete }) => {
    return (
        <Card>
            <CardContent className="space-y-6 py-4">
                <h3 className="text-2xl font-semibold">Career Page Settings</h3>
                <div className="space-y-4">
                    <Label htmlFor="logo-upload">Company Logo</Label>
                    <Input
                        id="logo-upload"
                        type="file"
                        accept="image/*"
                        onChange={handleLogoUpload}
                    />
                    {careerPageSettings.companyLogo && (
                        <div className="space-y-2">
                            <img src={careerPageSettings.companyLogo} alt="Company Logo" className="w-32 h-32 object-contain" />
                            <Button variant="destructive" onClick={handleLogoDelete}>
                                Delete Logo
                            </Button>
                        </div>
                    )}
                </div>
                <div className="space-y-2">
                    <Label htmlFor="welcome-message">Welcome Message</Label>
                    <Input
                        id="welcome-message"
                        value={careerPageSettings.welcomeMessage}
                        onChange={(e) => handleCareerPageSettingsChange('welcomeMessage', e.target.value)}
                    />
                </div>
                <div className="space-y-2">
                    <Label>Required Fields for Job Applications</Label>
                    <Card>
                        <CardContent className="space-y-2 flex flex-col py-4">
                            {['name', 'email', 'phone', 'resume'].map((field) => (
                                <div key={field} className="flex items-center space-x-2">
                                    <Checkbox
                                        id={field}
                                        checked={careerPageSettings.requiredFields.includes(field)}
                                        onCheckedChange={() => handleCareerPageSettingsChange('requiredFields', field)}
                                    />
                                    <Label htmlFor={field}>
                                        {field.charAt(0).toUpperCase() + field.slice(1)}
                                    </Label>
                                </div>
                            ))}
                        </CardContent>
                    </Card>
                </div>
            </CardContent>
        </Card>
    );
};

export default CareerPageSettings;