// src/services/candidateService.js

import { db, storage } from '../config/firebase.config';
import { collection, addDoc, updateDoc, doc, getDocs, getDoc, query, where, writeBatch, arrayUnion, getCountFromServer, orderBy, limit, startAfter } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { FIRESTORE_COLLECTIONS, JOB_BOARDS } from '../constants/appConstants';
import { handleError } from '../utils/errorHandler';
import { sendEmail } from './emailService';
import { generateCandidateConfirmationEmail } from '../templates/candidateConfirmationEmail';
import { getUserProfile } from './userService';
import { PAGINATION } from '../constants/appConstants';

const CANDIDATES_COLLECTION = FIRESTORE_COLLECTIONS.CANDIDATES;
const INTERVIEWS_COLLECTION = FIRESTORE_COLLECTIONS.INTERVIEWS;

export const createCandidate = async (candidateData) => {
    if (!candidateData.userId) {
        throw handleError("User ID is required to create a candidate");
    }
    try {
        let resumeUrl = null;

        if (candidateData.resume instanceof File) {
            const storageRef = ref(storage, `resumes/${candidateData.userId}/${Date.now()}_${candidateData.resume.name}`);
            await uploadBytes(storageRef, candidateData.resume);
            resumeUrl = await getDownloadURL(storageRef);
        }

        const candidateToSave = {
            ...candidateData,
            resumeUrl,
            createdAt: new Date(),
            appliedAt: new Date(),
            updatedAt: new Date(),
            isManuallyAdded: true
        };

        delete candidateToSave.resume;

        const docRef = await addDoc(collection(db, CANDIDATES_COLLECTION), candidateToSave);
        return { id: docRef.id, ...candidateToSave };
    } catch (error) {
        throw handleError(error);
    }
};

export const updateCandidate = async (id, candidateData) => {
    if (!id) {
        throw handleError("Candidate ID is required to update a candidate");
    }
    try {
        const docRef = doc(db, CANDIDATES_COLLECTION, id);
        const candidateSnap = await getDoc(docRef);

        if (!candidateSnap.exists()) {
            throw new Error("No such candidate!");
        }

        const currentData = candidateSnap.data();
        let updatedData = { ...currentData, ...candidateData, updatedAt: new Date() };

        // Manejar el archivo de CV si se proporciona uno nuevo
        if (candidateData.resume instanceof File) {
            const storageRef = ref(storage, `resumes/${updatedData.userId}/${Date.now()}_${candidateData.resume.name}`);
            await uploadBytes(storageRef, candidateData.resume);
            updatedData.resumeUrl = await getDownloadURL(storageRef);
        }

        // Eliminar el campo 'resume' antes de actualizar Firestore
        delete updatedData.resume;

        await updateDoc(docRef, updatedData);
        return { id, ...updatedData };
    } catch (error) {
        throw handleError(error);
    }
};

export const getCandidate = async (id) => {
    if (!id) {
        throw handleError("Candidate ID is required to fetch a candidate");
    }
    try {
        const docRef = doc(db, CANDIDATES_COLLECTION, id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            return { id: docSnap.id, ...docSnap.data() };
        } else {
            throw handleError("No such candidate!");
        }
    } catch (error) {
        throw handleError(error);
    }
};

export const getCandidates = async (userId) => {
    if (!userId) {
        throw handleError("User ID is required to fetch candidates");
    }
    try {
        const q = query(collection(db, CANDIDATES_COLLECTION), where('userId', '==', userId));
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
    } catch (error) {
        throw handleError(error);
    }
};

export const deleteCandidate = async (id) => {
    if (!id) {
        throw handleError("Candidate ID is required to delete a candidate");
    }
    try {
        const batch = writeBatch(db);

        const candidateRef = doc(db, CANDIDATES_COLLECTION, id);
        const candidateSnap = await getDoc(candidateRef);

        if (candidateSnap.exists()) {
            const candidateData = candidateSnap.data();

            if (candidateData.resumeUrl) {
                const resumeRef = ref(storage, candidateData.resumeUrl);
                await deleteObject(resumeRef);
            }

            batch.delete(candidateRef);

            const interviewsQuery = query(collection(db, INTERVIEWS_COLLECTION), where('candidateId', '==', id));
            const interviewsSnapshot = await getDocs(interviewsQuery);
            interviewsSnapshot.forEach((doc) => {
                batch.delete(doc.ref);
            });

            await batch.commit();
        } else {
            throw handleError("No such candidate!");
        }
    } catch (error) {
        throw handleError(error);
    }
};

export const deleteManyCandidates = async (ids) => {
    if (!ids) {
        throw handleError("Candidate IDs are required to delete multiple candidates");
    }
    const batch = writeBatch(db);
    const deletePromises = ids.map(async (id) => {
        const candidateRef = doc(db, CANDIDATES_COLLECTION, id);
        const candidateSnap = await getDoc(candidateRef);

        if (candidateSnap.exists()) {
            const candidateData = candidateSnap.data();

            if (candidateData.resumeUrl) {
                const resumeRef = ref(storage, candidateData.resumeUrl);
                await deleteObject(resumeRef);
            }

            batch.delete(candidateRef);

            const interviewsQuery = query(collection(db, INTERVIEWS_COLLECTION), where('candidateId', '==', id));
            const interviewsSnapshot = await getDocs(interviewsQuery);
            interviewsSnapshot.forEach((doc) => {
                batch.delete(doc.ref);
            });
        }
    });

    try {
        await Promise.all(deletePromises);
        await batch.commit();
    } catch (error) {
        throw handleError(error);
    }
};

export const getCandidatesByStage = async (userId, stage) => {
    if (!userId) {
        throw handleError("User ID is required to fetch candidates");
    }
    try {
        const q = query(
            collection(db, CANDIDATES_COLLECTION),
            where('userId', '==', userId),
            where('stage', '==', stage),
            orderBy('appliedAt', 'desc')
        );

        const snapshot = await getDocs(q);
        const candidates = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));

        return candidates;
    } catch (error) {
        throw handleError(error);
    }
};

export const submitJobApplication = async (slug, jobId, formData) => {
    try {
        const userQuery = query(collection(db, 'users'), where('slug', '==', slug));
        const userSnapshot = await getDocs(userQuery);

        if (userSnapshot.empty) {
            throw handleError('User not found');
        }
        const userId = userSnapshot.docs[0].id;

        let resumeUrl = null;

        if (formData.resume) {
            const resumeRef = ref(storage, `resumes/${slug}/${jobId}/${formData.name}-${Date.now()}`);
            await uploadBytes(resumeRef, formData.resume);
            resumeUrl = await getDownloadURL(resumeRef);
        }

        const candidateData = {
            jobId,
            userId,
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
            resumeUrl,
            stage: 'New',
            appliedAt: new Date(),
            source: formData.source || 'Career Page'
        };

        await addDoc(collection(db, CANDIDATES_COLLECTION), candidateData);

        const userProfile = await getUserProfile(userId);
        const emailSettings = userProfile.emailSettings || {};

        if (emailSettings.sendConfirmationEmail) {
            const htmlContent = generateCandidateConfirmationEmail(
                formData.name,
                userProfile.companyName,
                emailSettings
            );

            await sendEmail({
                from: 'PrimeApplicants <noreply@primeapplicants.com>',
                to: [formData.email],
                subject: emailSettings.confirmationSubject || 'Application Confirmation',
                html: htmlContent
            });
        }
    } catch (error) {
        throw handleError(error);
    }
};

export const addNoteToCandidate = async (candidateId, noteContent, userId) => {
    try {
        const candidateRef = doc(db, CANDIDATES_COLLECTION, candidateId);
        const newNote = {
            content: noteContent,
            createdAt: new Date().toISOString(),
            createdBy: userId
        };

        await updateDoc(candidateRef, {
            notes: arrayUnion(newNote)
        });

        return newNote;
    } catch (error) {
        throw handleError(error);
    }
};

export const getNotesForCandidate = async (candidateId) => {
    if (!candidateId) {
        throw handleError("Candidate ID is required to fetch notes for a candidate");
    }
    try {
        const candidateRef = doc(db, CANDIDATES_COLLECTION, candidateId);
        const candidateSnap = await getDoc(candidateRef);

        if (candidateSnap.exists()) {
            const candidateData = candidateSnap.data();
            return candidateData.notes || [];
        } else {
            throw new Error("Candidate not found");
        }
    } catch (error) {
        throw handleError(error);
    }
};

export const updateNoteInCandidate = async (candidateId, noteIndex, updatedContent) => {
    if (!candidateId) {
        throw handleError("Candidate ID is required to update a note in a candidate");
    }
    try {
        const candidateRef = doc(db, CANDIDATES_COLLECTION, candidateId);
        const candidateSnap = await getDoc(candidateRef);

        if (candidateSnap.exists()) {
            const candidateData = candidateSnap.data();
            const updatedNotes = [...(candidateData.notes || [])];
            updatedNotes[noteIndex] = {
                ...updatedNotes[noteIndex],
                content: updatedContent,
                updatedAt: new Date().toISOString()
            };

            await updateDoc(candidateRef, { notes: updatedNotes });
            return updatedNotes[noteIndex];
        } else {
            throw new Error("Candidate not found");
        }
    } catch (error) {
        throw handleError(error);
    }
};

export const deleteNoteFromCandidate = async (candidateId, noteIndex) => {
    if (!candidateId) {
        throw handleError("Candidate ID is required to delete a note from a candidate");
    }
    try {
        const candidateRef = doc(db, CANDIDATES_COLLECTION, candidateId);
        const candidateSnap = await getDoc(candidateRef);

        if (candidateSnap.exists()) {
            const candidateData = candidateSnap.data();
            const updatedNotes = [...(candidateData.notes || [])];
            updatedNotes.splice(noteIndex, 1);

            await updateDoc(candidateRef, { notes: updatedNotes });
            return true;
        } else {
            throw new Error("Candidate not found");
        }
    } catch (error) {
        throw handleError(error);
    }
};

export const getCandidateCountByStage = async (userId, stage) => {
    if (!userId) {
        throw handleError("User ID is required to fetch candidate count");
    }
    try {
        const q = query(
            collection(db, CANDIDATES_COLLECTION),
            where('userId', '==', userId),
            where('stage', '==', stage)
        );
        const snapshot = await getCountFromServer(q);
        return snapshot.data().count;
    } catch (error) {
        throw handleError(error);
    }
};

export const getCandidateCountBySource = async (userId) => {
    if (!userId) {
        throw handleError("User ID is required to fetch candidate count");
    }
    try {
        const sources = ['Manual', 'Career Page', ...Object.keys(JOB_BOARDS).map(board => board.toLowerCase())];
        const counts = {};
        for (const source of sources) {
            const q = query(
                collection(db, CANDIDATES_COLLECTION),
                where('userId', '==', userId),
                where('source', '==', source)
            );
            const snapshot = await getCountFromServer(q);
            counts[source.toLowerCase().replace(' ', '')] = snapshot.data().count;
        }
        return counts;
    } catch (error) {
        throw handleError(error);
    }
};

export const getCandidatesByJob = async (jobId) => {
    if (!jobId) {
        throw handleError("Job ID is required to fetch candidates");
    }
    try {
        const q = query(
            collection(db, CANDIDATES_COLLECTION),
            where('jobId', '==', jobId)
        );
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
    } catch (error) {
        throw handleError(error);
    }
};

export const deleteCandidateResume = async (candidateId) => {
    if (!candidateId) {
        throw handleError("Candidate ID is required to delete a resume");
    }
    try {
        const candidateRef = doc(db, CANDIDATES_COLLECTION, candidateId);

        const candidateDoc = await getDoc(candidateRef);
        const candidateData = candidateDoc.data();

        if (candidateData.resumeUrl) {
            const resumeRef = ref(storage, candidateData.resumeUrl);

            await deleteObject(resumeRef);

            await updateDoc(candidateRef, {
                resumeUrl: null
            });

        } else {
            throw handleError('No resume found to delete');
        }

        return true;
    } catch (error) {
        throw handleError(error);
    }
};