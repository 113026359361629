import React, { useState, useEffect } from 'react';
import { Card, CardContent } from "@/components/ui/card";
import { Switch } from "@/components/ui/switch";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { generateCandidateConfirmationEmail } from '../../../templates/candidateConfirmationEmail';
import { Loader2 } from 'lucide-react';

const EmailSettings = ({ emailSettings, onChange, onSaveModal }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [localSettings, setLocalSettings] = useState(emailSettings);
    const [modalSettings, setModalSettings] = useState({
        confirmationSubject: '',
        confirmationBody: ''
    });
    const [previewHtml, setPreviewHtml] = useState('');
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        setLocalSettings(emailSettings);
    }, [emailSettings]);

    useEffect(() => {
        const preview = generateCandidateConfirmationEmail(
            '{candidateName}',
            '{companyName}',
            {
                confirmationSubject: modalSettings.confirmationSubject,
                confirmationBody: modalSettings.confirmationBody
            }
        );
        setPreviewHtml(preview);
    }, [modalSettings]);

    const handleModalOpen = () => {
        setModalSettings({
            confirmationSubject: localSettings.confirmationSubject,
            confirmationBody: localSettings.confirmationBody
        });
        setIsModalOpen(true);
    };

    const handleModalChange = (key, value) => {
        setModalSettings(prevSettings => ({
            ...prevSettings,
            [key]: value
        }));
    };

    const handleModalSave = async () => {
        setIsSaving(true);
        try {
            const newSettings = {
                ...localSettings,
                ...modalSettings
            };
            setLocalSettings(newSettings);
            await onSaveModal(newSettings);
            setIsModalOpen(false);
        } catch (error) {
            console.error('Error saving email settings:', error);
            // You might want to show an error message to the user here
        } finally {
            setIsSaving(false);
        }
    };

    const handleSwitchChange = async (checked) => {
        const newSettings = {
            ...localSettings,
            sendConfirmationEmail: checked
        };
        setLocalSettings(newSettings);
        await onChange(newSettings);
    };

    return (
        <div>
            <h3 className="text-xl font-semibold mb-4">Email Settings</h3>
            <div className="mb-4 flex items-center space-x-2">
                <Switch
                    checked={localSettings.sendConfirmationEmail}
                    onCheckedChange={handleSwitchChange}
                    id="send-confirmation-email"
                />
                <Label htmlFor="send-confirmation-email">
                    Send confirmation email to applicants
                </Label>
            </div>
            <Button onClick={handleModalOpen}>
                Edit Confirmation Email
            </Button>

            <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
                <DialogContent className="sm:max-w-[725px]">
                    <DialogHeader>
                        <DialogTitle>Edit Confirmation Email</DialogTitle>
                    </DialogHeader>
                    <div className="space-y-4">
                        <div className="space-y-2">
                            <Label htmlFor="confirmation-subject">Subject</Label>
                            <Input
                                id="confirmation-subject"
                                value={modalSettings.confirmationSubject}
                                onChange={(e) => handleModalChange('confirmationSubject', e.target.value)}
                            />
                        </div>
                        <div className="space-y-2">
                            <Label>Email Body</Label>
                            <ReactQuill
                                theme="snow"
                                value={modalSettings.confirmationBody}
                                onChange={(content) => handleModalChange('confirmationBody', content)}
                                modules={{
                                    toolbar: [
                                        [{ 'header': [1, 2, false] }],
                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                                        ['link'],
                                        ['clean']
                                    ],
                                }}
                            />
                        </div>
                        <div className="space-y-2">
                            <h4 className="text-lg font-semibold">Email Preview</h4>
                            <Card>
                                <CardContent className="h-96 overflow-auto">
                                    <div dangerouslySetInnerHTML={{ __html: previewHtml }} />
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                    <DialogFooter>
                        <Button onClick={handleModalSave} disabled={isSaving}>
                            {isSaving ? (
                                <>
                                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                    Saving...
                                </>
                            ) : (
                                'Save'
                            )}
                        </Button>
                        <Button variant="outline" onClick={() => setIsModalOpen(false)}>Cancel</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default EmailSettings;