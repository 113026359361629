import React from 'react';
import { Link } from 'react-router-dom';
import {
    Globe,
    Video,
    Users,
    Clock,
    MessageCircle,
    FileText,
    Shield,
    Lightbulb,
    ChevronRight
} from 'lucide-react';
import { AnimatedPage } from '../../utils/animations';
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

export const articleMeta = {
    title: "The Ultimate Guide to Remote Hiring",
    description: "Master the art of recruiting and onboarding top talent in a virtual environment.",
    date: "August 9, 2024",
    readTime: "5 min read",
};

const RemoteHiringGuideArticle = () => {
    const { title, description, date, readTime } = articleMeta;

    const steps = [
        { icon: Globe, title: "Define Your Remote Work Policy", description: "Establish clear guidelines for remote work expectations and practices." },
        { icon: FileText, title: "Craft Remote-Friendly Job Descriptions", description: "Highlight remote work benefits and necessary skills for virtual collaboration." },
        { icon: Users, title: "Leverage Online Recruitment Platforms", description: "Utilize specialized job boards and social media to reach remote talent." },
        { icon: Video, title: "Master Virtual Interviews", description: "Develop a structured approach to conducting effective video interviews." },
        { icon: Clock, title: "Assess Time Management Skills", description: "Evaluate candidates' ability to work independently and manage their time effectively." },
        { icon: MessageCircle, title: "Test Communication Abilities", description: "Use various communication tools to assess candidates' virtual communication skills." },
        { icon: Shield, title: "Implement a Robust Onboarding Process", description: "Create a comprehensive virtual onboarding program for new remote hires." },
        { icon: Lightbulb, title: "Foster a Remote-Friendly Culture", description: "Develop strategies to integrate remote employees into your company culture." }
    ];

    return (
        <AnimatedPage>
            <article className="max-w-5xl mx-auto px-4 py-12">
                {/* Breadcrumb */}
                <nav className="flex mb-8 text-sm" aria-label="Breadcrumb">
                    <ol className="inline-flex items-center space-x-1 md:space-x-3">
                        <li className="inline-flex items-center">
                            <Link to="/" className="inline-flex items-center text-muted-foreground hover:text-primary">
                                Home
                            </Link>
                        </li>
                        <li>
                            <div className="flex items-center">
                                <ChevronRight className="w-4 h-4 text-muted-foreground" />
                                <Link to="/blog" className="ml-1 text-muted-foreground hover:text-primary md:ml-2">
                                    Blog
                                </Link>
                            </div>
                        </li>
                        <li aria-current="page">
                            <div className="flex items-center">
                                <ChevronRight className="w-4 h-4 text-muted-foreground" />
                                <span className="ml-1 text-primary md:ml-2">Remote Hiring Guide</span>
                            </div>
                        </li>
                    </ol>
                </nav>

                <header className="mb-12 text-center">
                    <h1 className="text-5xl font-bold mb-6">{title}</h1>
                    <div className="flex items-center justify-center text-muted-foreground mb-6">
                        <Users className="mr-2 h-5 w-5" />
                        <span className="mr-4">PrimeApplicants Team</span>
                        <Clock className="mr-2 h-5 w-5" />
                        <span className="mr-4">{date}</span>
                        <Clock className="mr-2 h-5 w-5" />
                        <span>{readTime}</span>
                    </div>
                    <p className="text-xl text-muted-foreground max-w-3xl mx-auto">{description}</p>
                </header>

                <section className="prose max-w-none mb-12">
                    <p>As the world of work continues to evolve, remote hiring has become an essential skill for recruiters and HR professionals. This comprehensive guide will walk you through the process of attracting, evaluating, and onboarding remote talent effectively.</p>
                    <p>Whether you're new to remote hiring or looking to refine your existing processes, this guide will provide valuable insights and practical strategies to help you build a strong remote team.</p>
                </section>

                <section className="mb-16">
                    <h2 className="text-3xl font-bold mb-8">Key Steps in Remote Hiring</h2>
                    <div className="grid md:grid-cols-2 gap-8">
                        {steps.map((step, index) => (
                            <Card key={index} className="hover:shadow-lg transition-shadow duration-300">
                                <CardHeader>
                                    <step.icon className="h-12 w-12 text-primary mb-4" />
                                    <CardTitle>{step.title}</CardTitle>
                                </CardHeader>
                                <CardContent>
                                    <p>{step.description}</p>
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                </section>

                {/* Banner de llamado a la acción adicional */}
                <section className="bg-primary text-primary-foreground py-12 px-8 rounded-lg mb-16">
                    <h2 className="text-3xl font-bold mb-4">Streamline Your Remote Hiring Process</h2>
                    <p className="mb-6">Use PrimeApplicants to efficiently manage your remote recruitment. Try our platform and see the difference in your hiring outcomes.</p>
                    <Button asChild size="lg" variant="secondary">
                        <Link to="/register">Start Free Trial</Link>
                    </Button>
                </section>

                <section className="prose max-w-none mb-12">
                    <h2 className="text-3xl font-bold mb-6">Best Practices for Remote Hiring</h2>
                    <ul className="list-disc pl-5 space-y-4">
                        <li>Prioritize candidates with previous remote work experience.</li>
                        <li>Use asynchronous communication methods during the hiring process.</li>
                        <li>Implement skills-based assessments relevant to remote work.</li>
                        <li>Be transparent about your remote work policies and expectations.</li>
                        <li>Utilize technology to streamline the remote hiring process.</li>
                        <li>Provide a realistic preview of your remote work environment.</li>
                        <li>Emphasize your company's commitment to work-life balance.</li>
                        <li>Continuously gather feedback to improve your remote hiring process.</li>
                    </ul>
                </section>

                <section className="prose max-w-none mb-12">
                    <h2 className="text-3xl font-bold mb-6">Overcoming Remote Hiring Challenges</h2>
                    <div className="space-y-6">
                        <div>
                            <h3 className="text-xl font-semibold mb-2">Challenge: Assessing Cultural Fit</h3>
                            <p>Solution: Use situational questions and virtual team interactions to evaluate how candidates align with your company culture.</p>
                        </div>
                        <div>
                            <h3 className="text-xl font-semibold mb-2">Challenge: Technical Difficulties</h3>
                            <p>Solution: Have a backup plan for video interviews and provide clear instructions for accessing virtual assessment tools.</p>
                        </div>
                        <div>
                            <h3 className="text-xl font-semibold mb-2">Challenge: Building Trust</h3>
                            <p>Solution: Increase transparency throughout the hiring process and maintain frequent communication with candidates.</p>
                        </div>
                    </div>
                </section>

                <section className="prose max-w-none mb-12">
                    <h2 className="text-3xl font-bold mb-6">Conclusion</h2>
                    <p>Remote hiring presents unique challenges, but with the right strategies and tools, it can be an effective way to build a talented and diverse team. By following this guide and continually refining your processes, you can master the art of remote hiring and set your organization up for success in the digital age.</p>
                    <p>Remember, successful remote hiring is not just about finding skilled individuals, but also about identifying candidates who thrive in a virtual work environment and align with your company's values and culture.</p>
                </section>
            </article>
            {/* Llamado a la acción estándar actualizado */}
            <section className="bg-secondary text-secondary-foreground py-20 text-center">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold mb-4">Ready to Excel in Remote Hiring?</h2>
                    <p className="text-xl mb-8">Use PrimeApplicants to streamline your remote hiring process and find top talent globally.</p>
                    <Button size="lg" asChild>
                        <Link to="/register">Start Your Free Trial Today</Link>
                    </Button>
                </div>
            </section>
        </AnimatedPage>
    );
};

export default RemoteHiringGuideArticle;