export function generateCandidateConfirmationEmail(candidateName, companyName, emailSettings) {
    const defaultSubject = 'Application Confirmation';
    const defaultBody = `
        <p>Thank you for applying for a position at ${companyName}. We have received your application and will review it soon.</p>
    `;

    const subject = emailSettings.confirmationSubject || defaultSubject;
    const bodyContent = emailSettings.confirmationBody || defaultBody;

    return `
    <html>

    <head>
        <style>
            body {
                font-family: Arial, sans-serif;
                line-height: 1.6;
                color: #333;
                margin: 0;
                padding: 0;
                background-color: #f4f4f4;
            }

            .container {
                max-width: 600px;
                margin: 0 auto;
                background-color: #ffffff;
            }

            .header {
                background-color: #0f172a;
                padding: 10px;
                text-align: center;
            }

            .header img {
                max-width: 85%;
            }

            .content {
                padding: 20px;
                color: #55575d;
                font-size: 15px;
            }

            .footer {
                background-color: #0f172a;
                color: #ffffff;
                text-align: left;
                padding: 15px 30px;
                font-size: 15px;
                text-align: center;
            }

            .footer a {
                text-decoration: underline;
            }
        </style>
    </head>

    <body>
        <div class="container">
            <div class="header">
                <img src="https://primeapplicants.com/assets/img/logoWhite.png"
                    alt="Prime Applicants Logo">
            </div>
            <div class="content">
                <p>Hi, ${candidateName}</p>
                ${bodyContent}
            </div>
            <div class="footer">
                Need help? Contact us at <a href="mailto:info@primeapplicants.com">info@primeapplicants.com</a>
            </div>
        </div>
    </body>

    </html>
    `;
}