import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getInterviews, deleteInterview, checkAndUpdateInterviewStatus } from '../../../services/interviewService';
import ErrorAlert from '../../common/ErrorAlert';
import { handleError } from '../../../utils/errorHandler';
import { AnimatedPage } from '../../../utils/animations';
import { Calendar, User, Briefcase, Clock } from 'lucide-react';

import { Card, CardContent } from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import { Skeleton } from "@/components/ui/skeleton"
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "@/components/ui/dialog"

const InterviewList = () => {
    const [interviews, setInterviews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [interviewToDelete, setInterviewToDelete] = useState(null);
    const navigate = useNavigate();

    const fetchInterviews = async () => {
        try {
            let fetchedInterviews = await getInterviews();
            fetchedInterviews = await Promise.all(fetchedInterviews.map(async (interview) => {
                const updatedInterview = await checkAndUpdateInterviewStatus(interview);
                return updatedInterview;
            }));
            setInterviews(fetchedInterviews.filter(interview => interview.status !== 'completed'));
        } catch (err) {
            const { message } = handleError(err);
            setError(message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchInterviews();
    }, []);

    const handleDeleteInterview = async () => {
        if (!interviewToDelete) return;
        try {
            await deleteInterview(interviewToDelete);
            setInterviews(interviews.filter(interview => interview.id !== interviewToDelete));
            setShowConfirmModal(false);
            setInterviewToDelete(null);
        } catch (err) {
            const { message } = handleError(err);
            setError(message);
        }
    };

    const openConfirmModal = (interviewId) => {
        setInterviewToDelete(interviewId);
        setShowConfirmModal(true);
    };

    const InterviewCardSkeleton = () => (
        <Card>
            <CardContent className="p-6">
                <Skeleton className="h-6 w-48 mb-3" />
                <div className="flex items-center mb-2">
                    <Skeleton className="h-5 w-5 mr-2" />
                    <Skeleton className="h-4 w-40" />
                </div>
                <div className="flex items-center mb-2">
                    <Skeleton className="h-5 w-5 mr-2" />
                    <Skeleton className="h-4 w-32" />
                </div>
                <div className="flex items-center mb-2">
                    <Skeleton className="h-5 w-5 mr-2" />
                    <Skeleton className="h-4 w-36" />
                </div>
                <div className="flex items-center mb-4">
                    <Skeleton className="h-5 w-5 mr-2" />
                    <Skeleton className="h-4 w-32" />
                </div>
                <div className="flex justify-between">
                    <Skeleton className="h-10 w-32" />
                    <Skeleton className="h-10 w-24" />
                </div>
            </CardContent>
        </Card>
    );

    if (loading) return (
        <AnimatedPage>
            <Skeleton className="h-10 w-64 mx-auto mb-6" />
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {[...Array(6)].map((_, index) => (
                    <InterviewCardSkeleton key={index} />
                ))}
            </div>
        </AnimatedPage>
    );
    if (error) return <ErrorAlert message={error} />;

    return (
        <AnimatedPage>
            <h1 className="text-3xl font-bold mb-6 text-center">Scheduled Interviews</h1>
            {interviews.length === 0 ? (
                <p className="text-center text-gray-600">No interviews scheduled.</p>
            ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {interviews.map((interview) => (
                        <Card key={interview.id}>
                            <CardContent className="p-6">
                                <h2 className="text-xl font-semibold mb-3 flex items-center">
                                    <User className="h-5 w-5 mr-2" />
                                    {interview.candidateName}
                                </h2>
                                <p className="mb-2 flex items-center">
                                    <Briefcase className="h-5 w-5 mr-2" />
                                    {interview.jobTitle}
                                </p>
                                <p className="mb-2 flex items-center">
                                    <Calendar className="h-5 w-5 mr-2" />
                                    {new Date(interview.interviewDateTime).toLocaleDateString()}
                                </p>
                                <p className="mb-4 flex items-center">
                                    <Clock className="h-5 w-5 mr-2" />
                                    {new Date(interview.interviewDateTime).toLocaleTimeString()}
                                </p>
                                <div className="flex justify-between">
                                    <Button variant="outline" onClick={() => navigate(`/candidates/${interview.candidateId}`)}>
                                        View Candidate
                                    </Button>
                                    <Button variant="destructive" onClick={() => openConfirmModal(interview.id)}>
                                        Cancel
                                    </Button>
                                </div>
                            </CardContent>
                        </Card>
                    ))}
                </div>
            )}
            <Dialog open={showConfirmModal} onOpenChange={setShowConfirmModal}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Cancel Interview</DialogTitle>
                        <DialogDescription>
                            Are you sure you want to cancel this interview? This action cannot be undone.
                        </DialogDescription>
                    </DialogHeader>
                    <DialogFooter>
                        <Button variant="outline" onClick={() => setShowConfirmModal(false)}>Cancel</Button>
                        <Button variant="destructive" onClick={handleDeleteInterview}>Confirm</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </AnimatedPage>
    );
};

export default InterviewList;