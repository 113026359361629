import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Check, X, HelpCircle, Zap, Users, Building2, Star, ArrowRight } from 'lucide-react';
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { AnimatedPage } from '../utils/animations';
import { Switch } from "@/components/ui/switch";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { Badge } from "@/components/ui/badge";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";

const PricingPage = () => {
    const [isAnnual, setIsAnnual] = useState(false);

    const plans = [
        {
            name: "Free Plan",
            price: isAnnual ? "0" : "0",
            features: [
                { name: "Up to 10 job postings", icon: <Zap className="h-4 w-4 mr-2" /> },
                { name: "Basic candidate management", icon: <Users className="h-4 w-4 mr-2" /> },
                { name: "Standard career page", icon: <Building2 className="h-4 w-4 mr-2" /> },
                { name: "Email support", icon: <Star className="h-4 w-4 mr-2" /> }
            ],
            notIncluded: [
                "Advanced analytics",
                "Custom branding",
                "API access",
                "Priority support"
            ],
            badge: "Best for Startups",
            highlighted: false
        },
        {
            name: "Premium (Coming Soon)",
            price: isAnnual ? "79" : "99",
            features: [
                { name: "Unlimited job postings", icon: <Zap className="h-4 w-4 mr-2" /> },
                { name: "Advanced candidate management", icon: <Users className="h-4 w-4 mr-2" /> },
                { name: "Customizable career page", icon: <Building2 className="h-4 w-4 mr-2" /> },
                { name: "Advanced analytics", icon: <Star className="h-4 w-4 mr-2" /> },
                { name: "Custom branding", icon: <Star className="h-4 w-4 mr-2" /> },
                { name: "API access", icon: <Star className="h-4 w-4 mr-2" /> },
                { name: "Priority support", icon: <Star className="h-4 w-4 mr-2" /> }
            ],
            notIncluded: [],
            badge: "Most Popular",
            highlighted: true
        },
        {
            name: "Enterprise (Coming Soon)",
            price: "Custom",
            features: [
                { name: "All Premium features", icon: <Check className="h-4 w-4 mr-2" /> },
                { name: "Dedicated account manager", icon: <Users className="h-4 w-4 mr-2" /> },
                { name: "Custom integrations", icon: <Building2 className="h-4 w-4 mr-2" /> },
                { name: "On-premise deployment option", icon: <Star className="h-4 w-4 mr-2" /> },
                { name: "24/7 phone support", icon: <Star className="h-4 w-4 mr-2" /> }
            ],
            notIncluded: [],
            badge: "For Large Teams",
            highlighted: false
        }
    ];

    const faqs = [
        {
            question: "What's included in the free plan?",
            answer: "The free plan includes up to 10 job postings, basic candidate management, a standard career page, and email support."
        },
        {
            question: "Can I upgrade or downgrade my plan at any time?",
            answer: "Yes, you can upgrade or downgrade your plan at any time. Changes will be reflected in your next billing cycle."
        },
        {
            question: "Do you offer a discount for non-profit organizations?",
            answer: "Yes, we offer special pricing for non-profit organizations. Please contact our sales team for more information."
        },
        {
            question: "Is there a long-term contract?",
            answer: "No, our plans are billed monthly or annually with no long-term commitment. You can cancel at any time."
        }
    ];

    return (
        <AnimatedPage>
            <div className="space-y-12">
                {/* Hero Section */}
                <section className="bg-gradient-to-tl from-primary to-muted-foreground text-primary-foreground py-20">
                    <div className="container mx-auto px-4 text-center">
                        <h1 className="text-5xl font-bold mb-4">Choose Your Plan</h1>
                        <p className="text-xl mb-8 max-w-2xl mx-auto">Select the perfect plan for your recruitment needs and start optimizing your hiring process today.</p>
                    </div>
                </section>

                {/* Pricing Plans Section */}
                <section className="py-20">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold text-center mb-6">Our Pricing Plans</h2>
                        <div className="flex items-center justify-center space-x-4 mb-6">
                            <span className={`text-lg ${!isAnnual ? 'font-bold' : ''}`}>Monthly</span>
                            <Switch checked={isAnnual} onCheckedChange={setIsAnnual} />
                            <span className={`text-lg ${isAnnual ? 'font-bold' : ''}`}>Annual (Save 20%)</span>
                        </div>
                        <div className="grid md:grid-cols-3 gap-8">
                            {plans.map((plan, index) => (
                                <Card key={index} className={`flex flex-col h-full ${plan.highlighted ? 'border-primary shadow-lg' : ''}`}>
                                    <CardHeader>
                                        <div className="flex justify-between items-center mb-2">
                                            <CardTitle className="text-2xl font-bold">{plan.name}</CardTitle>
                                            {plan.badge && (
                                                <Badge variant="secondary">{plan.badge}</Badge>
                                            )}
                                        </div>
                                        <p className="text-4xl font-bold text-primary">
                                            {plan.price === "Custom" ? (
                                                "Custom"
                                            ) : (
                                                <>
                                                    ${plan.price}<span className="text-xl font-normal text-muted-foreground">/{isAnnual ? 'year' : 'month'}</span>
                                                </>
                                            )}
                                        </p>
                                    </CardHeader>
                                    <CardContent className="flex flex-col h-full">
                                        <h4 className="font-semibold mb-4">Key Features:</h4>
                                        <ul className="mb-8 space-y-3 flex-grow">
                                            {plan.features.map((feature, i) => (
                                                <li key={i} className="flex items-center text-foreground">
                                                    {feature.icon}
                                                    <span>{feature.name}</span>
                                                </li>
                                            ))}
                                            {plan.notIncluded.map((feature, i) => (
                                                <li key={i} className="flex items-center text-muted-foreground">
                                                    <X className="h-5 w-5 mr-2 flex-shrink-0" />
                                                    <span>{feature}</span>
                                                </li>
                                            ))}
                                        </ul>
                                        <div className="mt-auto">
                                            <Button asChild className="w-full" variant={index === 0 ? "default" : "secondary"} disabled={index !== 0}>
                                                <Link to={index === 0 ? "/register" : "#"}>
                                                    {index === 0 ? "Start For Free" : "Coming Soon"}
                                                </Link>
                                            </Button>
                                        </div>
                                    </CardContent>
                                </Card>
                            ))}
                        </div>
                    </div>
                </section>

                {/* Feature Comparison Table */}
                <section className="py-20 bg-secondary">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold text-center mb-12">Feature Comparison</h2>
                        <Table>
                            <TableHeader>
                                <TableRow>
                                    <TableHead>Feature</TableHead>
                                    <TableHead>Free</TableHead>
                                    <TableHead>Premium</TableHead>
                                    <TableHead>Enterprise</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Job Postings</TableCell>
                                    <TableCell>Up to 10</TableCell>
                                    <TableCell>Unlimited</TableCell>
                                    <TableCell>Unlimited</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Candidate Management</TableCell>
                                    <TableCell>Basic</TableCell>
                                    <TableCell>Advanced</TableCell>
                                    <TableCell>Advanced</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Career Page</TableCell>
                                    <TableCell>Standard</TableCell>
                                    <TableCell>Customizable</TableCell>
                                    <TableCell>Fully Customizable</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Analytics</TableCell>
                                    <TableCell>Basic</TableCell>
                                    <TableCell>Advanced</TableCell>
                                    <TableCell>Advanced + Custom</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Support</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Priority</TableCell>
                                    <TableCell>24/7 Phone + Dedicated Manager</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </section>

                {/* FAQ Section */}
                <section className="py-20">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold text-center mb-12">Frequently Asked Questions</h2>
                        <Accordion type="single" collapsible className="w-full max-w-3xl mx-auto">
                            {faqs.map((faq, index) => (
                                <AccordionItem key={index} value={`item-${index}`}>
                                    <AccordionTrigger>{faq.question}</AccordionTrigger>
                                    <AccordionContent>{faq.answer}</AccordionContent>
                                </AccordionItem>
                            ))}
                        </Accordion>
                    </div>
                </section>

                {/* Coming Soon Features */}
                <section className="py-20 bg-muted">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold text-center mb-12">Coming Soon</h2>
                        <div className="grid md:grid-cols-3 gap-8">
                            <Card>
                                <CardHeader>
                                    <CardTitle>AI-Powered Matching</CardTitle>
                                </CardHeader>
                                <CardContent>
                                    Advanced AI algorithms to match candidates with job requirements more accurately.
                                </CardContent>
                            </Card>
                            <Card>
                                <CardHeader>
                                    <CardTitle>Video Interviews</CardTitle>
                                </CardHeader>
                                <CardContent>
                                    Integrated video interview platform for seamless remote hiring processes.
                                </CardContent>
                            </Card>
                            <Card>
                                <CardHeader>
                                    <CardTitle>Advanced Reporting</CardTitle>
                                </CardHeader>
                                <CardContent>
                                    Comprehensive reporting tools for deeper insights into your recruitment metrics.
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                </section>

                {/* Custom Plans Section */}
                <section className="py-20">
                    <div className="container mx-auto px-4 text-center">
                        <h2 className="text-3xl font-bold mb-4">Need a Custom Plan?</h2>
                        <p className="text-xl mb-8">We offer tailored solutions for large enterprises and organizations with specific needs.</p>
                        <Button asChild size="lg">
                            <Link to="/contact">
                                Contact Sales <ArrowRight className="ml-2 h-4 w-4" />
                            </Link>
                        </Button>
                    </div>
                </section>

                {/* CTA Section */}
                <section className="bg-secondary text-secondary-foreground py-20 text-center">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold mb-4">Ready to Streamline Your Hiring?</h2>
                        <p className="text-xl mb-8">Join PrimeApplicants today and experience the difference in your recruitment process.</p>
                        <Button size="lg">
                            <Link to="/register">
                                Start Your Free Trial Now
                            </Link>
                        </Button>
                    </div>
                </section>
            </div>
        </AnimatedPage>
    );
};

export default PricingPage;