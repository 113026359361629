import React from 'react';
import { HelpCircle, Search } from 'lucide-react';
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label"
import { Switch } from "@/components/ui/switch"
import { Input } from '@/components/ui/input';
import { Card, CardContent } from "@/components/ui/card";
import { JOB_BOARDS } from '../../../constants/appConstants';

// Nuevo componente para la tarjeta de integración individual
const IntegrationCard = ({ boardKey, boardName, integration, onChange }) => (
    <Card className="h-full">
        <CardContent className="p-4">
            <h3 className="text-lg font-semibold mb-2">{boardName} Integration</h3>
            <div className="flex items-center space-x-2">
                <Switch
                    id={`${boardKey.toLowerCase()}-integration`}
                    checked={integration?.enabled || false}
                    onCheckedChange={(checked) => onChange(boardKey.toLowerCase(), 'enabled', checked)}
                />
                <Label htmlFor={`${boardKey.toLowerCase()}-integration`}>Enable</Label>
            </div>
        </CardContent>
    </Card>
);

const IntegrationSettings = ({ integrations, onChange }) => {
    const handleChange = (integration, key, value) => {
        onChange(integration, {
            ...integrations[integration],
            [key]: value
        });
    };

    const jobBoardEntries = Object.entries(JOB_BOARDS);
    const linkedInEntry = jobBoardEntries.find(([key]) => key === 'LINKEDIN');
    const otherEntries = jobBoardEntries.filter(([key]) => key !== 'LINKEDIN');

    return (
        <div className="space-y-6">
            {/* LinkedIn Integration */}
            {linkedInEntry && (
                <Card>
                    <CardContent className="p-6">
                        <h3 className="text-xl font-semibold mb-4">{linkedInEntry[1]} Integration</h3>
                        <div className="flex items-center space-x-2">
                            <Switch
                                id="linkedin-integration"
                                checked={integrations['linkedin']?.enabled || false}
                                onCheckedChange={(checked) => handleChange('linkedin', 'enabled', checked)}
                            />
                            <Label htmlFor="linkedin-integration">Enable LinkedIn Integration</Label>
                        </div>
                        {integrations['linkedin']?.enabled && (
                            <div className="space-y-4 mt-4">
                                <div className="space-y-2">
                                    <Label htmlFor="company-id">Company ID in LinkedIn</Label>
                                    <Input
                                        id="company-id"
                                        value={integrations['linkedin'].companyId || ''}
                                        onChange={(e) => handleChange('linkedin', 'companyId', e.target.value)}
                                        placeholder="E.g. 1234567"
                                    />
                                </div>
                                <Button variant="link" className="p-0 h-auto" asChild>
                                    <a
                                        href="https://www.linkedin.com/help/linkedin/answer/a415420/associate-your-linkedin-company-id-with-the-linkedin-job-board-faqs"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="flex items-center text-primary hover:underline"
                                    >
                                        <HelpCircle className="h-5 w-5 mr-1" />
                                        Need help finding your Company ID?
                                    </a>
                                </Button>
                            </div>
                        )}
                    </CardContent>
                </Card>
            )}

            {/* Other Integrations */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {otherEntries.map(([key, value]) => (
                    <IntegrationCard
                        key={key}
                        boardKey={key}
                        boardName={value}
                        integration={integrations[key.toLowerCase()]}
                        onChange={handleChange}
                    />
                ))}
            </div>

            {/* Google for Jobs Integration */}
            <Card>
                <CardContent className="p-6">
                    <div className="flex items-center space-x-2 mb-4">
                        <Search className="h-6 w-6 text-primary" />
                        <h3 className="text-xl font-semibold">Google for Jobs Integration</h3>
                    </div>
                    <p className="text-muted-foreground mb-4">
                        Your job listings are automatically optimized for Google for Jobs. No additional configuration is required.
                    </p>
                    <ul className="list-disc list-inside space-y-2">
                        <li>Job postings are automatically formatted with structured data</li>
                        <li>Increased visibility in Google search results</li>
                        <li>Improved candidate reach and application rates</li>
                    </ul>
                    <Button variant="link" className="p-0 h-auto mt-4" asChild>
                        <a
                            href="https://jobs.google.com/about/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex items-center text-primary hover:underline"
                        >
                            <HelpCircle className="h-5 w-5 mr-1" />
                            Learn more about Google for Jobs
                        </a>
                    </Button>
                </CardContent>
            </Card>
        </div>
    );
};

export default IntegrationSettings;