import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const SessionManager = () => {
    const { user, checkSessionExpiration, updateUserActivity, logOut } = useAuth();
    const location = useLocation();

    useEffect(() => {
        const checkAndUpdateActivity = async () => {
            if (user) {
                const sessionValid = await checkSessionExpiration();
                if (sessionValid) {
                    await updateUserActivity();
                } else {
                    logOut(user.id);
                }
            }
        };
        checkAndUpdateActivity();
    }, [location, user, checkSessionExpiration, updateUserActivity, logOut]);

    return null;
};

export default SessionManager;