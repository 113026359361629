import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getActiveJobsByUser } from '../../../services/jobService';
import ErrorAlert from '../../common/ErrorAlert';
import { handleError } from '../../../utils/errorHandler';
import { AnimatedPage } from '../../../utils/animations';
import { useTheme } from 'next-themes';
import { Sun, Moon } from 'lucide-react';

import { Input } from "@/components/ui/input";
import { Card, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";

const CareerPage = () => {
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { slug } = useParams();
    const navigate = useNavigate();
    const { theme, setTheme } = useTheme();

    const toggleTheme = () => {
        setTheme(theme === 'dark' ? 'light' : 'dark');
    };

    const [page, setPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const fetchJobs = async () => {
            try {
                const jobsData = await getActiveJobsByUser(slug);
                const sortedJobs = jobsData.sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate());
                setJobs(sortedJobs);
                setLoading(false);
            } catch (err) {
                const { message } = handleError(err);
                setError(message);
                setLoading(false);
            }
        };
        fetchJobs();
    }, [slug]);

    const jobsPerPage = 9;
    const totalPages = Math.ceil(jobs.length / jobsPerPage);

    const items = React.useMemo(() => {
        const start = (page - 1) * jobsPerPage;
        const end = start + jobsPerPage;

        return jobs
            .filter(job => 
                job.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                job.company.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .slice(start, end);
    }, [page, jobs, searchTerm, jobsPerPage]);

    const handleNextPage = () => {
        setPage(prevPage => Math.min(prevPage + 1, totalPages));
    };

    const handlePrevPage = () => {
        setPage(prevPage => Math.max(prevPage - 1, 1));
    };

    if (loading) return <div className="text-center mt-8">Loading...</div>;
    if (error) return <ErrorAlert message={error} />;

    return (
        <AnimatedPage>
            <div className="max-w-4xl mx-auto relative">
                <Button
                    onClick={toggleTheme}
                    variant="outline"
                    size="icon"
                    className="absolute top-0 right-0 p-2 rounded-full bg-gray-200 dark:bg-gray-700 transition-colors duration-200"
                >
                    {theme === 'dark' ? (
                        <Sun className="h-5 w-5 text-yellow-400" />
                    ) : (
                        <Moon className="h-5 w-5 text-gray-700" />
                    )}
                </Button>
                <h2 className="text-2xl font-bold mb-6">Open Positions</h2>
                <div className="mb-6">
                    <Input
                        type="text"
                        placeholder="Search jobs..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="mb-4"
                    />
                </div>
                {items.length === 0 ? (
                    <Card>
                        <CardContent className="py-4">
                            <p className="text-center text-gray-600 dark:text-gray-300">No open positions at the moment. Please check back later!</p>
                        </CardContent>
                    </Card>
                ) : (
                    <>
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                            {items.map(job => (
                                <Card key={job.id} className="dark:bg-gray-800 flex flex-col h-full">
                                    <CardContent className="flex flex-col h-full py-4">
                                        <div className="flex-grow">
                                            <h3 className="text-xl font-semibold mb-2 dark:text-white">{job.title}</h3>
                                            <p className="text-gray-600 dark:text-gray-300 mb-4">{job.country}</p>
                                        </div>
                                        <Button
                                            onClick={() => navigate(`/careers/${slug}/job/${job.id}`)}
                                            className="mt-auto w-full"
                                            variant="default"
                                        >
                                            View Details
                                        </Button>
                                    </CardContent>
                                </Card>
                            ))}
                        </div>
                        <div className="flex justify-between items-center mt-6">
                            <Button 
                                onClick={handlePrevPage} 
                                disabled={page === 1}
                                variant="outline"
                            >
                                Previous
                            </Button>
                            <span>Page {page} of {totalPages}</span>
                            <Button 
                                onClick={handleNextPage} 
                                disabled={page === totalPages}
                                variant="outline"
                            >
                                Next
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </AnimatedPage>
    );
};

export default CareerPage;