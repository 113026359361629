// src/services/twoFactorService.js

import { authenticator } from 'otplib';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../config/firebase.config';

export const generateSecret = () => {
    return authenticator.generateSecret();
};

export const verifyToken = (token, secret) => {
    return authenticator.verify({ token, secret });
};

export const verifyAndEnableTwoFactor = async (userId, token, secret) => {
    if (verifyToken(token, secret)) {
        await updateDoc(doc(db, 'users', userId), {
            twoFactorEnabled: true,
            twoFactorSecret: secret
        });
        return true;
    }
    return false;
};

export const disableTwoFactor = async (userId) => {
    await updateDoc(doc(db, 'users', userId), {
        twoFactorEnabled: false,
        twoFactorSecret: null
    });
};