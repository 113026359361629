import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const workerUrl = process.env.REACT_APP_WORKER_URL;

let firebaseApp;
let auth;
let db;
let storage;

export const initializeFirebase = async () => {
    try {
        const response = await fetch(`${workerUrl}/firebase-config`);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const firebaseConfig = await response.json();

        firebaseApp = initializeApp(firebaseConfig);
        auth = getAuth(firebaseApp);
        db = getFirestore(firebaseApp);
        storage = getStorage(firebaseApp);
    } catch (error) {
        console.error('Error initializing Firebase:', error);
    }
};

export const getFirebaseAuth = () => {
    if (!auth) {
        throw new Error('Firebase Auth has not been initialized. Call initializeFirebase first.');
    }
    return auth;
};

export const getFirebaseDb = () => {
    if (!db) {
        throw new Error('Firebase Firestore has not been initialized. Call initializeFirebase first.');
    }
    return db;
};

export const getFirebaseStorage = () => {
    if (!storage) {
        throw new Error('Firebase Storage has not been initialized. Call initializeFirebase first.');
    }
    return storage;
};

// Exportar directamente auth, db y storage
export { auth, db, storage };