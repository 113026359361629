import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { withAdminAuth } from '../../../contexts/adminAuthContext';
import { getAllJobs, deleteJob, deleteManyJobs, getJobCandidateCount } from '../../../services/adminService';
import { handleError } from '../../../utils/errorHandler';
import { AnimatedPage } from '../../../utils/animations';
import { JOB_STATUS } from '../../../constants/appConstants';
import { PAGINATION } from '../../../constants/appConstants';
import ErrorAlert from '../../common/ErrorAlert';

import { Table, TableHeader, TableBody, TableHead, TableRow, TableCell } from "@/components/ui/table";
import { Input } from "@/components/ui/input";
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "@/components/ui/dialog";
import { Badge } from "@/components/ui/badge";
import { Skeleton } from "@/components/ui/skeleton";
import { Checkbox } from "@/components/ui/checkbox";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";

import { Trash2, Search, ChevronLeft, ChevronRight, Eye, Loader2 } from 'lucide-react';

const { ITEMS_PER_PAGE } = PAGINATION;

const AdminJobManagement = () => {
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [individualDeleteModalOpen, setIndividualDeleteModalOpen] = useState(false);
    const [bulkDeleteModalOpen, setBulkDeleteModalOpen] = useState(false);
    const [jobToDelete, setJobToDelete] = useState(null);
    const [selectedJobs, setSelectedJobs] = useState(new Set([]));
    const [selectAll, setSelectAll] = useState(false);
    const navigate = useNavigate();

    const [filters, setFilters] = useState({
        status: ''
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(ITEMS_PER_PAGE);
    const [isDeleting, setIsDeleting] = useState(false);

    useEffect(() => {
        fetchJobs();
    }, []);

    const fetchJobs = async () => {
        try {
            const jobsData = await getAllJobs();
            const jobsWithCandidateCounts = await Promise.all(
                jobsData.map(async (job) => {
                    const candidateCount = await getJobCandidateCount(job.id);
                    return { ...job, candidateCount };
                })
            );
            // Ordenar los trabajos por fecha de creación (más reciente primero)
            const sortedJobs = jobsWithCandidateCounts.sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate());
            setJobs(sortedJobs);
            setLoading(false);
        } catch (err) {
            const { message } = handleError(err);
            setError(message);
            setLoading(false);
        }
    };

    const filteredJobs = React.useMemo(() => {
        return jobs.filter(job => {
            const matchesSearch = job.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                job.company.toLowerCase().includes(searchTerm.toLowerCase());
            const matchesStatus = filters.status === '' || filters.status === 'all' || job.status === filters.status;
            return matchesSearch && matchesStatus;
        });
    }, [jobs, searchTerm, filters]);

    const pages = Math.ceil(filteredJobs.length / rowsPerPage);

    const items = React.useMemo(() => {
        const start = (page - 1) * rowsPerPage;
        const end = start + rowsPerPage;
        return filteredJobs.slice(start, end);
    }, [page, filteredJobs, rowsPerPage]);

    const handleFilterChange = (name, value) => {
        setFilters(prevFilters => ({ ...prevFilters, [name]: value }));
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleIndividualDeleteClick = (job) => {
        setJobToDelete(job);
        setIndividualDeleteModalOpen(true);
    };

    const handleBulkDeleteClick = () => {
        setBulkDeleteModalOpen(true);
    };

    const handleIndividualDelete = async () => {
        setIsDeleting(true);
        try {
            await deleteJob(jobToDelete.id);
            fetchJobs();
            setIndividualDeleteModalOpen(false);
            setJobToDelete(null);
        } catch (err) {
            const { message } = handleError(err);
            setError(message);
        } finally {
            setIsDeleting(false);
        }
    };

    const handleBulkDelete = async () => {
        setIsDeleting(true);
        try {
            await deleteManyJobs(Array.from(selectedJobs));
            fetchJobs();
            setBulkDeleteModalOpen(false);
            setSelectedJobs(new Set([]));
        } catch (err) {
            const { message } = handleError(err);
            setError(message);
        } finally {
            setIsDeleting(false);
        }
    };

    const handleSelectAll = (checked) => {
        setSelectAll(checked);
        if (checked) {
            const allJobIds = items.map(job => job.id);
            setSelectedJobs(new Set(allJobIds));
        } else {
            setSelectedJobs(new Set([]));
        }
    };

    const columns = [
        { name: "", id: "select" },
        { name: "TITLE", id: "title" },
        { name: "COMPANY", id: "company" },
        { name: "LOCATION", id: "location" },
        { name: "PUBLISHED DATE", id: "publishedDate" },
        { name: "STATUS", id: "status" },
        { name: "CANDIDATES", id: "candidateCount" },
        { name: "ACTIONS", id: "actions" }
    ];

    const renderCell = (job, columnId) => {
        switch (columnId) {
            case 'select':
                return (
                    <Checkbox
                        checked={selectedJobs.has(job.id)}
                        onCheckedChange={(checked) => {
                            const newSelected = new Set(selectedJobs);
                            if (checked) {
                                newSelected.add(job.id);
                            } else {
                                newSelected.delete(job.id);
                            }
                            setSelectedJobs(newSelected);
                        }}
                    />
                );
            case 'title':
                return job.title;
            case 'company':
                return job.company;
            case 'location':
                return `${job.city}, ${job.country}`;
            case 'publishedDate':
                return new Date(job.createdAt.toDate()).toLocaleDateString();
            case 'status':
                return <Badge variant={job.status === 'Active' ? "success" : "secondary"}>{job.status}</Badge>;
            case 'candidateCount':
                return job.candidateCount || 0;
            case 'actions':
                return (
                    <div className="flex items-center justify-end space-x-2">
                        <TooltipProvider>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <Button variant="outline" size="icon" onClick={() => navigate(`/admin/jobs/${job.id}`)}>
                                        <Eye className="h-4 w-4" />
                                    </Button>
                                </TooltipTrigger>
                                <TooltipContent>View Job Details</TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                        <TooltipProvider>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <Button variant="outline" size="icon" onClick={() => handleIndividualDeleteClick(job)}>
                                        <Trash2 className="h-4 w-4" />
                                    </Button>
                                </TooltipTrigger>
                                <TooltipContent>Delete Job</TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                    </div>
                );
            default:
                return job[columnId];
        }
    };

    if (loading) {
        return (
            <AnimatedPage>
                <div className="space-y-4">
                    <Skeleton className="h-8 w-[200px]" />
                    <Skeleton className="h-10 w-full" />
                    <Table>
                        <TableHeader>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableHead key={column.id}>
                                        <Skeleton className="h-4 w-full" />
                                    </TableHead>
                                ))}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {[...Array(10)].map((_, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        <Skeleton className="h-5 w-5" />
                                    </TableCell>
                                    {columns.map((column) => (
                                        <TableCell key={column.id}>
                                            {column.id === 'actions' ? (
                                                <div className="flex items-center justify-center gap-2">
                                                    <Skeleton className="h-8 w-8" />
                                                    <Skeleton className="h-8 w-8" />
                                                </div>
                                            ) : (
                                                <Skeleton className="h-4 w-full" />
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            </AnimatedPage>
        );
    }

    if (error) return <ErrorAlert message={error} />;

    return (
        <AnimatedPage>
            <div className="space-y-4">
                <div className="flex justify-between items-center mb-6">
                    <h1 className="text-3xl font-bold">Job Management</h1>
                </div>

                <Card>
                    <CardContent className="p-6">
                        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
                            <div className="relative flex-grow">
                                <Input
                                    type="text"
                                    placeholder="Search jobs..."
                                    value={searchTerm}
                                    onChange={handleSearch}
                                    className="pl-10"
                                />
                                <Search className="h-5 w-5 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                            </div>
                            <Select
                                value={filters.status}
                                onValueChange={(value) => handleFilterChange('status', value)}
                            >
                                <SelectTrigger className="w-full md:w-48">
                                    <SelectValue placeholder="All Statuses" />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectItem value="all">All Statuses</SelectItem>
                                    {Object.values(JOB_STATUS).map(status => (
                                        <SelectItem key={status} value={status}>{status}</SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>
                    </CardContent>
                </Card>

                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead className="w-[50px]">
                                <Checkbox
                                    checked={selectAll}
                                    onCheckedChange={handleSelectAll}
                                />
                            </TableHead>
                            {columns.slice(1).map((column) => (
                                <TableHead key={column.id} className={column.id === 'actions' ? 'text-right' : ''}>
                                    {column.name}
                                </TableHead>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {items.map((job) => (
                            <TableRow key={job.id}>
                                {columns.map((column) => (
                                    <TableCell key={`${job.id}-${column.id}`} className={column.id === 'actions' ? 'text-right' : ''}>
                                        {renderCell(job, column.id)}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                <div className="flex items-center justify-between">
                    <div className="flex-1 text-sm text-muted-foreground">
                        {selectedJobs.size} of {items.length} row(s) selected.
                    </div>
                    <div className="flex items-center space-x-6 lg:space-x-8">
                        <div className="flex items-center space-x-2">
                            <p className="text-sm font-medium">Rows per page</p>
                            <Select
                                value={`${rowsPerPage}`}
                                onValueChange={(value) => setRowsPerPage(Number(value))}
                            >
                                <SelectTrigger className="h-8 w-[70px]">
                                    <SelectValue placeholder={rowsPerPage} />
                                </SelectTrigger>
                                <SelectContent side="top">
                                    {[10, 20, 30, 40, 50].map((pageSize) => (
                                        <SelectItem key={pageSize} value={`${pageSize}`}>
                                            {pageSize}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>
                        <div className="flex w-[100px] items-center justify-center text-sm font-medium">
                            Page {page} of {pages}
                        </div>
                        <div className="flex items-center space-x-2">
                            <Button
                                variant="outline"
                                className="hidden h-8 w-8 p-0 lg:flex"
                                onClick={() => setPage(1)}
                                disabled={page === 1}
                            >
                                <span className="sr-only">Go to first page</span>
                                <ChevronLeft className="h-4 w-4" />
                                <ChevronLeft className="h-4 w-4" />
                            </Button>
                            <Button
                                variant="outline"
                                className="h-8 w-8 p-0"
                                onClick={() => setPage(page - 1)}
                                disabled={page === 1}
                            >
                                <span className="sr-only">Go to previous page</span>
                                <ChevronLeft className="h-4 w-4" />
                            </Button>
                            <Button
                                variant="outline"
                                className="h-8 w-8 p-0"
                                onClick={() => setPage(page + 1)}
                                disabled={page === pages}
                            >
                                <span className="sr-only">Go to next page</span>
                                <ChevronRight className="h-4 w-4" />
                            </Button>
                            <Button
                                variant="outline"
                                className="hidden h-8 w-8 p-0 lg:flex"
                                onClick={() => setPage(pages)}
                                disabled={page === pages}
                            >
                                <span className="sr-only">Go to last page</span>
                                <ChevronRight className="h-4 w-4" />
                                <ChevronRight className="h-4 w-4" />
                            </Button>
                        </div>
                    </div>
                </div>

                {selectedJobs.size > 0 && (
                    <div className="mt-4">
                        <Button variant="destructive" onClick={handleBulkDeleteClick}>
                            Delete Selected ({selectedJobs.size})
                        </Button>
                    </div>
                )}
                <Dialog open={individualDeleteModalOpen} onOpenChange={setIndividualDeleteModalOpen}>
                    <DialogContent>
                        <DialogHeader>
                            <DialogTitle>Delete Job</DialogTitle>
                            <DialogDescription>
                                Are you sure you want to delete {jobToDelete?.title}? This action cannot be undone.
                            </DialogDescription>
                        </DialogHeader>
                        <DialogFooter>
                            <Button variant="outline" onClick={() => setIndividualDeleteModalOpen(false)}>Cancel</Button>
                            <Button variant="destructive" onClick={handleIndividualDelete} disabled={isDeleting}>
                                {isDeleting ? (
                                    <>
                                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                        Deleting...
                                    </>
                                ) : (
                                    'Delete'
                                )}
                            </Button>
                        </DialogFooter>
                    </DialogContent>
                </Dialog>
                <Dialog open={bulkDeleteModalOpen} onOpenChange={setBulkDeleteModalOpen}>
                    <DialogContent>
                        <DialogHeader>
                            <DialogTitle>Delete Jobs</DialogTitle>
                            <DialogDescription>
                                Are you sure you want to delete {selectedJobs.size} jobs? This action cannot be undone.
                            </DialogDescription>
                        </DialogHeader>
                        <DialogFooter>
                            <Button variant="outline" onClick={() => setBulkDeleteModalOpen(false)}>Cancel</Button>
                            <Button variant="destructive" onClick={handleBulkDelete} disabled={isDeleting}>
                                {isDeleting ? (
                                    <>
                                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                        Deleting...
                                    </>
                                ) : (
                                    'Delete'
                                )}
                            </Button>
                        </DialogFooter>
                    </DialogContent>
                </Dialog>
            </div>
        </AnimatedPage>
    );
};

export default withAdminAuth(AdminJobManagement);