import React from 'react';
import { Link } from 'react-router-dom';
import { CalendarDays, Clock, User, ChevronRight, MessageSquare, CheckCircle, AlertTriangle } from 'lucide-react';
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { AnimatedPage } from '../../utils/animations';

export const articleMeta = {
    title: "Recruiting Texting Guide: A Complete Guide for Modern Recruiters",
    description: "Discover how to effectively use texting in your recruitment process to improve candidate engagement and streamline communications.",
    date: "August 12, 2024",
    readTime: "6 min read",
};

const RecruitingTextingGuide = () => {
    const { title, description, date, readTime } = articleMeta;

    const textingBenefits = [
        {
            icon: Clock,
            title: "Quick Response Times",
            description: "95% of texts are read within 3 minutes of being received, making it ideal for time-sensitive communications."
        },
        {
            icon: MessageSquare,
            title: "Higher Engagement Rates",
            description: "Text messages have a 45% response rate on average, significantly higher than email or phone calls."
        },
        {
            icon: CheckCircle,
            title: "Improved Candidate Experience",
            description: "Texting offers a more immediate and convenient way for candidates to interact with recruiters."
        },
        {
            icon: AlertTriangle,
            title: "Compliance Considerations",
            description: "Ensure your texting practices comply with regulations like TCPA to avoid legal issues."
        }
    ];

    const textingStrategies = [
        "Use texting for quick updates and reminders",
        "Personalize messages to each candidate",
        "Keep messages concise and professional",
        "Respect candidates' preferences for communication methods",
        "Use an ATS with integrated texting capabilities for better tracking",
        "Set clear expectations about response times",
        "Use texting to complement, not replace, other communication methods"
    ];

    return (
        <AnimatedPage>
            <article className="max-w-5xl mx-auto px-4 py-12">
                {/* Breadcrumb */}
                <nav className="flex mb-8 text-sm" aria-label="Breadcrumb">
                    <ol className="inline-flex items-center space-x-1 md:space-x-3">
                        <li className="inline-flex items-center">
                            <Link to="/" className="inline-flex items-center text-muted-foreground hover:text-primary">
                                Home
                            </Link>
                        </li>
                        <li>
                            <div className="flex items-center">
                                <ChevronRight className="w-4 h-4 text-muted-foreground" />
                                <Link to="/blog" className="ml-1 text-muted-foreground hover:text-primary md:ml-2">
                                    Blog
                                </Link>
                            </div>
                        </li>
                        <li aria-current="page">
                            <div className="flex items-center">
                                <ChevronRight className="w-4 h-4 text-muted-foreground" />
                                <span className="ml-1 text-primary md:ml-2">Recruiting Texting Guide</span>
                            </div>
                        </li>
                    </ol>
                </nav>

                <header className="mb-12 text-center">
                    <h1 className="text-5xl font-bold mb-6">{title}</h1>
                    <div className="flex items-center justify-center text-muted-foreground mb-6">
                        <User className="mr-2 h-5 w-5" />
                        <span className="mr-4">PrimeApplicants Team</span>
                        <CalendarDays className="mr-2 h-5 w-5" />
                        <span className="mr-4">{date}</span>
                        <Clock className="mr-2 h-5 w-5" />
                        <span>{readTime}</span>
                    </div>
                    <p className="text-xl text-muted-foreground max-w-3xl mx-auto">{description}</p>
                </header>

                <section className="prose max-w-none mb-12">
                    <h2 className="text-3xl font-bold mb-6">The Rise of Recruiting Texting</h2>
                    <p>In today's fast-paced recruitment landscape, effective communication is key to attracting and engaging top talent. As candidates become increasingly mobile-centric, recruiters are turning to texting as a powerful tool in their recruitment arsenal. This guide explores the benefits, best practices, and strategies for incorporating texting into your recruitment process.</p>
                    <p>Recruiting texting has emerged as a game-changer in the talent acquisition field. With over 43% of recruiters now using texting to reach out to candidates or current applicants, it's clear that this communication method is no longer just a trend, but a essential component of modern recruitment strategies.</p>
                </section>

                <section className="mb-16">
                    <h2 className="text-3xl font-bold mb-8">Key Benefits of Recruiting Texting</h2>
                    <div className="grid md:grid-cols-2 gap-8">
                        {textingBenefits.map((benefit, index) => (
                            <Card key={index} className="hover:shadow-lg transition-shadow duration-300">
                                <CardHeader>
                                    <benefit.icon className="h-12 w-12 text-primary mb-4" />
                                    <CardTitle className="text-2xl mb-2">{benefit.title}</CardTitle>
                                </CardHeader>
                                <CardContent>
                                    <p>{benefit.description}</p>
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                </section>

                <section className="prose max-w-none mb-12">
                    <h2 className="text-3xl font-bold mb-6">When to Use Recruiting Texting</h2>
                    <p>While texting can be a powerful tool, it's important to use it appropriately in the recruitment process. Here are some ideal scenarios for recruiting texting:</p>
                    <ul className="list-disc pl-5 space-y-2">
                        <li><strong>Scheduling interviews:</strong> Quickly confirm interview times and locations.</li>
                        <li><strong>Sending reminders:</strong> Notify candidates about upcoming interviews or deadlines.</li>
                        <li><strong>Providing updates:</strong> Keep candidates informed about their application status.</li>
                        <li><strong>Answering quick questions:</strong> Address simple queries that don't require lengthy explanations.</li>
                        <li><strong>Sharing job opportunities:</strong> Notify passive candidates about relevant openings.</li>
                    </ul>
                </section>

                {/* Banner de llamado a la acción adicional */}
                <section className="bg-primary text-primary-foreground py-12 px-8 rounded-lg mb-16">
                    <h2 className="text-3xl font-bold mb-4">Streamline Your Recruiting Texting with PrimeApplicants</h2>
                    <p className="mb-6">Our ATS offers integrated texting capabilities to help you manage candidate communications efficiently. Start your free trial today and experience the difference.</p>
                    <Button asChild size="lg" variant="secondary">
                        <Link to="/register">Start Free Trial</Link>
                    </Button>
                </section>

                <section className="prose max-w-none mb-12">
                    <h2 className="text-3xl font-bold mb-6">Best Practices for Recruiting Texting</h2>
                    <p>To make the most of recruiting texting, consider these strategies:</p>
                    <ul className="list-disc pl-5 space-y-2">
                        {textingStrategies.map((strategy, index) => (
                            <li key={index}>{strategy}</li>
                        ))}
                    </ul>
                </section>

                <section className="prose max-w-none mb-12">
                    <h2 className="text-3xl font-bold mb-6">The Future of Recruiting Texting</h2>
                    <p>As technology continues to evolve, we can expect recruiting texting to become even more sophisticated. Some trends to watch for include:</p>
                    <ul className="list-disc pl-5 space-y-2">
                        <li>AI-powered chatbots for initial candidate screening</li>
                        <li>Enhanced analytics to track texting effectiveness</li>
                        <li>Integration with other communication channels for a seamless candidate experience</li>
                        <li>Increased use of rich media in text communications</li>
                    </ul>
                    <p>By staying ahead of these trends and implementing best practices, recruiters can leverage texting to create a more efficient and candidate-friendly recruitment process.</p>
                </section>

                <section className="prose max-w-none mb-12">
                    <h2 className="text-3xl font-bold mb-6">Conclusion</h2>
                    <p>Recruiting texting has become an indispensable tool for modern recruiters, offering a quick, efficient, and candidate-friendly way to communicate throughout the hiring process. By understanding its benefits, following best practices, and using it strategically, recruiters can significantly enhance their recruitment efforts and improve candidate engagement.</p>
                    <p>Remember, while texting is powerful, it should be part of a comprehensive communication strategy that includes other methods like email and phone calls. The key is to use each channel appropriately to create a positive and effective candidate experience.</p>
                </section>
            </article>
            {/* Llamado a la acción estándar */}
            <section className="bg-secondary text-secondary-foreground py-20 text-center">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold mb-4">Ready to Revolutionize Your Recruitment Communications?</h2>
                    <p className="text-xl mb-8">Try PrimeApplicants and experience how our integrated texting features can streamline your recruitment process.</p>
                    <Button size="lg" asChild>
                        <Link to="/register">Start Your Free Trial Today</Link>
                    </Button>
                </div>
            </section>
        </AnimatedPage>
    );
};

export default RecruitingTextingGuide;