import React from 'react';
import { Button } from "@/components/ui/button";
import { Check } from 'lucide-react';

const BillingSettings = () => {
    return (
        <div>
            <h3 className="text-xl font-semibold mb-4">Billing</h3>
            <div className="bg-primary-100 border border-primary-200 rounded-lg p-4 mb-6">
                <h3 className="text-lg font-semibold text-primary-700 mb-2">Current Plan: Free Plan</h3>
                <p className="text-primary-600">You are currently on the Free Plan. Enjoy our basic features at no cost!</p>
            </div>
            <div className="mb-6">
                <h4 className="text-lg font-semibold mb-2">Plan Features:</h4>
                <ul className="space-y-2">
                    <li className="flex items-center">
                        <Check className="h-5 w-5 text-green-500 mr-2" />
                        Up to 10 job postings
                    </li>
                    <li className="flex items-center">
                        <Check className="h-5 w-5 text-green-500 mr-2" />
                        Basic candidate management
                    </li>
                    <li className="flex items-center">
                        <Check className="h-5 w-5 text-green-500 mr-2" />
                        Standard career page
                    </li>
                    <li className="flex items-center">
                        <Check className="h-5 w-5 text-green-500 mr-2" />
                        Email support
                    </li>
                </ul>
            </div>
            <div>
                <Button variant="default" disabled>
                    Upgrade Plan (Coming Soon)
                </Button>
            </div>
        </div>
    );
};

export default BillingSettings;