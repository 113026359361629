import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import { handleError } from '../../../utils/errorHandler';
import { AnimatedPage } from '../../../utils/animations';
import { getCandidatesByStage, deleteCandidate, updateCandidate, deleteManyCandidates } from '../../../services/candidateService';
import { ArrowLeft, Edit, Trash2, Search, ChevronLeft, ChevronRight, Loader2 } from 'lucide-react';
import { useToast } from "@/components/ui/use-toast";

import { Card, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import ErrorAlert from '../../common/ErrorAlert';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Input } from "@/components/ui/input";
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "@/components/ui/select";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { Checkbox } from "@/components/ui/checkbox";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "@/components/ui/dialog";
import { RECRUITMENT_STAGES, PAGINATION } from '../../../constants/appConstants';

const { ITEMS_PER_PAGE } = PAGINATION;

const StageDetails = () => {
    const [candidates, setCandidates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { user } = useAuth();
    const { stage } = useParams();
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(ITEMS_PER_PAGE);
    const [selectedCandidates, setSelectedCandidates] = useState(new Set([]));
    const [individualDeleteModalOpen, setIndividualDeleteModalOpen] = useState(false);
    const [bulkDeleteModalOpen, setBulkDeleteModalOpen] = useState(false);
    const [candidateToDelete, setCandidateToDelete] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const { toast } = useToast();

    useEffect(() => {
        if (user && stage) {
            fetchCandidates();
        }
    }, [user, stage]);

    const fetchCandidates = async () => {
        if (!user) {
            setLoading(false);
            return;
        }
        try {
            setLoading(true);
            const candidatesData = await getCandidatesByStage(user.id, stage);
            setCandidates(candidatesData);
            setLoading(false);
        } catch (err) {
            const { message } = handleError(err);
            setError(message);
            setLoading(false);
        }
    };

    const filteredCandidates = candidates.filter(candidate =>
        candidate.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        candidate.email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const paginatedCandidates = filteredCandidates.slice(
        (page - 1) * rowsPerPage,
        page * rowsPerPage
    );

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        setPage(1);
    };

    const handleIndividualDeleteClick = (candidate) => {
        setCandidateToDelete(candidate);
        setIndividualDeleteModalOpen(true);
    };

    const handleBulkDeleteClick = () => {
        setBulkDeleteModalOpen(true);
    };

    const handleIndividualDelete = async () => {
        setIsDeleting(true);
        try {
            await deleteCandidate(candidateToDelete.id);
            fetchCandidates();
            setIndividualDeleteModalOpen(false);
            setCandidateToDelete(null);
        } catch (err) {
            const { message } = handleError(err);
            setError(message);
        } finally {
            setIsDeleting(false);
        }
    };

    const handleBulkDelete = async () => {
        setIsDeleting(true);
        try {
            await deleteManyCandidates(Array.from(selectedCandidates));
            fetchCandidates();
            setBulkDeleteModalOpen(false);
            setSelectedCandidates(new Set([]));
        } catch (err) {
            const { message } = handleError(err);
            setError(message);
        } finally {
            setIsDeleting(false);
        }
    };

    const handleStageChange = async (candidateId, newStage) => {
        try {
            await updateCandidate(candidateId, { stage: newStage });
            await fetchCandidates();
            
            // Encontrar el candidato actualizado
            const updatedCandidate = candidates.find(c => c.id === candidateId);
            
            toast({
                title: "Stage updated",
                description: `${updatedCandidate.name}'s stage has been updated to ${newStage}.`,
            });
        } catch (err) {
            const { message } = handleError(err);
            setError(message);
            toast({
                title: "Error updating stage",
                description: "There was an error updating the candidate's stage. Please try again.",
                variant: "destructive",
            });
        }
    };

    const columns = [
        { name: "NAME", id: "name", align: "left" },
        { name: "EMAIL", id: "email", align: "left" },
        { name: "APPLIED DATE", id: "appliedDate", align: "left" },
        { name: "STAGE", id: "stage", align: "left" },
        { name: "ACTIONS", id: "actions", align: "center" }
    ];

    const renderCell = (candidate, columnKey) => {
        switch (columnKey) {
            case "name":
                return (
                    <div className="flex items-center space-x-4" onClick={() => navigate(`/candidates/${candidate.id}`)} style={{ cursor: 'pointer' }}>
                        <Avatar>
                            <AvatarImage src={candidate.avatar} alt={candidate.name} />
                            <AvatarFallback>{candidate.name.charAt(0)}</AvatarFallback>
                        </Avatar>
                        <div>
                            <p className="text-sm font-medium">{candidate.name}</p>
                            <p className="text-sm text-muted-foreground">{candidate.email}</p>
                        </div>
                    </div>
                );
            case "email":
                return candidate.email;
            case "appliedDate":
                return new Date(candidate.appliedAt.seconds * 1000).toLocaleDateString();
            case "stage":
                return (
                    <Select
                        value={candidate.stage}
                        onValueChange={(value) => handleStageChange(candidate.id, value)}
                    >
                        <SelectTrigger className="w-[180px]">
                            <SelectValue placeholder="Select stage" />
                        </SelectTrigger>
                        <SelectContent>
                            {Object.values(RECRUITMENT_STAGES).map((stage) => (
                                <SelectItem key={stage} value={stage}>{stage}</SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                );
            case "actions":
                return (
                    <div className="flex items-center justify-center space-x-2">
                        <TooltipProvider>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <Button variant="outline" size="icon" onClick={() => navigate(`/candidates/edit/${candidate.id}`)}>
                                        <Edit className="h-4 w-4" />
                                    </Button>
                                </TooltipTrigger>
                                <TooltipContent>
                                    <p>Edit {candidate.name}</p>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                        <TooltipProvider>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <Button variant="outline" size="icon" onClick={() => handleIndividualDeleteClick(candidate)}>
                                        <Trash2 className="h-4 w-4" />
                                    </Button>
                                </TooltipTrigger>
                                <TooltipContent>
                                    <p>Delete {candidate.name}</p>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                    </div>
                );
            default:
                return candidate[columnKey];
        }
    };

    if (loading && candidates.length === 0) {
        return (
            <AnimatedPage>
                <div className="space-y-6">
                    <div className="flex justify-between items-center mb-6">
                        <Skeleton className="h-10 w-32" />
                        <Skeleton className="h-10 w-48" />
                    </div>
                    <Card>
                        <CardContent>
                            <Table>
                                <TableHeader>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableHead key={column.id}><Skeleton className="h-4 w-full" /></TableHead>
                                        ))}
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {[...Array(5)].map((_, index) => (
                                        <TableRow key={index}>
                                            {columns.map((column) => (
                                                <TableCell key={column.id}><Skeleton className="h-4 w-full" /></TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>
                </div>
            </AnimatedPage>
        );
    }

    if (error) return <ErrorAlert message={error} />;

    return (
        <AnimatedPage>
            <div className="space-y-6">
                <div className="flex justify-between items-center mb-6">
                    <Button
                        onClick={() => navigate('/recruitment')}
                        variant="outline"
                        className="flex items-center"
                    >
                        <ArrowLeft className="h-5 w-5 mr-2" />
                        Back to Board
                    </Button>
                    <h1 className="text-3xl font-bold text-primary">Stage: {stage}</h1>
                </div>
                <Card>
                    <CardContent className="p-6">
                        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
                            <div className="relative flex-grow">
                                <Input
                                    type="text"
                                    placeholder="Search candidates..."
                                    value={searchTerm}
                                    onChange={handleSearch}
                                    className="pl-10"
                                />
                                <Search className="h-5 w-5 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                            </div>
                        </div>
                    </CardContent>
                </Card>
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead className="w-[50px]">
                                <Checkbox
                                    checked={selectedCandidates.size === filteredCandidates.length}
                                    onCheckedChange={(checked) => {
                                        if (checked) {
                                            setSelectedCandidates(new Set(filteredCandidates.map(c => c.id)));
                                        } else {
                                            setSelectedCandidates(new Set());
                                        }
                                    }}
                                />
                            </TableHead>
                            {columns.map((column) => (
                                <TableHead key={column.id} className={`text-${column.align}`}>
                                    {column.name}
                                </TableHead>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {paginatedCandidates.map((item) => (
                            <TableRow key={item.id}>
                                <TableCell>
                                    <Checkbox
                                        checked={selectedCandidates.has(item.id)}
                                        onCheckedChange={(checked) => {
                                            if (checked) {
                                                setSelectedCandidates(new Set([...selectedCandidates, item.id]));
                                            } else {
                                                const newSelected = new Set(selectedCandidates);
                                                newSelected.delete(item.id);
                                                setSelectedCandidates(newSelected);
                                            }
                                        }}
                                    />
                                </TableCell>
                                {columns.map((column) => (
                                    <TableCell key={`${item.id}-${column.id}`}>
                                        {renderCell(item, column.id)}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {selectedCandidates.size > 0 && (
                    <Button variant="destructive" onClick={handleBulkDeleteClick}>
                        Delete Selected ({selectedCandidates.size})
                    </Button>
                )}
                <div className="flex items-center justify-between">
                    <div className="flex-1 text-sm text-muted-foreground">
                        {selectedCandidates.size} of {filteredCandidates.length} selected row(s).
                    </div>
                    <div className="flex items-center space-x-6 lg:space-x-8">
                        <div className="flex items-center space-x-2">
                            <p className="text-sm font-medium">Rows per page</p>
                            <Select
                                value={`${rowsPerPage}`}
                                onValueChange={(value) => {
                                    setRowsPerPage(Number(value));
                                    setPage(1);
                                }}
                            >
                                <SelectTrigger className="h-8 w-[70px]">
                                    <SelectValue placeholder={rowsPerPage} />
                                </SelectTrigger>
                                <SelectContent side="top">
                                    {[10, 20, 30, 40, 50].map((pageSize) => (
                                        <SelectItem key={pageSize} value={`${pageSize}`}>
                                            {pageSize}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>
                        <div className="flex w-[100px] items-center justify-center text-sm font-medium">
                            Page {page} of {Math.ceil(filteredCandidates.length / rowsPerPage)}
                        </div>
                        <div className="flex items-center space-x-2">
                            <Button
                                variant="outline"
                                className="hidden h-8 w-8 p-0 lg:flex"
                                onClick={() => handlePageChange(1)}
                                disabled={page === 1}
                            >
                                <span className="sr-only">Go to first page</span>
                                <ChevronLeft className="h-4 w-4" />
                                <ChevronLeft className="h-4 w-4" />
                            </Button>
                            <Button
                                variant="outline"
                                className="h-8 w-8 p-0"
                                onClick={() => handlePageChange(page - 1)}
                                disabled={page === 1}
                            >
                                <span className="sr-only">Go to previous page</span>
                                <ChevronLeft className="h-4 w-4" />
                            </Button>
                            <Button
                                variant="outline"
                                className="h-8 w-8 p-0"
                                onClick={() => handlePageChange(page + 1)}
                                disabled={page === Math.ceil(filteredCandidates.length / rowsPerPage)}
                            >
                                <span className="sr-only">Go to next page</span>
                                <ChevronRight className="h-4 w-4" />
                            </Button>
                            <Button
                                variant="outline"
                                className="hidden h-8 w-8 p-0 lg:flex"
                                onClick={() => handlePageChange(Math.ceil(filteredCandidates.length / rowsPerPage))}
                                disabled={page === Math.ceil(filteredCandidates.length / rowsPerPage)}
                            >
                                <span className="sr-only">Go to last page</span>
                                <ChevronRight className="h-4 w-4" />
                                <ChevronRight className="h-4 w-4" />
                            </Button>
                        </div>
                    </div>
                </div>
                <Dialog open={individualDeleteModalOpen} onOpenChange={setIndividualDeleteModalOpen}>
                    <DialogContent>
                        <DialogHeader>
                            <DialogTitle>Delete Candidate</DialogTitle>
                            <DialogDescription>
                                Are you sure you want to delete {candidateToDelete?.name}? This action cannot be undone.
                            </DialogDescription>
                        </DialogHeader>
                        <DialogFooter>
                            <Button variant="outline" onClick={() => setIndividualDeleteModalOpen(false)}>Cancel</Button>
                            <Button variant="destructive" onClick={handleIndividualDelete} disabled={isDeleting}>
                                {isDeleting ? (
                                    <>
                                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                        Deleting...
                                    </>
                                ) : 'Delete'}
                            </Button>
                        </DialogFooter>
                    </DialogContent>
                </Dialog>
                <Dialog open={bulkDeleteModalOpen} onOpenChange={setBulkDeleteModalOpen}>
                    <DialogContent>
                        <DialogHeader>
                            <DialogTitle>Delete Candidates</DialogTitle>
                            <DialogDescription>
                                Are you sure you want to delete {selectedCandidates.size} candidates? This action cannot be undone.
                            </DialogDescription>
                        </DialogHeader>
                        <DialogFooter>
                            <Button variant="outline" onClick={() => setBulkDeleteModalOpen(false)}>Cancel</Button>
                            <Button variant="destructive" onClick={handleBulkDelete} disabled={isDeleting}>
                                {isDeleting ? (
                                    <>
                                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                        Deleting...
                                    </>
                                ) : (
                                    'Delete'
                                )}
                            </Button>
                        </DialogFooter>
                    </DialogContent>
                </Dialog>
            </div>
        </AnimatedPage>
    );
};

export default StageDetails;