import React from 'react';
import { Link } from 'react-router-dom';
import {
    Users, ClipboardList, Briefcase, BarChart2,
    Globe, Shield, Settings, User
} from 'lucide-react';
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { AnimatedPage } from '../utils/animations';
const HomePage = () => {
    const features = [
        { icon: Users, title: "Candidate Management", description: "Easily organize and track candidates throughout the entire hiring process." },
        { icon: ClipboardList, title: "Job Posting", description: "Create and publish job offers simply and efficiently." },
        { icon: Briefcase, title: "Recruitment Dashboard", description: "Visualize and manage the entire hiring process in one place." },
        { icon: BarChart2, title: "Basic Analytics", description: "Gain insights into your hiring process with simple analytics." },
        { icon: Globe, title: "Custom Career Page", description: "Create a unique career page for your company and showcase your job offers." },
        { icon: Shield, title: "Data Security", description: "Keep your candidates' information secure with our data protection measures." },
        { icon: Settings, title: "Custom Configuration", description: "Adapt the platform to your specific hiring needs." },
        { icon: User, title: "User Profiles", description: "Manage access and roles for your recruitment team." }
    ];

    return (
        <AnimatedPage>
            <div className="space-y-0">
                {/* Hero Section */}
                <section className="bg-gradient-to-tl from-primary to-muted-foreground text-primary-foreground py-20">
                    <div className="container mx-auto px-4 text-center">
                        <h1 className="text-5xl font-bold mb-4">Simplify Your Hiring Process with PrimeApplicants</h1>
                        <p className="text-xl mb-8 max-w-2xl mx-auto">An intuitive platform to manage candidates, post jobs, and optimize your recruitment process.</p>
                        <div className="space-x-4">
                            <Button>
                                <Link to="/register">Start Free Trial</Link>
                            </Button>
                            <Button variant="secondary">
                                <Link to="/services">Explore Our Services</Link>
                            </Button>
                        </div>
                    </div>
                </section>

                {/* Features Section */}
                <section className="py-20">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold text-center mb-12">Why Choose PrimeApplicants?</h2>
                        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
                            {features.map((feature, index) => (
                                <Card key={index}>
                                    <CardHeader>
                                        <feature.icon className="h-8 w-8 mb-2" />
                                        <CardTitle>{feature.title}</CardTitle>
                                    </CardHeader>
                                    <CardContent>
                                        <p>{feature.description}</p>
                                    </CardContent>
                                </Card>
                            ))}
                        </div>
                    </div>
                </section>

                {/* How It Works Section */}
                <section className="bg-muted py-20">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold text-center mb-12">How PrimeApplicants Works</h2>
                        <div className="flex flex-col md:flex-row justify-between items-center space-y-8 md:space-y-0 md:space-x-8">
                            {["Post Jobs", "Manage Candidates", "Collaborate", "Hire"].map((step, index) => (
                                <div key={index} className="text-center">
                                    <div className="bg-primary text-primary-foreground rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">{index + 1}</div>
                                    <h3 className="text-xl font-semibold mb-2">{step}</h3>
                                    <p>{["Create and publish job offers easily", "Organize and evaluate applicants", "Work as a team to make decisions", "Select and hire the best candidates"][index]}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>

                {/* Key Benefits Section */}
                <section className="py-20">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold text-center mb-12">Key Benefits</h2>
                        <div className="grid md:grid-cols-3 gap-6">
                            {["Time Saving", "Better Organization", "Improved Collaboration"].map((benefit, index) => (
                                <Card key={index}>
                                    <CardHeader>
                                        <CardTitle>{benefit}</CardTitle>
                                    </CardHeader>
                                    <CardContent>
                                        <p>{["Automate repetitive tasks and centralize information to streamline your hiring process.", "Keep all candidate and job data in one place, easily accessible to your team.", "Facilitate communication and team decision-making during the selection process."][index]}</p>
                                    </CardContent>
                                </Card>
                            ))}
                        </div>
                    </div>
                </section>

                {/* CTA Section */}
                <section className="bg-secondary text-secondary-foreground py-20 text-center">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold mb-4">Ready to Improve Your Hiring Process?</h2>
                        <p className="text-xl mb-8">Join the companies already optimizing their recruitment with PrimeApplicants.</p>
                        <Button size="lg">
                            <Link to="/register">Start Your Free Trial Today</Link>
                        </Button>
                    </div>
                </section>
            </div>
        </AnimatedPage>
    );
};

export default HomePage;