import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAdminAuth } from '../../contexts/adminAuthContext';

const AdminPrivateRoute = ({ children }) => {
    const { adminUser, loading } = useAdminAuth();
    const location = useLocation();

    if (loading) {
        return <div className='text-center mt-8'>Loading...</div>;
    }

    if (!adminUser) {
        return <Navigate to="/admin/login" state={{ from: location }} replace />;
    }

    return children;
};

export default AdminPrivateRoute;