import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider } from 'next-themes';
import { AuthProvider } from './contexts/AuthContext';
import Layout from './components/layout/Layout';
import PublicLayout from './components/layout/PublicLayout';
import CareerPageLayout from './components/layout/CareerPageLayout';
import PrivateRoute from './components/common/PrivateRoute';
import GeneralPublicLayout from './components/layout/GeneralPublicLayout';
import { ROUTES } from './constants/appConstants';
import LoadingSpinner from './components/common/LoadingSpinner';
const Dashboard = React.lazy(() => import('./components/features/dashboard/Dashboard'));
const JobList = React.lazy(() => import('./components/features/jobs/JobList'));
const JobForm = React.lazy(() => import('./components/features/jobs/JobForm'));
const JobDetails = React.lazy(() => import('./components/features/jobs/JobDetails'));
const CandidateList = React.lazy(() => import('./components/features/candidates/CandidateList'));
const CandidateForm = React.lazy(() => import('./components/features/candidates/CandidateForm'));
const CandidateDetails = React.lazy(() => import('./components/features/candidates/CandidateDetails'));
const RecruitmentBoard = React.lazy(() => import('./components/features/recruitment/RecruitmentBoard'));
import LoginPage from './components/features/auth/LoginPage';
import RegisterPage from './components/features/auth/RegisterPage';
import CareerPage from './components/features/careerPage/CareerPage';
import PublicJobDetails from './components/features/careerPage/PublicJobDetails';
import UserProfileSettings from './components/features/settings/UserProfileSettings';
import PrivacyPolicy from './pages/PrivacyPolicy';
import HomePage from './pages/HomePage';
import FAQPage from './pages/FAQPage';
import ContactPage from './pages/ContactPage';
import PricingPage from './pages/PricingPage';
import IntegrationsPage from './pages/IntegrationsPage';
import RecruitmentResourcesGuidePage from './pages/RecruitmentResourcesGuidePage';
import BestPracticesPage from './pages/resources/BestPracticesPage';
import InterviewStrategiesPage from './pages/resources/InterviewStrategiesPage';
import TalentAnalyticsPage from './pages/resources/TalentAnalyticsPage';
import EmployerBrandingPage from './pages/resources/EmployerBrandingPage';
import TermsOfService from './pages/TermsOfService';
import ServicesPage from './pages/ServicesPage';
import InterviewList from './components/features/interviews/InterviewList';
const TwoFactorSetup = React.lazy(() => import('./components/features/settings/TwoFactorSetup'));
import TwoFactorVerify from './components/features/auth/TwoFactorVerify';
import VerifyEmailSent from './components/features/auth/VerifyEmailSent';
import VerifyEmail from './components/features/auth/VerifyEmail';
import ConsentBanner from './components/common/ConsentBanner';
import SessionManager from './components/common/SessionManager';

import StageDetails from './components/features/recruitment/StageDetails';

import { AdminAuthProvider } from './contexts/adminAuthContext';
import AdminLogin from './components/admin/auth/AdminLogin';
import AdminDashboard from './components/admin/dashboard/AdminDashboard';
import AdminUserManagement from './components/admin/users/AdminUserManagement';
import AdminUserDetails from './components/admin/users/AdminUserDetails';
import AdminJobManagement from './components/admin/jobs/AdminJobManagement';
import AdminJobDetails from './components/admin/jobs/AdminJobDetails';
import AdminPrivateRoute from './components/common/AdminPrivateRoute';
import AdminPublicLayout from './components/admin/layout/AdminPublicLayout';
import AdminLayout from './components/admin/layout/AdminLayout';

import { Toaster } from "@/components/ui/toaster"
import ForgotPasswordPage from './components/features/auth/ForgotPasswordPage';
import ResetPasswordPage from './components/features/auth/ResetPasswordPage';
import BlogListPage from './pages/BlogListPage';
import { blogArticles } from './data/blogArticles';

function App() {
  return (
    <Router>
      <ThemeProvider attribute="class" defaultTheme="light" enableSystem>
        <AuthProvider>
          <AdminAuthProvider>
            <SessionManager />
            <Routes>
              <Route element={<GeneralPublicLayout />}>
                <Route path="/" element={<HomePage />} />
                <Route path="/services" element={<ServicesPage />} />
                <Route path="/integrations" element={<IntegrationsPage />} />
                <Route path="/resources" element={<RecruitmentResourcesGuidePage />} />
                <Route path="/pricing" element={<PricingPage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/faq" element={<FAQPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/terms-of-service" element={<TermsOfService />} />
                <Route path="/resources/best-practices" element={<BestPracticesPage />} />
                <Route path="/resources/interview-strategies" element={<InterviewStrategiesPage />} />
                <Route path="/resources/talent-analytics" element={<TalentAnalyticsPage />} />
                <Route path="/resources/employer-branding" element={<EmployerBrandingPage />} />
                <Route path="/blog" element={<BlogListPage />} />
                {blogArticles.map((article, index) => (
                  <Route
                    key={index}
                    path={article.link}
                    element={<article.component />}
                  />
                ))}
              </Route>
              <Route element={<PublicLayout />}>
                <Route path={ROUTES.LOGIN} element={<LoginPage />} />
                <Route path={ROUTES.REGISTER} element={<RegisterPage />} />
                <Route path={ROUTES.VERIFY_2FA} element={<TwoFactorVerify />} />
                <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
                <Route path="/reset-password" element={<ResetPasswordPage />} />
              </Route>
              <Route element={<CareerPageLayout />}>
                <Route path="/careers/:slug" element={<CareerPage />} />
                <Route path="/careers/:slug/job/:id" element={<PublicJobDetails />} />
              </Route>
              <Route element={<PrivateRoute><Layout /></PrivateRoute>}>
                <Route path="/" element={<Navigate to={ROUTES.DASHBOARD} replace />} />
                <Route path={ROUTES.DASHBOARD} element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <PrivateRoute><Dashboard /></PrivateRoute>
                  </Suspense>
                } />
                <Route path={ROUTES.JOBS} element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <JobList />
                  </Suspense>
                } />
                <Route path={ROUTES.JOB_CREATE} element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <JobForm />
                  </Suspense>
                } />
                <Route path={ROUTES.JOB_EDIT} element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <JobForm />
                  </Suspense>
                } />
                <Route path={ROUTES.JOB_DETAILS} element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <JobDetails />
                  </Suspense>
                } />
                <Route path={ROUTES.CANDIDATES} element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <CandidateList />
                  </Suspense>
                } />
                <Route path={ROUTES.CANDIDATE_CREATE} element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <CandidateForm />
                  </Suspense>
                } />
                <Route path={ROUTES.CANDIDATE_EDIT} element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <CandidateForm />
                  </Suspense>
                } />
                <Route path={ROUTES.CANDIDATE_DETAILS} element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <CandidateDetails />
                  </Suspense>
                } />
                <Route path={ROUTES.RECRUITMENT} element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <RecruitmentBoard />
                  </Suspense>
                } />
                <Route path="/recruitment/:stage" element={<StageDetails />} />
                <Route path={ROUTES.SETTINGS} element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <UserProfileSettings />
                  </Suspense>
                } />
                <Route path={ROUTES.INTERVIEWS} element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <InterviewList />
                  </Suspense>
                } />
                <Route path={ROUTES.SETUP_2FA} element={
                  <Suspense>
                    <TwoFactorSetup />
                  </Suspense>
                } />
              </Route>
              <Route path="/verify-email-sent" element={<VerifyEmailSent />} />
              <Route path="/verify-email" element={<VerifyEmail />} />
              <Route element={<AdminPublicLayout />}>
                <Route path="/admin/login" element={<AdminLogin />} />
              </Route>
              <Route element={<AdminPrivateRoute><AdminLayout /></AdminPrivateRoute>}>
                <Route path="/admin" element={<AdminDashboard />} />
                <Route path="/admin/users" element={<AdminUserManagement />} />
                <Route path="/admin/users/:id" element={<AdminUserDetails />} />
                <Route path="/admin/jobs" element={<AdminJobManagement />} />
                <Route path="/admin/jobs/:id" element={<AdminJobDetails />} />
              </Route>
              <Route path="/admin/*" element={<Navigate to="/admin" replace />} />
              <Route path="*" element={<Navigate to={ROUTES.DASHBOARD} replace />} />
            </Routes>
          </AdminAuthProvider>
        </AuthProvider>
      </ThemeProvider>
      <ConsentBanner />
      <Toaster />
    </Router>
  );
}

export default App;