import React from 'react';
import { Outlet } from 'react-router-dom';

const PublicLayout = () => {
    return (
        <div className="min-h-screen bg-background font-sans flex flex-col">
            <main className="flex-grow">
                <div className="container mx-auto px-0 py-8 max-w-7xl">
                    <Outlet />
                </div>
            </main>
            <footer className="bg-primary text-primary-foreground text-center p-4">
                <p>&copy; {new Date().getFullYear()} JA Media LLC. All rights reserved.</p>
            </footer>
        </div>
    );
};

export default PublicLayout;