import React from 'react';
import { Outlet } from 'react-router-dom';
import AdminNavbar from './AdminNavbar';

const AdminLayout = () => {
    return (
        <div className="flex flex-col min-h-screen bg-background dark:bg-gray-900 font-sans">
            <AdminNavbar />
            <main className="flex-1 overflow-x-hidden overflow-y-auto bg-background dark:bg-gray-900">
                <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-6 sm:py-8">
                    <Outlet />
                </div>
            </main>
            <footer className="bg-primary text-primary-foreground text-center p-4 text-sm sm:text-base">
                <p>&copy; {new Date().getFullYear()} JA Media LLC. All rights reserved.</p>
            </footer>
        </div>
    );
};

export default AdminLayout;