// Importando bibliotecas y estilos necesarios
import React from 'react'; // Biblioteca React para construir interfaces de usuario
import { createRoot } from 'react-dom/client'; // createRoot para renderizar componentes en el DOM
import './styles/index.css'; // Importando estilos globales
import App from './App'; // Componente principal de la aplicación
import { AuthProvider } from './contexts/AuthContext'; // Proveedor de contexto para autenticación
import { Buffer } from 'buffer'; // Módulo Buffer para manejar datos binarios
import process from 'process'; // Módulo Process para variables de entorno

// Asignando Buffer y process al objeto global window para compatibilidad
window.Buffer = Buffer;
window.process = process;

// Creando la raíz de la aplicación y renderizando
const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider> {/* Proporcionando contexto de autenticación a la app */}
      <App /> {/* Componente principal de la aplicación */}
    </AuthProvider>
  </React.StrictMode>
);