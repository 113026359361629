import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { withAdminAuth } from '../../../contexts/adminAuthContext';
import { getJobDetails, deleteJob } from '../../../services/adminService';
import { handleError } from '../../../utils/errorHandler';
import { AnimatedPage } from '../../../utils/animations';
import ErrorAlert from '../../common/ErrorAlert';
import { Building, MapPin, DollarSign, Calendar, Clock, GraduationCap, CheckCircle, ArrowLeft, Search, Loader2 } from 'lucide-react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

import { Card, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { Separator } from "@/components/ui/separator";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "@/components/ui/dialog";
import { Skeleton } from "@/components/ui/skeleton";
import { JOB_BOARDS } from '../../../constants/appConstants';

const AdminJobDetails = () => {
    const [job, setJob] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        fetchJobDetails();
    }, [id]);

    const fetchJobDetails = async () => {
        try {
            const jobData = await getJobDetails(id);
            setJob(jobData);
            setLoading(false);
        } catch (err) {
            const { message } = handleError(err);
            setError(message);
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        setIsDeleting(true);
        try {
            await deleteJob(id);
            navigate('/admin/jobs');
        } catch (err) {
            const { message } = handleError(err);
            setError(message);
        } finally {
            setIsDeleting(false);
        }
    };

    const getPublishedJobBoards = (job) => {
        return Object.entries(JOB_BOARDS)
            .filter(([key, value]) => job[`publishTo${value}`])
            .map(([_, value]) => value)
            .join(', ') || 'Not published to any job board';
    };

    const JobInfoSkeleton = () => (
        <div className="space-y-2">
            <Skeleton className="h-8 w-3/4 mb-4" />
            {[...Array(8)].map((_, index) => (
                <div key={index} className="flex items-center space-x-2 mb-2">
                    <Skeleton className="h-5 w-5" />
                    <Skeleton className="h-4 w-1/2" />
                </div>
            ))}
        </div>
    );

    const SkillsSkeleton = () => (
        <div className="space-y-2">
            <Skeleton className="h-8 w-1/2 mb-4" />
            {[...Array(3)].map((_, index) => (
                <Skeleton key={index} className="h-4 w-3/4 mb-2" />
            ))}
        </div>
    );

    if (loading) {
        return (
            <AnimatedPage>
                <Skeleton className="h-10 w-32 mb-4" />
                <Card className="mb-8">
                    <CardContent className="p-6">
                        <Skeleton className="h-10 w-3/4 mb-6" />
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                            <JobInfoSkeleton />
                            <SkillsSkeleton />
                        </div>
                        <Skeleton className="h-1 w-full my-6" />
                        <div className="mt-4 space-y-2">
                            <Skeleton className="h-8 w-1/2 mb-2" />
                            <Skeleton className="h-4 w-full" />
                            <Skeleton className="h-4 w-full" />
                            <Skeleton className="h-4 w-3/4" />
                        </div>
                        <Skeleton className="h-1 w-full my-6" />
                        <div className="flex justify-between">
                            <Skeleton className="h-10 w-32" />
                            <Skeleton className="h-10 w-32" />
                        </div>
                    </CardContent>
                </Card>
            </AnimatedPage>
        );
    }

    if (error) return <ErrorAlert message={error} />;
    if (!job) return <div className="text-center mt-8">Job not found</div>;

    return (
        <AnimatedPage>
            <Button onClick={() => navigate('/admin/jobs')} variant="outline" className="mb-4 flex items-center">
                <ArrowLeft className="h-5 w-5 mr-2" />
                Back to Jobs
            </Button>
            <Card className="mb-8">
                <CardContent className="p-6">
                    <h1 className="text-3xl font-bold mb-6">{job.title}</h1>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                        <div>
                            <h2 className="text-2xl font-semibold mb-4">Job Information</h2>
                            <p className="flex items-center mb-2"><Building className="h-5 w-5 mr-2" /> {job.company}</p>
                            <p className="flex items-center mb-2"><MapPin className="h-5 w-5 mr-2" /> {job.city}, {job.country}</p>
                            <p className="flex items-center mb-2"><DollarSign className="h-5 w-5 mr-2" /> {job.salaryMin} - {job.salaryMax} {job.currency}</p>
                            <p className="flex items-center mb-2"><Calendar className="h-5 w-5 mr-2" /> Start Date: {job.startDate}</p>
                            <p className="flex items-center mb-2"><Clock className="h-5 w-5 mr-2" /> {job.jobType} | {job.workTime}</p>
                            <p className="flex items-center mb-2"><GraduationCap className="h-5 w-5 mr-2" /> {job.experienceLevel}</p>
                            <p className="flex items-center mb-2">
                                <CheckCircle className="h-5 w-5 mr-2" />
                                Status: <Badge variant={job.status === 'Active' ? "success" : "secondary"} className="ml-2">{job.status}</Badge>
                            </p>
                            <p className="flex items-center mb-2">
                                <Search className="h-5 w-5 mr-2" />
                                Job Boards: {getPublishedJobBoards(job)}
                            </p>
                        </div>
                        <div>
                            <h2 className="text-2xl font-semibold mb-4">Required Skills</h2>
                            <div>
                                <ul className="list-disc list-inside">
                                    {Array.isArray(job.skills) ? (
                                        job.skills.map((skill, index) => (
                                            <li key={index}>{skill}</li>
                                        ))
                                    ) : (
                                        <li>{job.skills}</li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <Separator className="my-6" />
                    <div className="mt-4">
                        <h2 className="text-2xl font-semibold mb-2">Job Description</h2>
                        <ReactQuill
                            value={job.description}
                            readOnly={true}
                            theme="bubble"
                            modules={{ toolbar: false }}
                        />
                    </div>
                    <Separator className="my-6" />
                    <div className="flex justify-end">
                        <Button
                            variant="destructive"
                            onClick={() => setDeleteModalOpen(true)}
                        >
                            Delete Job
                        </Button>
                    </div>
                </CardContent>
            </Card>
            <Dialog open={deleteModalOpen} onOpenChange={setDeleteModalOpen}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Delete Job</DialogTitle>
                        <DialogDescription>
                            Are you sure you want to delete this job? This action cannot be undone.
                        </DialogDescription>
                    </DialogHeader>
                    <DialogFooter>
                        <Button variant="outline" onClick={() => setDeleteModalOpen(false)}>Cancel</Button>
                        <Button variant="destructive" onClick={handleDelete} disabled={isDeleting}>
                            {isDeleting ? (
                                <>
                                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                    Deleting...
                                </>
                            ) : (
                                'Delete'
                            )}
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </AnimatedPage>
    );
};

export default withAdminAuth(AdminJobDetails);