// src/services/emailService.js

const workerUrl = process.env.REACT_APP_WORKER_URL;

export async function sendEmail(emailData) {
    try {
        const response = await fetch(`${workerUrl}/email`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(emailData)
        });

        if (response.ok) {
            const result = await response.json();
            return result.success;
        } else {
            console.error('Error sending email:', response.statusText);
            return false;
        }
    } catch (error) {
        console.error('Error sending email:', error);
        return false;
    }
}