import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { getUserProfile, updateUserProfile, updateCareerPageSettings, getCareerPageSettings, uploadLogo, deleteLogo } from '../../../services/userService';
import { Clipboard } from 'lucide-react';
import IntegrationSettings from './IntegrationSettings';
import SecuritySettings from './SecuritySettings';
import ErrorAlert from '../../common/ErrorAlert';
import { handleError } from '../../../utils/errorHandler';
import { AnimatedPage } from '../../../utils/animations';
import { DEFAULT_CAREER_PAGE_SETTINGS } from '../../../constants/appConstants';
import CareerPageSettings from './CareerPageSettings';
import BillingSettings from './BillingSettings';
import EmailSettings from './EmailSettings';
import { useToast } from "@/components/ui/use-toast"
import { Loader2 } from 'lucide-react';

import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Card, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { Skeleton } from "@/components/ui/skeleton";
import { Label } from "@/components/ui/label";

import { JOB_BOARDS } from '../../../constants/appConstants';

const UserProfileSettings = () => {
    const [profile, setProfile] = useState({
        firstName: '',
        lastName: '',
        email: '',
        companyName: '',
        jobTitle: '',
        companySize: '',
        companyWebsite: '',
        slug: ''
    });
    const [careerPageSettings, setCareerPageSettings] = useState({
        requiredFields: [],
        companyLogo: '',
        welcomeMessage: 'Join our team and make a difference!'
    });
    const [activeTab, setActiveTab] = useState('profile');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const { user } = useAuth();
    const [integrations, setIntegrations] = useState(
        Object.keys(JOB_BOARDS).reduce((acc, key) => ({
            ...acc,
            [key.toLowerCase()]: { enabled: false }
        }), {})
    );
    const [showCopiedTooltip, setShowCopiedTooltip] = useState(false);
    const [emailSettings, setEmailSettings] = useState({
        sendConfirmationEmail: false,
        confirmationSubject: 'Application Received',
        confirmationBody: '<p>Thank you for your application. We have received it and will review it shortly.</p>'
    });
    const { toast } = useToast()
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            if (user) {
                try {
                    const userProfile = await getUserProfile(user.id);
                    const settings = await getCareerPageSettings(user.id);
                    setProfile(userProfile || {});
                    setCareerPageSettings(settings || DEFAULT_CAREER_PAGE_SETTINGS);
                    setIntegrations(userProfile.integrations || 
                        Object.keys(JOB_BOARDS).reduce((acc, key) => ({
                            ...acc,
                            [key.toLowerCase()]: { enabled: false }
                        }), {})
                    );
                    setEmailSettings(userProfile.emailSettings || {
                        sendConfirmationEmail: false,
                        confirmationSubject: 'Application Received',
                        confirmationBody: '<p>Thank you for your application. We have received it and will review it shortly.</p>'
                    });
                    setLoading(false);
                } catch (err) {
                    const { message } = handleError(err);
                    setError(message);
                    setLoading(false);
                }
            }
        };
        fetchData();
    }, [user]);

    const handleProfileChange = (key, value) => {
        setProfile(prev => ({ ...prev, [key]: value }));
    };

    const handleIntegrationChange = (integration, newConfig) => {
        setIntegrations(prev => ({
            ...prev,
            [integration]: newConfig
        }));
    };

    const handleCareerPageSettingsChange = (key, value) => {
        if (key === 'requiredFields') {
            setCareerPageSettings(prev => ({
                ...prev,
                requiredFields: prev.requiredFields.includes(value)
                    ? prev.requiredFields.filter(field => field !== value)
                    : [...prev.requiredFields, value]
            }));
        } else {
            setCareerPageSettings(prev => ({ ...prev, [key]: value }));
        }
    };

    const handleSubmit = async (tabId) => {
        setIsSaving(true);
        setError('');
        try {
            if (tabId === 'profile' || tabId === 'integrations') {
                // Validación para LinkedIn
                if (integrations.linkedin && integrations.linkedin.enabled) {
                    if (!integrations.linkedin.companyId) {
                        throw new Error('LinkedIn Company ID is required when LinkedIn integration is enabled');
                    }
                }
                await updateUserProfile(user.id, { ...profile, integrations });
            }
            if (tabId === 'careerPage') {
                await updateCareerPageSettings(user.id, careerPageSettings);
            }
            if (tabId === 'emails') {
                await updateUserProfile(user.id, { emailSettings });
            }
            toast({
                title: "Settings updated",
                description: "Your settings have been successfully updated.",
            })
        } catch (err) {
            const { message } = handleError(err);
            setError(message);
        }
        setIsSaving(false);
    };

    const handleLogoUpload = async (e) => {
        const file = e.target.files[0];
        if (file) {
            try {
                const logoUrl = await uploadLogo(user.id, file);
                setCareerPageSettings(prev => ({ ...prev, companyLogo: logoUrl }));
                await updateCareerPageSettings(user.id, { ...careerPageSettings, companyLogo: logoUrl });
                toast({
                    title: "Logo uploaded",
                    description: "Your logo has been successfully uploaded.",
                })
            } catch (error) {
                const { message } = handleError(error);
                setError(message);
            }
        }
    };

    const handleLogoDelete = async () => {
        try {
            await deleteLogo(user.id);
            setCareerPageSettings(prev => ({ ...prev, companyLogo: '' }));
            await updateCareerPageSettings(user.id, { ...careerPageSettings, companyLogo: '' });
            toast({
                title: "Logo deleted",
                description: "Your logo has been successfully deleted.",
            })
        } catch (error) {
            const { message } = handleError(error);
            setError(message);
        }
    };

    const handleCopyUrl = () => {
        const url = `${window.location.origin}/careers/${profile.slug}`;
        navigator.clipboard.writeText(url).then(() => {
            setShowCopiedTooltip(true);
            setTimeout(() => {
                setShowCopiedTooltip(false);
            }, 2000);
        });
    };

    const companySizes = [
        "1-10 employees",
        "11-50 employees",
        "51-200 employees",
        "201-500 employees",
        "501-1000 employees",
        "1001+ employees"
    ];

    const handleEmailSettingsChange = async (newSettings) => {
        setEmailSettings(newSettings);
        await updateUserProfile(user.id, { emailSettings: newSettings });
        toast({
            title: "Email settings updated",
            description: "Your email settings have been successfully updated.",
        })
    };

    const handleEmailModalSave = async (newSettings) => {
        try {
            await updateUserProfile(user.id, { emailSettings: newSettings });
            setEmailSettings(newSettings);
            toast({
                title: "Email template updated",
                description: "Your email template has been successfully updated.",
            })
        } catch (err) {
            const { message } = handleError(err);
            setError(message);
        }
    };

    const FormFieldSkeleton = () => (
        <div className="space-y-2 mb-4">
            <Skeleton className="h-4 w-1/4" />
            <Skeleton className="h-10 w-full" />
        </div>
    );

    const CardSkeleton = () => (
        <Card>
            <CardContent className="p-6">
                <Skeleton className="h-6 w-1/2 mb-4" />
                {[...Array(4)].map((_, index) => (
                    <FormFieldSkeleton key={index} />
                ))}
            </CardContent>
        </Card>
    );

    if (loading) {
        return (
            <AnimatedPage>
                <div className="space-y-6">
                    <div className="flex justify-center mb-6">
                        <Skeleton className="h-10 w-full" />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <CardSkeleton />
                        <CardSkeleton />
                    </div>
                    <Skeleton className="h-10 w-full mt-6" />
                </div>
            </AnimatedPage>
        );
    }

    return (
        <AnimatedPage>
            <div className="space-y-6">
                <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
                    <TabsList className="w-full flex justify-start mb-6 overflow-x-auto">
                        <TabsTrigger value="profile" className="flex-1 min-w-[100px]">Profile</TabsTrigger>
                        <TabsTrigger value="careerPage" className="flex-1 min-w-[100px]">Career Page</TabsTrigger>
                        <TabsTrigger value="integrations" className="flex-1 min-w-[100px]">Integrations</TabsTrigger>
                        <TabsTrigger value="security" className="flex-1 min-w-[100px]">Security</TabsTrigger>
                        <TabsTrigger value="emails" className="flex-1 min-w-[100px]">Emails</TabsTrigger>
                        <TabsTrigger value="billing" className="flex-1 min-w-[100px]">Billing</TabsTrigger>
                    </TabsList>

                    <ErrorAlert message={error} />

                    <TabsContent value="profile">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <Card>
                                <CardContent className="p-6">
                                    <h3 className="text-xl font-semibold mb-4">Personal Information</h3>
                                    <div className="space-y-4">
                                        <div className="space-y-2">
                                            <Label htmlFor="firstName">First Name</Label>
                                            <Input
                                                id="firstName"
                                                value={profile.firstName}
                                                onChange={(e) => handleProfileChange('firstName', e.target.value)}
                                            />
                                        </div>
                                        <div className="space-y-2">
                                            <Label htmlFor="lastName">Last Name</Label>
                                            <Input
                                                id="lastName"
                                                value={profile.lastName}
                                                onChange={(e) => handleProfileChange('lastName', e.target.value)}
                                            />
                                        </div>
                                        <div className="space-y-2">
                                            <Label htmlFor="email">Email</Label>
                                            <Input
                                                id="email"
                                                value={profile.email}
                                                onChange={(e) => handleProfileChange('email', e.target.value)}
                                                disabled
                                            />
                                        </div>
                                        <div className="space-y-2">
                                            <Label htmlFor="jobTitle">Job Title</Label>
                                            <Input
                                                id="jobTitle"
                                                value={profile.jobTitle}
                                                onChange={(e) => handleProfileChange('jobTitle', e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>

                            <Card>
                                <CardContent className="p-6">
                                    <h3 className="text-xl font-semibold mb-4">Company Information</h3>
                                    <div className="space-y-4">
                                        <div className="space-y-2">
                                            <Label htmlFor="companyName">Company Name</Label>
                                            <Input
                                                id="companyName"
                                                value={profile.companyName}
                                                onChange={(e) => handleProfileChange('companyName', e.target.value)}
                                            />
                                        </div>
                                        <div className="space-y-2">
                                            <Label htmlFor="companySize">Company Size</Label>
                                            <Select
                                                value={profile.companySize}
                                                onValueChange={(value) => handleProfileChange('companySize', value)}
                                            >
                                                <SelectTrigger>
                                                    <SelectValue placeholder="Select company size" />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    {companySizes.map((size) => (
                                                        <SelectItem key={size} value={size}>{size}</SelectItem>
                                                    ))}
                                                </SelectContent>
                                            </Select>
                                        </div>
                                        <div className="space-y-2">
                                            <Label htmlFor="companyWebsite">Company Website</Label>
                                            <Input
                                                id="companyWebsite"
                                                value={profile.companyWebsite}
                                                onChange={(e) => handleProfileChange('companyWebsite', e.target.value)}
                                            />
                                        </div>
                                        {profile.slug && (
                                            <div className="space-y-2">
                                                <Label>Career Page URL</Label>
                                                <div className="flex items-center">
                                                    <Input
                                                        readOnly
                                                        value={`${window.location.origin}/careers/${profile.slug}`}
                                                        className="flex-grow mr-2"
                                                    />
                                                    <TooltipProvider>
                                                        <Tooltip open={showCopiedTooltip}>
                                                            <TooltipTrigger asChild>
                                                                <Button
                                                                    size="icon"
                                                                    onClick={handleCopyUrl}
                                                                >
                                                                    <Clipboard className="h-4 w-4" />
                                                                </Button>
                                                            </TooltipTrigger>
                                                            <TooltipContent>
                                                                <p>Copied!</p>
                                                            </TooltipContent>
                                                        </Tooltip>
                                                    </TooltipProvider>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    </TabsContent>

                    <TabsContent value="careerPage">
                        <CareerPageSettings
                            careerPageSettings={careerPageSettings}
                            handleCareerPageSettingsChange={handleCareerPageSettingsChange}
                            handleLogoUpload={handleLogoUpload}
                            handleLogoDelete={handleLogoDelete}
                        />
                    </TabsContent>

                    <TabsContent value="integrations">
                        <Card>
                            <CardContent className="p-6">
                                <IntegrationSettings
                                    integrations={integrations}
                                    onChange={handleIntegrationChange}
                                />
                            </CardContent>
                        </Card>
                    </TabsContent>

                    <TabsContent value="security">
                        <SecuritySettings />
                    </TabsContent>

                    <TabsContent value="billing">
                        <Card>
                            <CardContent className="p-6">
                                <BillingSettings />
                            </CardContent>
                        </Card>
                    </TabsContent>

                    <TabsContent value="emails">
                        <Card>
                            <CardContent className="p-6">
                                <EmailSettings
                                    emailSettings={emailSettings}
                                    onChange={setEmailSettings}
                                    onSaveModal={handleEmailModalSave}
                                />
                            </CardContent>
                        </Card>
                    </TabsContent>
                </Tabs>

                <Button onClick={() => handleSubmit(activeTab)} disabled={isSaving} className="mt-6 w-full">
                    {isSaving ? (
                        <>
                            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                            Saving...
                        </>
                    ) : (
                        'Save Changes'
                    )}
                </Button>
            </div>
        </AnimatedPage>
    );
};

export default UserProfileSettings;