import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import {
    Calendar,
    Video,
    Briefcase,
    Globe,
    Zap,
    Users,
    BarChart2,
    CheckCircle
} from 'lucide-react';
import { AnimatedPage } from '../utils/animations';
import { JOB_BOARDS } from '../constants/appConstants';

const IntegrationsPage = () => {
    const futureIntegrations = [
        { name: "Google Calendar", icon: Calendar, description: "Synchronize interviews and recruitment events" },
        { name: "Zoom", icon: Video, description: "Conduct and manage video interviews" },
        { name: "Indeed", icon: Briefcase, description: "Expand the reach of your job offers" },
        { name: "Glassdoor", icon: Globe, description: "Improve your employer brand" },
    ];

    const currentIntegrations = [
        ...Object.entries(JOB_BOARDS).map(([key, value]) => ({
            name: value,
            logo: `assets/img/jobBoards/${key.toLowerCase()}Logo.png`
        })),
        { name: "Google Jobs", logo: "assets/img/jobBoards/googleLogo.png" },
    ];

    const integrationBenefits = [
        { icon: Globe, title: "Greater reach", description: "Access vast professional networks and increase visibility on multiple job search platforms" },
        { icon: Zap, title: "Optimized workflow", description: "Automate job posting and simplify the recruitment process" },
        { icon: Users, title: "Quality candidates", description: "Attract both passive and active job seekers from various platforms" },
        { icon: BarChart2, title: "Insightful analytics", description: "Track the performance of your job offers across different channels" },
    ];

    return (
        <AnimatedPage>
            <div className="space-y-0">
                {/* Hero Section */}
                <section className="bg-gradient-to-tl from-primary to-muted-foreground text-primary-foreground py-20">
                    <div className="container mx-auto px-4 text-center">
                        <h1 className="text-5xl font-bold mb-4">Powerful Integrations</h1>
                        <p className="text-xl mb-8 max-w-2xl mx-auto">
                            Enhance your recruitment process with seamless integrations that connect PrimeApplicants with the platforms you use daily.
                        </p>
                    </div>
                </section>

                {/* Current Integrations Section */}
                <section className="py-20">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold mb-12 text-center">Current Integrations</h2>
                        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6 mb-12">
                            {currentIntegrations.map((integration, index) => (
                                <Card key={index}>
                                    <CardContent className="p-6 flex items-center justify-center h-full">
                                        <img src={integration.logo} alt={`${integration.name} logo`} className="max-h-16 max-w-full" />
                                    </CardContent>
                                </Card>
                            ))}
                        </div>
                    </div>
                </section>

                {/* Integration Benefits Section */}
                <section className="bg-muted py-20">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold mb-12 text-center">Benefits of Our Integrations</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                            {integrationBenefits.map((benefit, index) => (
                                <Card key={index}>
                                    <CardContent className="text-center p-6">
                                        <benefit.icon className="h-16 w-16 mx-auto mb-4 text-primary" />
                                        <h3 className="text-xl font-semibold mb-2">{benefit.title}</h3>
                                        <p className="text-muted-foreground">{benefit.description}</p>
                                    </CardContent>
                                </Card>
                            ))}
                        </div>
                    </div>
                </section>

                {/* Future Integrations Section */}
                <section className="py-20">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold mb-12 text-center">Coming Soon: More Powerful Integrations</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                            {futureIntegrations.map((integration, index) => (
                                <Card key={index}>
                                    <CardContent className="text-center p-6">
                                        <integration.icon className="h-16 w-16 mx-auto mb-4 text-primary" />
                                        <h3 className="text-xl font-semibold mb-2">{integration.name}</h3>
                                        <p className="text-muted-foreground">{integration.description}</p>
                                    </CardContent>
                                </Card>
                            ))}
                        </div>
                    </div>
                </section>

                {/* CTA Section */}
                <section className="bg-secondary text-secondary-foreground py-20 text-center">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold mb-4">Ready to optimize your recruitment?</h2>
                        <p className="text-xl mb-8">Join PrimeApplicants today and experience the power of integrated recruitment.</p>
                        <Button size="lg">
                            <Link to="/register">
                                Start your free trial today
                            </Link>
                        </Button>
                    </div>
                </section>
            </div>
        </AnimatedPage>
    );
};

export default IntegrationsPage;