import Cookies from 'js-cookie';

const COOKIE_NAME = '2fa_token';

export const generateTwoFactorToken = async (userId, twoFactorVerified) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_WORKER_URL}/generate-2fa-token`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ userId, twoFactorVerified }),
    });
    const data = await response.json();
    Cookies.set(COOKIE_NAME, data.token, {
      secure: true,
      sameSite: 'strict'
    });
  } catch (error) {
    console.error('Error generating 2FA token:', error);
  }
};

export const verify2FAToken = () => {
  const token = Cookies.get(COOKIE_NAME);
  if (!token) return { twoFactorVerified: false };

  try {
    // Realizar una solicitud síncrona
    const xhr = new XMLHttpRequest();
    xhr.open('POST', `${process.env.REACT_APP_WORKER_URL}/verify-2fa-token`, false);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify({ token }));

    if (xhr.status === 200) {
      const data = JSON.parse(xhr.responseText);
      return data;
    } else {
      console.error('Error verifying 2FA token:', xhr.statusText);
      return { twoFactorVerified: false };
    }
  } catch (error) {
    console.error('Error verifying 2FA token:', error);
    return { twoFactorVerified: false };
  }
};

export const removeTwoFactorToken = () => {
  Cookies.remove(COOKIE_NAME);
};