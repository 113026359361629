import React from 'react';
import { Helmet } from 'react-helmet';

const JobStructuredData = ({ job }) => {
    const formatDate = (date) => {
        if (date instanceof Date) {
            return date.toISOString();
        } else if (date && typeof date.toDate === 'function') {
            return date.toDate().toISOString();
        } else if (typeof date === 'string') {
            return new Date(date).toISOString();
        }
        return undefined;
    };

    const structuredData = {
        '@context': 'https://schema.org/',
        '@type': 'JobPosting',
        title: job.title,
        description: job.description,
        datePosted: formatDate(job.createdAt),
        validThrough: job.expirationDate ? formatDate(job.expirationDate) : undefined,
        employmentType: job.workTime,
        hiringOrganization: {
            '@type': 'Organization',
            name: job.company,
        },
        jobLocation: {
            '@type': 'Place',
            address: {
                '@type': 'PostalAddress',
                addressLocality: job.city,
                addressRegion: job.state,
                addressCountry: job.country,
            },
        },
        applicantLocationRequirements: {
            "@type": "Country",
            "name": job.country
        },
        jobLocationType: job.jobType === 'Remote' ? 'TELECOMMUTE' : undefined,
        baseSalary: job.salaryMin && job.salaryMax ? {
            '@type': 'MonetaryAmount',
            currency: job.currency,
            value: {
                '@type': 'QuantitativeValue',
                minValue: job.salaryMin,
                maxValue: job.salaryMax,
                unitText: 'YEAR',
            },
        } : undefined,
        directApply: true
    };

    return (
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify(structuredData)}
            </script>
        </Helmet>
    );
};

export default JobStructuredData;