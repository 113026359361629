import React from 'react';
import { Link } from 'react-router-dom';
import {
    Users,
    ClipboardCheck,
    Lightbulb,
    BarChart2,
    MessageSquare,
    ThumbsUp,
    Cog,
    ShieldCheck
} from 'lucide-react';
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { AnimatedPage } from '../../utils/animations';

const BestPracticesPage = () => {
    const bestPractices = [
        {
            icon: BarChart2,
            title: "Data-Driven Decision Making",
            description: "Leverage analytics and metrics to inform your recruitment strategies and improve outcomes."
        },
        {
            icon: ClipboardCheck,
            title: "Structured Interview Process",
            description: "Implement a consistent, structured interview process to ensure fair evaluation of all candidates."
        },
        {
            icon: ThumbsUp,
            title: "Candidate Experience Focus",
            description: "Prioritize creating a positive candidate experience throughout the recruitment process."
        },
        {
            icon: Users,
            title: "Diversity and Inclusion",
            description: "Implement strategies to promote diversity and inclusion in your hiring practices."
        },
        {
            icon: Lightbulb,
            title: "Continuous Learning and Improvement",
            description: "Stay updated with industry trends and continuously refine your recruitment processes."
        },
        {
            icon: MessageSquare,
            title: "Effective Communication",
            description: "Maintain clear and timely communication with candidates throughout the hiring process."
        }
    ];

    return (
        <AnimatedPage>
            <div className="space-y-0">
                {/* Hero Section */}
                <section className="bg-gradient-to-tl from-primary to-muted-foreground text-primary-foreground py-20">
                    <div className="container mx-auto px-4 text-center">
                        <h1 className="text-5xl font-bold mb-4">Best Practices in Recruitment</h1>
                        <p className="text-xl mb-8 max-w-2xl mx-auto">
                            Discover industry-leading techniques to elevate your recruitment strategy and attract top talent.
                        </p>
                        <div className="space-x-4">
                            <Button>
                                <Link to="/register">Start Free Trial</Link>
                            </Button>
                            <Button variant="secondary">
                                <Link to="/services">Explore Our Services</Link>
                            </Button>
                        </div>
                    </div>
                </section>

                {/* Best Practices Section */}
                <section className="py-20">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold text-center mb-12">Key Recruitment Best Practices</h2>
                        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
                            {bestPractices.map((practice, index) => (
                                <Card key={index}>
                                    <CardHeader>
                                        <practice.icon className="h-8 w-8 mb-2" />
                                        <CardTitle>{practice.title}</CardTitle>
                                    </CardHeader>
                                    <CardContent>
                                        <p>{practice.description}</p>
                                    </CardContent>
                                </Card>
                            ))}
                        </div>
                    </div>
                </section>

                {/* How PrimeApplicants Helps Section */}
                <section className="bg-muted py-20">
                    <div className="container mx-auto px-4">
                        <div className="flex flex-col md:flex-row items-center">
                            <div className="md:w-1/2 mb-8 md:mb-0">
                                <h2 className="text-3xl font-bold mb-4">How PrimeApplicants Supports Best Practices</h2>
                                <p className="text-xl mb-6">Our platform is designed to help you implement and maintain recruitment best practices efficiently.</p>
                                <ul className="list-disc list-inside space-y-2">
                                    <li>Streamlined workflows for structured processes</li>
                                    <li>Advanced analytics for data-driven decisions</li>
                                    <li>Enhanced candidate experience features</li>
                                    <li>Tools to support diversity and inclusion</li>
                                </ul>
                            </div>
                            <div className="md:w-1/2 md:pl-12 flex justify-center">
                                <div className="grid grid-cols-2 gap-4">
                                    <Cog className="h-16 w-16 text-primary" />
                                    <BarChart2 className="h-16 w-16 text-primary" />
                                    <Users className="h-16 w-16 text-primary" />
                                    <ShieldCheck className="h-16 w-16 text-primary" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* CTA Section */}
                <section className="bg-secondary text-secondary-foreground py-20 text-center">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold mb-4">Ready to Implement These Best Practices?</h2>
                        <p className="text-xl mb-8">Join the companies already optimizing their recruitment with PrimeApplicants.</p>
                        <Button size="lg">
                            <Link to="/register">Start Your Free Trial Today</Link>
                        </Button>
                    </div>
                </section>
            </div>
        </AnimatedPage>
    );
};

export default BestPracticesPage;