import React from 'react';
import { Link } from 'react-router-dom';
import { CalendarDays, Clock, User, ChevronRight, ExternalLink } from 'lucide-react';
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { AnimatedPage } from '../../utils/animations';

export const articleMeta = {
    title: "8 Best Free Job Posting Sites in Canada for 2024",
    description: "Discover the top free job posting sites in Canada to help you find the best talent without breaking the bank.",
    date: "August 12, 2024",
    readTime: "6 min read",
};

const FreeJobPostingSitesCanada = () => {
    const { title, description, date, readTime } = articleMeta;

    const jobSites = [
        {
            name: "Job Bank",
            description: "The official job site of the Canadian government, offering free job postings for employers.",
            features: ["Nationwide reach", "Bilingual (English and French)", "Government-backed credibility"]
        },
        {
            name: "Indeed Canada",
            description: "A popular job search engine with a strong presence in the Canadian market.",
            features: ["Free job postings", "Large candidate database", "Sponsored job options"]
        },
        {
            name: "LinkedIn",
            description: "Professional networking site with a robust job board, popular among Canadian professionals.",
            features: ["Professional networking", "Company pages", "Targeted job advertisements"]
        },
        {
            name: "Kijiji",
            description: "A classified ads website with a significant job section, popular for local hiring.",
            features: ["Local focus", "Wide range of job categories", "Easy-to-use interface"]
        },
        {
            name: "Workopolis",
            description: "One of Canada's leading job boards, offering both free and paid posting options.",
            features: ["Resume database access", "Job posting analytics", "Mobile-friendly platform"]
        },
        {
            name: "Eluta",
            description: "A Canadian job search engine that aggregates listings from employer websites.",
            features: ["Direct employer listings", "Canada's Top 100 Employers focus", "Industry-specific searches"]
        },
        {
            name: "Jobillico",
            description: "A Canadian job board with a strong presence in Quebec and growing nationwide.",
            features: ["Bilingual platform", "Applicant tracking system", "Employer branding tools"]
        },
        {
            name: "WowJobs",
            description: "A Canadian job aggregator that collects listings from various sources.",
            features: ["Comprehensive job listings", "Easy application process", "Job alert notifications"]
        }
    ];

    return (
        <AnimatedPage>
            <article className="max-w-5xl mx-auto px-4 py-12">
                {/* Breadcrumb */}
                <nav className="flex mb-8 text-sm" aria-label="Breadcrumb">
                    <ol className="inline-flex items-center space-x-1 md:space-x-3">
                        <li className="inline-flex items-center">
                            <Link to="/" className="inline-flex items-center text-muted-foreground hover:text-primary">
                                Home
                            </Link>
                        </li>
                        <li>
                            <div className="flex items-center">
                                <ChevronRight className="w-4 h-4 text-muted-foreground" />
                                <Link to="/blog" className="ml-1 text-muted-foreground hover:text-primary md:ml-2">
                                    Blog
                                </Link>
                            </div>
                        </li>
                        <li aria-current="page">
                            <div className="flex items-center">
                                <ChevronRight className="w-4 h-4 text-muted-foreground" />
                                <span className="ml-1 text-primary md:ml-2">Free Job Posting Sites Canada</span>
                            </div>
                        </li>
                    </ol>
                </nav>

                <header className="mb-12 text-center">
                    <h1 className="text-5xl font-bold mb-6">{title}</h1>
                    <div className="flex items-center justify-center text-muted-foreground mb-6">
                        <User className="mr-2 h-5 w-5" />
                        <span className="mr-4">PrimeApplicants Team</span>
                        <CalendarDays className="mr-2 h-5 w-5" />
                        <span className="mr-4">{date}</span>
                        <Clock className="mr-2 h-5 w-5" />
                        <span>{readTime}</span>
                    </div>
                    <p className="text-xl text-muted-foreground max-w-3xl mx-auto">{description}</p>
                </header>

                <section className="prose max-w-none mb-12">
                    <p>In the competitive Canadian job market, finding cost-effective ways to attract top talent is crucial for businesses of all sizes. Fortunately, there are several free job posting sites available that can help employers reach a wide pool of qualified candidates across Canada.</p>
                    <p>This article explores the eight best free job posting sites in Canada, highlighting their unique features and benefits to help you optimize your recruitment efforts and find the right candidates for your open positions.</p>
                </section>

                <section className="mb-16">
                    <h2 className="text-3xl font-bold mb-8">Top Free Job Posting Sites in Canada</h2>
                    <div className="grid md:grid-cols-2 gap-8">
                        {jobSites.map((site, index) => (
                            <Card key={index} className="hover:shadow-lg transition-shadow duration-300">
                                <CardHeader>
                                    <CardTitle className="text-2xl mb-2">{site.name}</CardTitle>
                                </CardHeader>
                                <CardContent>
                                    <p className="mb-4">{site.description}</p>
                                    <h4 className="font-semibold mb-2">Key Features:</h4>
                                    <ul className="list-disc pl-5 space-y-1">
                                        {site.features.map((feature, fIndex) => (
                                            <li key={fIndex}>{feature}</li>
                                        ))}
                                    </ul>
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                </section>

                {/* Banner de llamado a la acción adicional */}
                <section className="bg-primary text-primary-foreground py-12 px-8 rounded-lg mb-16">
                    <h2 className="text-3xl font-bold mb-4">Streamline Your Recruitment Process in Canada</h2>
                    <p className="mb-6">Use PrimeApplicants to manage your job postings across multiple Canadian platforms efficiently. Start your free trial today and experience the difference.</p>
                    <Button asChild size="lg" variant="secondary">
                        <Link to="/register">Start Free Trial</Link>
                    </Button>
                </section>

                <section className="prose max-w-none mb-12">
                    <h2 className="text-3xl font-bold mb-6">Tips for Effective Job Posting in Canada</h2>
                    <p>To maximize the effectiveness of your job postings on these platforms, consider the following tips:</p>
                    <ul className="list-disc pl-5 space-y-2">
                        <li><strong>Use bilingual listings:</strong> Consider posting in both English and French to reach a wider audience, especially for positions in Quebec or federal jobs.</li>
                        <li><strong>Highlight Canadian-specific benefits:</strong> Mention benefits that are particularly attractive to Canadian workers, such as healthcare coverage, retirement savings plans, or work-life balance initiatives.</li>
                        <li><strong>Be clear about location and remote work options:</strong> Specify whether the job is on-site, remote, or hybrid, and mention the exact location for on-site positions.</li>
                        <li><strong>Include salary information:</strong> Many Canadian job seekers appreciate transparency regarding compensation. Consider including a salary range if possible.</li>
                        <li><strong>Optimize for keywords:</strong> Use industry-specific terms and common job titles to improve the visibility of your listings in search results.</li>
                        <li><strong>Showcase your company culture:</strong> Highlight what makes your organization unique and appealing to Canadian workers.</li>
                    </ul>
                </section>

                <section className="prose max-w-none mb-12">
                    <h2 className="text-3xl font-bold mb-6">The Future of Job Posting in Canada</h2>
                    <p>As the Canadian job market continues to evolve, we can expect these platforms to innovate with new features such as:</p>
                    <ul className="list-disc pl-5 space-y-2">
                        <li>Enhanced AI-driven candidate matching algorithms</li>
                        <li>Integration of video interviews and virtual job fairs</li>
                        <li>Increased focus on diversity and inclusion in hiring practices</li>
                        <li>More comprehensive skills assessment tools</li>
                        <li>Greater emphasis on employer branding and company culture showcases</li>
                    </ul>
                    <p>Staying informed about these trends and leveraging the right platforms can give employers a significant advantage in attracting top talent in Canada.</p>
                </section>

                <section className="prose max-w-none mb-12">
                    <h2 className="text-3xl font-bold mb-6">Conclusion</h2>
                    <p>Canada offers a diverse and skilled talent pool for employers, and these free job posting sites provide excellent platforms for connecting with potential candidates. By utilizing these platforms effectively and following best practices in job posting, you can significantly improve your recruitment outcomes without incurring substantial costs.</p>
                    <p>Remember, while these sites offer free posting options, consider your specific needs and budget when deciding whether to upgrade to premium features for increased visibility and advanced tools. The right combination of free and paid services can help you create a comprehensive and effective recruitment strategy in the Canadian job market.</p>
                </section>
            </article>
            {/* Llamado a la acción estándar */}
            <section className="bg-secondary text-secondary-foreground py-20 text-center">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold mb-4">Ready to Optimize Your Recruitment in Canada?</h2>
                    <p className="text-xl mb-8">Try PrimeApplicants and experience how our AI-powered platform can streamline your hiring process across multiple Canadian job boards.</p>
                    <Button size="lg" asChild>
                        <Link to="/register">Start Your Free Trial Today</Link>
                    </Button>
                </div>
            </section>
        </AnimatedPage>
    );
};

export default FreeJobPostingSitesCanada;