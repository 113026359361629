import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Mail } from 'lucide-react';
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { AnimatedPage } from '../utils/animations';
import { sendEmail } from '../services/emailService';

const ContactPage = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        const emailData = {
            from: `PrimeApplicants <noreply@primeapplicants.com>`,
            to: ['info@primeapplicants.com'],
            subject: 'New Contact Form Submission',
            text: `Name: ${formData.name}\nEmail: ${formData.email}\n\nMessage:\n${formData.message}`
        };

        const success = await sendEmail(emailData);

        if (success) {
            setIsSubmitted(true);
        } else {
            alert('There was an error sending your message. Please try again later.');
        }

        setIsSubmitting(false);
    };

    return (
        <AnimatedPage>
            <div className="space-y-0">
                {/* Hero Section */}
                <section className="bg-gradient-to-tl from-primary to-muted-foreground text-primary-foreground py-20">
                    <div className="container mx-auto px-4 text-center">
                        <h1 className="text-5xl font-bold mb-4">Get in Touch</h1>
                        <p className="text-xl mb-8 max-w-2xl mx-auto">We're here to help and answer any question you might have about PrimeApplicants.</p>
                    </div>
                </section>

                {/* Contact Form Section */}
                <section className="py-20">
                    <div className="container mx-auto px-4">
                        <Card className="max-w-3xl mx-auto">
                            <CardHeader>
                                <CardTitle className="text-2xl font-bold">Contact Us</CardTitle>
                            </CardHeader>
                            <CardContent>
                                {isSubmitted ? (
                                    <div className="text-center">
                                        <h3 className="text-2xl font-bold text-primary mb-4">Thank You!</h3>
                                        <p className="text-lg">We have received your message and will get in touch with you shortly.</p>
                                    </div>
                                ) : (
                                    <div className="flex flex-col md:flex-row md:space-x-8">
                                        <div className="md:w-1/3 mb-8 md:mb-0">
                                            <div className="flex items-center mb-4">
                                                <Mail className="h-6 w-6 text-primary mr-2" />
                                                <span className="text-muted-foreground">info@primeapplicants.com</span>
                                            </div>
                                            <p className="text-muted-foreground">Feel free to reach out to us with any questions or inquiries. We're here to assist you!</p>
                                        </div>
                                        <div className="md:w-2/3">
                                            <form onSubmit={handleSubmit} className="space-y-4">
                                                <Input
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    required
                                                    placeholder="Your Name"
                                                />
                                                <Input
                                                    name="email"
                                                    type="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    required
                                                    placeholder="your.email@example.com"
                                                />
                                                <Textarea
                                                    name="message"
                                                    value={formData.message}
                                                    onChange={handleChange}
                                                    required
                                                    placeholder="Your message here..."
                                                    rows={4}
                                                />
                                                <Button type="submit" disabled={isSubmitting}>
                                                    {isSubmitting ? 'Sending...' : 'Send Message'}
                                                </Button>
                                            </form>
                                        </div>
                                    </div>
                                )}
                            </CardContent>
                        </Card>
                    </div>
                </section>

                {/* CTA Section */}
                <section className="bg-secondary text-secondary-foreground py-20 text-center">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold mb-4">Ready to Streamline Your Hiring?</h2>
                        <p className="text-xl mb-8">Join PrimeApplicants today and experience the difference in your recruitment process.</p>
                        <Button asChild size="lg">
                            <Link to="/register">Start Your Free Trial Now</Link>
                        </Button>
                    </div>
                </section>
            </div>
        </AnimatedPage>
    );
};

export default ContactPage;