import React from 'react';
import { Alert, AlertTitle, AlertDescription } from "@/components/ui/alert";
import { CircleAlert } from 'lucide-react';

const ErrorAlert = ({ message }) => {
    if (!message) return null;

    return (
        <Alert variant="destructive">
            <CircleAlert className="h-4 w-4" />
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>{message}</AlertDescription>
        </Alert>
    );
};

export default ErrorAlert;