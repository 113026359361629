import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import ErrorAlert from '../../common/ErrorAlert';
import { handleError } from '../../../utils/errorHandler';
import { Card, CardContent, CardHeader, CardTitle, CardFooter } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Label } from "@/components/ui/label";
import { verify2FAToken } from '../../../utils/twoFactorToken';
import { Mail, Lock, Eye, EyeOff, Loader2 } from 'lucide-react';

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const [error, setError] = useState('');
    const [loginStatus, setLoginStatus] = useState('idle'); // 'idle', 'loading', 'success', 'error'
    const [shouldRedirect, setShouldRedirect] = useState(false);
    const { signIn, user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!user) {
            return;
        }

        const twoFactorToken = verify2FAToken();
        
        if (user.emailVerified && (twoFactorToken.twoFactorVerified || !user.twoFactorEnabled)) {
            navigate('/dashboard');
        } else if (!user.emailVerified) {
            navigate('/verify-email-sent');
        } else if (user.twoFactorEnabled && !twoFactorToken.twoFactorVerified) {
            navigate('/verify-2fa');
        }

        setShouldRedirect(false);
    }, [user, navigate, shouldRedirect]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setLoginStatus('loading');
        try {
            const result = await signIn(email, password, rememberMe);
            if (result.requiresEmailVerification) {
                setLoginStatus('success');
                navigate('/verify-email-sent');
            } else if (result.requiresTwoFactor) {
                setLoginStatus('success');
                navigate('/verify-2fa');
            } else if (result.user) {
                setLoginStatus('success');
                setShouldRedirect(true);
            }
        } catch (error) {
            const { message } = handleError(error);
            setError(message);
            setLoginStatus('error');
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <Card className="w-full max-w-md shadow-lg">
                <CardHeader className="space-y-1">
                    <CardTitle className="text-3xl font-extrabold text-center">Welcome back</CardTitle>
                    <p className="text-sm text-muted-foreground text-center">Enter your credentials to access your account</p>
                </CardHeader>
                <CardContent>
                    <ErrorAlert message={error} />
                    <form onSubmit={handleSubmit} className="space-y-4 mt-4">
                        <div className="space-y-2">
                            <Label htmlFor="email">Email address</Label>
                            <div className="relative">
                                <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                                <Input
                                    id="email"
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="pl-10"
                                    placeholder="john.doe@company.com"
                                    required
                                />
                            </div>
                        </div>
                        <div className="space-y-2">
                            <Label htmlFor="password">Password</Label>
                            <div className="relative">
                                <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                                <Input
                                    id="password"
                                    type={showPassword ? "text" : "password"}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="pl-10 pr-10"
                                    placeholder="••••••••"
                                    required
                                />
                                <Button
                                    type="button"
                                    variant="ghost"
                                    size="sm"
                                    className="absolute right-1 top-1/2 transform -translate-y-1/2"
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? <EyeOff size={18} /> : <Eye size={18} />}
                                </Button>
                            </div>
                        </div>
                        <div className="flex items-center justify-between">
                            <div className="flex items-center space-x-2">
                                <Checkbox
                                    id="rememberMe"
                                    checked={rememberMe}
                                    onCheckedChange={(checked) => setRememberMe(checked)}
                                />
                                <Label htmlFor="rememberMe" className="text-sm font-medium">
                                    Remember me
                                </Label>
                            </div>
                            <Link to="/forgot-password" className="text-sm text-primary hover:underline">
                                Forgot password?
                            </Link>
                        </div>
                        <Button 
                            type="submit" 
                            className="w-full" 
                            disabled={loginStatus === 'loading'}
                        >
                            {loginStatus === 'loading' ? (
                                <>
                                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                    Signing In...
                                </>
                            ) : (
                                'Sign In'
                            )}
                        </Button>
                    </form>
                </CardContent>
                <CardFooter className="text-center">
                    <p className="text-sm text-muted-foreground">
                        Don't have an account?{' '}
                        <Link to="/register" className="text-primary hover:underline">
                            Sign up
                        </Link>
                    </p>
                </CardFooter>
            </Card>
        </div>
    );
};

export default LoginPage;