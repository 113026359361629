import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { getJob } from '../../../services/jobService';
import { Card, CardContent } from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import { Badge } from "@/components/ui/badge"
import { Separator } from "@/components/ui/separator"
import JobApplicationForm from './JobApplicationForm';
import ErrorAlert from '../../common/ErrorAlert';
import { handleError } from '../../../utils/errorHandler';
import { AnimatedPage } from '../../../utils/animations';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css'; // Importamos el tema 'bubble' que es más adecuado para visualización
import JobStructuredData from '../jobs/JobStructuredData';

const PublicJobDetails = () => {
    const [job, setJob] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { id, slug } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const source = new URLSearchParams(location.search).get('source') || 'Career Page';

    useEffect(() => {
        const fetchJob = async () => {
            try {
                const jobData = await getJob(id);
                setJob(jobData);
                setLoading(false);
            } catch (err) {
                const { message } = handleError(err);
                setError(message);
                setLoading(false);
            }
        };

        fetchJob();
    }, [id]);

    if (loading) return <div className="text-center mt-8">Loading...</div>;
    if (error) return <ErrorAlert message={error} />;
    if (!job) return <div className="text-center mt-8">Job not found</div>;

    return (
        <AnimatedPage>
            {job && <JobStructuredData job={job} />}
            <div className="max-w-4xl mx-auto">
                <div className="flex justify-between items-center mb-4">
                    <Button
                        variant="outline"
                        onClick={() => navigate(`/careers/${slug}`)}
                    >
                        ← Back to All Jobs
                    </Button>
                    <Button onClick={() => document.getElementById('application-form').scrollIntoView({ behavior: 'smooth' })}>
                        Apply Now
                    </Button>
                </div>
                <Card>
                    <CardContent className="p-6">
                        <h1 className="text-3xl font-bold mb-4">{job.title}</h1>
                        <div className="grid grid-cols-2 gap-4 mb-4">
                            <p><strong>Company:</strong> {job.company}</p>
                            <p><strong>Location:</strong> {job.city}, {job.country}</p>
                            <p><strong>Job Type:</strong> {job.jobType}</p>
                            <p><strong>Work Time:</strong> {job.workTime}</p>
                            <p><strong>Experience Level:</strong> {job.experienceLevel}</p>
                            <p><strong>Start Date:</strong> {job.startDate}</p>
                            {job.salaryMin && job.salaryMax && (
                                <p><strong>Salary Range:</strong> {job.salaryMin} - {job.salaryMax} {job.currency}</p>
                            )}
                        </div>
                        <Separator className="my-4" />
                        <div className="mb-4">
                            <h2 className="text-2xl font-semibold mb-2">Required Skills</h2>
                            <div className="flex flex-wrap gap-2">
                                {job.skills.map((skill, index) => (
                                    <Badge key={index} variant="secondary">
                                        {skill}
                                    </Badge>
                                ))}
                            </div>
                        </div>
                        <Separator className="my-4" />
                        <div className="mt-4">
                            <h2 className="text-2xl font-semibold mb-2">Job Description</h2>
                            <ReactQuill
                                value={job.description}
                                readOnly={true}
                                theme="bubble"
                                modules={{ toolbar: false }}
                            />
                        </div>
                    </CardContent>
                </Card>
                <div id="application-form" className="mt-8">
                    <JobApplicationForm jobId={id} source={source} />
                </div>
            </div>
        </AnimatedPage>
    );
};

export default PublicJobDetails;