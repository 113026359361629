import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, db } from '../config/firebase.config';
import { doc, updateDoc } from 'firebase/firestore';
import { getUserProfile } from '../services/userService';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, signOut } from 'firebase/auth';

const AdminAuthContext = createContext();

export const useAdminAuth = () => useContext(AdminAuthContext);

export const AdminAuthProvider = ({ children }) => {
    const [adminUser, setAdminUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                const profile = await getUserProfile(user.uid);
                if (profile.role === 'admin') {
                    setAdminUser(user);
                } else {
                    setAdminUser(null);
                }
            } else {
                setAdminUser(null);
            }
            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    const signInAdmin = async (email, password) => {
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            const userProfile = await getUserProfile(user.uid);
            if (userProfile.role !== 'admin') {
                throw new Error('You do not have admin permissions');
            }
            await updateDoc(doc(db, 'users', user.uid), {
                lastActivity: Date.now()
            });
            setAdminUser(user);
        } catch (error) {
            console.error('Error signing in as admin:', error);
            throw error;
        }
    };

    const logOutAdmin = async () => {
        await signOut(auth);
        setAdminUser(null);
        navigate('/admin/login');
    };

    const value = {
        adminUser,
        loading,
        signInAdmin,
        logOutAdmin
    };

    return (
        <AdminAuthContext.Provider value={value}>
            {children}
        </AdminAuthContext.Provider>
    );
};

export const withAdminAuth = (WrappedComponent) => {
    return (props) => {
        const { adminUser, loading } = useAdminAuth();
        const navigate = useNavigate();

        useEffect(() => {
            if (!loading && !adminUser) {
                navigate('/admin/login');
            }
        }, [adminUser, loading, navigate]);

        if (loading) {
            return <div>Loading...</div>;
        }

        if (!adminUser) {
            return null;
        }

        return <WrappedComponent {...props} />;
    };
};