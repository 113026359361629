import React, { useState, useEffect } from 'react';
import { withAdminAuth } from '../../../contexts/adminAuthContext';
import { getAllUsers, disableUser, enableUser, getUserJobCount } from '../../../services/adminService';
import { handleError } from '../../../utils/errorHandler';
import { AnimatedPage } from '../../../utils/animations';
import { PAGINATION } from '../../../constants/appConstants';
import ErrorAlert from '../../common/ErrorAlert';
import LoadingSpinner from '../../common/LoadingSpinner';
import { useToast } from "@/components/ui/use-toast";

import { Table, TableHeader, TableBody, TableHead, TableRow, TableCell } from "@/components/ui/table";
import { Input } from "@/components/ui/input";
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "@/components/ui/dialog";
import { Skeleton } from "@/components/ui/skeleton";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { Badge } from "@/components/ui/badge";

import { Search, ChevronLeft, ChevronRight, MoreHorizontal, Loader2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const { ITEMS_PER_PAGE } = PAGINATION;

const AdminUserManagement = () => {
    const { toast } = useToast();
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [disablingUser, setDisablingUser] = useState(null);

    const [filters, setFilters] = useState({
        status: ''
    });
    const [searchTerm, setSearchTerm] = useState('');

    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(ITEMS_PER_PAGE);

    const navigate = useNavigate();

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const usersData = await getAllUsers();
            const usersWithJobCount = await Promise.all(
                usersData.map(async (user) => {
                    const jobCount = await getUserJobCount(user.id);
                    return { ...user, jobCount };
                })
            );
            // Ordenar los usuarios por fecha de creación (más reciente primero)
            const sortedUsers = usersWithJobCount.sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate());
            setUsers(sortedUsers);
            setLoading(false);
        } catch (err) {
            const { message } = handleError(err);
            setError(message);
            setLoading(false);
        }
    };

    const filteredUsers = users.filter(user =>
        (filters.status === '' || filters.status === 'all' ||
            (filters.status === 'active' && !user.disabled) ||
            (filters.status === 'disabled' && user.disabled)) &&
        ((user.name && user.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (user.email && user.email.toLowerCase().includes(searchTerm.toLowerCase())))
    );

    const pages = Math.ceil(filteredUsers.length / rowsPerPage);

    const items = React.useMemo(() => {
        const start = (page - 1) * rowsPerPage;
        const end = start + rowsPerPage;
        return filteredUsers.slice(start, end);
    }, [page, filteredUsers, rowsPerPage]);

    const handleFilterChange = (name, value) => {
        setFilters(prevFilters => ({ ...prevFilters, [name]: value }));
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleToggleUserStatus = async (userId, currentStatus) => {
        setDisablingUser(userId);
        try {
            if (currentStatus) {
                await enableUser(userId);
                toast({
                    title: "User Enabled",
                    description: "The user has been enabled successfully.",
                });
            } else {
                await disableUser(userId);
                toast({
                    title: "User Disabled",
                    description: "The user has been disabled successfully.",
                });
            }
            fetchUsers();
        } catch (err) {
            const { message } = handleError(err);
            setError(message);
            toast({
                title: "Error",
                description: `Failed to ${currentStatus ? 'enable' : 'disable'} user. ${message}`,
                variant: "destructive",
            });
        } finally {
            setDisablingUser(null);
        }
    };

    const columns = [
        { name: "NAME", id: "name" },
        { name: "EMAIL", id: "email" },
        { name: "CREATED DATE", id: "createdDate" },
        { name: "STATUS", id: "status" },
        { name: "JOBS POSTED", id: "jobCount" },
        { name: "ACTIONS", id: "actions" }
    ];

    const renderCell = (user, columnId) => {
        switch (columnId) {
            case 'name':
                return (
                    <div className="flex items-center">
                        <Avatar className="h-8 w-8 mr-2">
                            <AvatarImage src={user.photoURL} alt={user.name || 'User'} />
                            <AvatarFallback>{(user.name && user.name.charAt(0)) || 'U'}</AvatarFallback>
                        </Avatar>
                        {user.firstName || 'N/A'} {user.lastName || 'N/A'}
                    </div>
                );
            case 'email':
                return user.email || 'N/A';
            case 'createdDate':
                return user.createdAt ? new Date(user.createdAt.toDate()).toLocaleDateString() : 'N/A';
            case 'status':
                return (
                    <Badge variant={user.disabled ? "secondary" : "success"}>
                        {user.disabled ? 'Disabled' : 'Active'}
                    </Badge>
                );
            case 'jobCount':
                return user.jobCount || 0;
            case 'actions':
                return (
                    <div className="flex space-x-2">
                        <Button
                            variant="outline"
                            size="sm"
                            onClick={() => navigate(`/admin/users/${user.id}`)}
                        >
                            View Details
                        </Button>
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <Button variant="ghost" className="h-8 w-8 p-0">
                                    <span className="sr-only">Open menu</span>
                                    <MoreHorizontal className="h-4 w-4" />
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent align="end">
                                <DropdownMenuItem
                                    onClick={() => handleToggleUserStatus(user.id, user.disabled)}
                                    disabled={disablingUser === user.id}
                                >
                                    {disablingUser === user.id ? (
                                        <>
                                            <LoadingSpinner ariaLabel="Toggling user status" className="mr-2 h-4 w-4" />
                                            {user.disabled ? 'Enabling...' : 'Disabling...'}
                                        </>
                                    ) : user.disabled ? (
                                        'Enable'
                                    ) : (
                                        'Disable'
                                    )}
                                </DropdownMenuItem>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    </div>
                );
            default:
                return user[columnId] || 'N/A';
        }
    };

    if (loading) {
        return (
            <AnimatedPage>
                <div className="space-y-6">
                    <Skeleton className="h-9 w-48" />
                    <Card>
                        <CardContent className="p-6">
                            <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
                                <Skeleton className="h-10 w-full md:w-2/3" />
                                <Skeleton className="h-10 w-full md:w-1/3" />
                            </div>
                        </CardContent>
                    </Card>
                    <Table>
                        <TableHeader>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableHead key={column.id}>
                                        <Skeleton className="h-4 w-full" />
                                    </TableHead>
                                ))}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {[...Array(5)].map((_, index) => (
                                <TableRow key={index}>
                                    {columns.map((column) => (
                                        <TableCell key={column.id}>
                                            <Skeleton className="h-4 w-full" />
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            </AnimatedPage>
        );
    }

    if (error) return <ErrorAlert message={error} />;

    return (
        <AnimatedPage>
            <div className="space-y-4">
                <h1 className="text-3xl font-bold text-primary">User Management</h1>
                <Card>
                    <CardContent className="p-6">
                        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
                            <div className="relative flex-grow">
                                <Input
                                    type="text"
                                    placeholder="Search users..."
                                    value={searchTerm}
                                    onChange={handleSearch}
                                    className="pl-10"
                                />
                                <Search className="h-5 w-5 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                            </div>
                            <Select
                                value={filters.status}
                                onValueChange={(value) => handleFilterChange('status', value)}
                            >
                                <SelectTrigger className="w-full md:w-48">
                                    <SelectValue placeholder="All Statuses" />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectItem value="all">All Statuses</SelectItem>
                                    <SelectItem value="active">Active</SelectItem>
                                    <SelectItem value="disabled">Disabled</SelectItem>
                                </SelectContent>
                            </Select>
                        </div>
                    </CardContent>
                </Card>
                <Table>
                    <TableHeader>
                        <TableRow>
                            {columns.map((column) => (
                                <TableHead key={column.id}>{column.name}</TableHead>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {items.map((user) => (
                            <TableRow key={user.id}>
                                {columns.map((column) => (
                                    <TableCell key={`${user.id}-${column.id}`}>
                                        {renderCell(user, column.id)}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-6 lg:space-x-8">
                        <div className="flex items-center space-x-2">
                            <p className="text-sm font-medium">Rows per page</p>
                            <Select
                                value={`${rowsPerPage}`}
                                onValueChange={(value) => setRowsPerPage(Number(value))}
                            >
                                <SelectTrigger className="h-8 w-[70px]">
                                    <SelectValue placeholder={rowsPerPage} />
                                </SelectTrigger>
                                <SelectContent side="top">
                                    {[10, 20, 30, 40, 50].map((pageSize) => (
                                        <SelectItem key={pageSize} value={`${pageSize}`}>
                                            {pageSize}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>
                        <div className="flex w-[100px] items-center justify-center text-sm font-medium">
                            Page {page} of {pages}
                        </div>
                        <div className="flex items-center space-x-2">
                            <Button
                                variant="outline"
                                className="h-8 w-8 p-0"
                                onClick={() => setPage(page - 1)}
                                disabled={page === 1}
                            >
                                <span className="sr-only">Go to previous page</span>
                                <ChevronLeft className="h-4 w-4" />
                            </Button>
                            <Button
                                variant="outline"
                                className="h-8 w-8 p-0"
                                onClick={() => setPage(page + 1)}
                                disabled={page === pages}
                            >
                                <span className="sr-only">Go to next page</span>
                                <ChevronRight className="h-4 w-4" />
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </AnimatedPage>
    );
};

export default withAdminAuth(AdminUserManagement);