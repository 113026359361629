import React from 'react';
import { Link } from 'react-router-dom';
import { FileQuestion } from 'lucide-react';
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "@/components/ui/accordion";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { AnimatedPage } from '../utils/animations';
import { JOB_BOARDS } from '../constants/appConstants';

const FAQPage = () => {
    const jobBoardsList = Object.values(JOB_BOARDS).join(', ');

    const faqs = [
        {
            category: "General Information",
            questions: [
                {
                    question: "What is PrimeApplicants?",
                    answer: "PrimeApplicants is an Applicant Tracking System (ATS) designed to simplify and optimize the hiring process. Our platform helps companies efficiently manage jobs, candidates, and the recruitment process."
                },
                {
                    question: "Who can use PrimeApplicants?",
                    answer: "PrimeApplicants is designed for companies of all sizes, from startups to large corporations, looking to improve their hiring process."
                },
                {
                    question: "Is PrimeApplicants free?",
                    answer: "Currently, we offer a free plan with basic features. In the future, we may introduce premium plans with additional functionalities."
                }
            ]
        },
        {
            category: "Main Features",
            questions: [
                {
                    question: "What are the main features of PrimeApplicants?",
                    answer: `PrimeApplicants offers job and candidate management, a recruitment board, customizable career page, integrations with job boards (${jobBoardsList}, Google for Jobs), two-factor authentication (2FA), and customizable email settings.`
                },
                {
                    question: "Can I post jobs on multiple job boards through PrimeApplicants?",
                    answer: `Yes, PrimeApplicants offers integrations with several job boards including ${jobBoardsList}, Google for Jobs. You can enable these integrations in the settings section.`
                },
                {
                    question: "How does the recruitment board work?",
                    answer: "The recruitment board provides a visual view of the hiring process. You can move candidates between different stages of the hiring process using a drag-and-drop system."
                }
            ]
        },
        {
            category: "Setup and Usage",
            questions: [
                {
                    question: "How can I customize my career page?",
                    answer: "You can customize your career page in the settings section. This includes uploading your company logo, setting up a welcome message, and selecting required fields for job applications."
                },
                {
                    question: "Can I customize the emails sent to candidates?",
                    answer: "Yes, you can customize the confirmation emails sent to candidates in the email settings section."
                },
                {
                    question: "How do I set up LinkedIn integration?",
                    answer: "You can set up LinkedIn integration in the integrations section of your settings. You'll need to provide your LinkedIn company ID and enable the integration."
                }
            ]
        },
        {
            category: "Security and Privacy",
            questions: [
                {
                    question: "How does PrimeApplicants protect my data?",
                    answer: "PrimeApplicants uses advanced security measures, including data encryption and secure authentication. We also offer two-factor authentication (2FA) for an additional layer of security."
                },
                {
                    question: "Does PrimeApplicants comply with data protection regulations?",
                    answer: "Yes, PrimeApplicants is designed to comply with data protection regulations such as GDPR and CCPA. You can find more information in our privacy policy."
                },
                {
                    question: "Who has access to candidate data?",
                    answer: "Only authorized users from your company have access to candidate data. You can manage user permissions in your account settings."
                }
            ]
        },
        {
            category: "Plans and Billing",
            questions: [
                {
                    question: "What does the free plan include?",
                    answer: "The free plan includes basic features such as job and candidate management, a recruitment board, and a customizable career page. To see all features, visit our pricing page."
                },
                {
                    question: "Will there be paid plans in the future?",
                    answer: "We are considering introducing premium plans with additional features in the future. We'll keep you informed about any changes to our plans and pricing."
                }
            ]
        },
        {
            category: "Support and Help",
            questions: [
                {
                    question: "How can I get help if I have problems?",
                    answer: "We offer email support for all our users. You can contact us at info@primeapplicants.com. We're also working on a comprehensive knowledge base to help you resolve common issues."
                },
                {
                    question: "Do you offer training for using PrimeApplicants?",
                    answer: "Currently, we don't offer formal training, but we're developing learning resources, including video tutorials and step-by-step guides, which will soon be available in our knowledge base."
                }
            ]
        }
    ];

    return (
        <AnimatedPage>
            <div className="space-y-0">
                {/* Hero Section */}
                <section className="bg-gradient-to-tl from-primary to-muted-foreground text-primary-foreground py-20">
                    <div className="container mx-auto px-4 text-center">
                        <h1 className="text-5xl font-bold mb-4">Frequently Asked Questions</h1>
                        <p className="text-xl mb-8 max-w-2xl mx-auto">Find answers to the most common questions about PrimeApplicants.</p>
                    </div>
                </section>

                {/* FAQ Categories */}
                <section className="py-20">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold text-center mb-12">FAQ Categories</h2>
                        <div className="grid md:grid-cols-3 gap-8">
                            {faqs.map((category, index) => (
                                <Button 
                                    key={index} 
                                    variant="ghost" 
                                    asChild 
                                    className="h-auto p-0"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const element = document.getElementById(category.category.toLowerCase().replace(/\s+/g, '-'));
                                        element.scrollIntoView({ behavior: 'smooth' });
                                    }}
                                >
                                    <Link to={`#${category.category.toLowerCase().replace(/\s+/g, '-')}`}>
                                        <Card className="w-full hover:shadow-lg transition-shadow">
                                            <CardContent className="p-6">
                                                <FileQuestion className="h-12 w-12 text-primary mb-4" />
                                                <h3 className="text-xl font-semibold mb-2">{category.category}</h3>
                                            </CardContent>
                                        </Card>
                                    </Link>
                                </Button>
                            ))}
                        </div>
                    </div>
                </section>

                {/* FAQ Content */}
                <section className="bg-muted py-20">
                    <div className="container mx-auto px-4">
                        {faqs.map((category, categoryIndex) => (
                            <div key={categoryIndex} className="mb-16">
                                <h2 id={category.category.toLowerCase().replace(/\s+/g, '-')} className="text-3xl font-bold mb-8">{category.category}</h2>
                                <Accordion type="single" collapsible className="w-full">
                                    {category.questions.map((faq, faqIndex) => (
                                        <AccordionItem key={faqIndex} value={`item-${categoryIndex}-${faqIndex}`}>
                                            <AccordionTrigger>{faq.question}</AccordionTrigger>
                                            <AccordionContent>
                                                <p>{faq.answer}</p>
                                            </AccordionContent>
                                        </AccordionItem>
                                    ))}
                                </Accordion>
                            </div>
                        ))}
                    </div>
                </section>

                {/* CTA Section */}
                <section className="bg-secondary text-secondary-foreground py-20 text-center">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold mb-4">Still Have Questions?</h2>
                        <p className="text-xl mb-8">We're here to help. Contact our support team for personalized assistance.</p>
                        <Button asChild>
                            <a href="mailto:info@primeapplicants.com">
                                Contact Support
                            </a>
                        </Button>
                    </div>
                </section>
            </div>
        </AnimatedPage>
    );
};

export default FAQPage;