import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import { Card, CardHeader, CardContent, CardFooter, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import ErrorAlert from '../../common/ErrorAlert';
import { handleError } from '../../../utils/errorHandler';
import { verify2FAToken } from '../../../utils/twoFactorToken';
import { InputOTP, InputOTPGroup, InputOTPSlot, InputOTPSeparator } from "@/components/ui/input-otp"
import { Loader2, ShieldCheck, LogOut } from 'lucide-react';

const TwoFactorVerify = () => {
    const [token, setToken] = useState('');
    const [error, setError] = useState('');
    const [verifyStatus, setVerifyStatus] = useState('idle'); // 'idle', 'loading', 'success', 'error'
    const navigate = useNavigate();
    const { verifyTwoFactorToken, setUser, logOut, user } = useAuth();

    useEffect(() => {
        const twoFactorToken = verify2FAToken();
        if (!user || !twoFactorToken) {
            navigate('/login');
        } else if (!user.twoFactorEnabled || twoFactorToken.twoFactorVerified) {
            navigate('/dashboard');
        }
    }, [navigate, user]);

    useEffect(() => {
        if (token.length === 6) {
            handleVerify();
        }
    }, [token]);

    const handleVerify = async (e) => {
        if (e) e.preventDefault();
        if (verifyStatus === 'loading') return;

        setError('');
        setVerifyStatus('loading');

        try {
            const twoFactorToken = verify2FAToken();
            if (!twoFactorToken || !twoFactorToken.userId) {
                throw new Error('User ID not found. Please try logging in again.');
            }
            const updatedUser = await verifyTwoFactorToken(twoFactorToken.userId, token);
            setUser(updatedUser);
            setVerifyStatus('success');
            navigate('/dashboard');
        } catch (error) {
            const { message } = handleError(error);
            setError(message);
            setVerifyStatus('error');
        }
    };

    const handleLogout = async () => {
        try {
            const twoFactorToken = verify2FAToken();
            if (twoFactorToken && twoFactorToken.userId) {
                await logOut(twoFactorToken.userId);
            }
            navigate('/login');
        } catch (error) {
            const { message } = handleError(error);
            setError(message);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <Card className="w-full max-w-md shadow-lg">
                <CardHeader className="space-y-1">
                    <div className="flex justify-between items-center">
                        <CardTitle className="text-3xl font-extrabold flex items-center">
                            <ShieldCheck className="w-8 h-8 mr-2 text-primary" />
                            2FA Verify
                        </CardTitle>
                        <Button
                            variant="ghost"
                            size="sm"
                            onClick={handleLogout}
                            className="text-muted-foreground hover:text-foreground"
                        >
                            <LogOut className="w-4 h-4 mr-2" />
                            Log Out
                        </Button>
                    </div>
                    <p className="text-sm text-muted-foreground">
                        Enter the 6-digit code from your authenticator app
                    </p>
                </CardHeader>
                <CardContent className="space-y-4">
                    <ErrorAlert message={error} />
                    <form onSubmit={handleVerify} className="space-y-4">
                        <div>
                            <InputOTP
                                value={token}
                                onChange={setToken}
                                maxLength={6}
                                autoFocus={true}
                            >
                                <InputOTPGroup className="w-full">
                                    <InputOTPSlot index={0} className="flex-1 h-12 text-2xl" />
                                    <InputOTPSlot index={1} className="flex-1 h-12 text-2xl" />
                                    <InputOTPSlot index={2} className="flex-1 h-12 text-2xl" />
                                </InputOTPGroup>
                                <InputOTPSeparator />
                                <InputOTPGroup className="w-full">
                                    <InputOTPSlot index={3} className="flex-1 h-12 text-2xl" />
                                    <InputOTPSlot index={4} className="flex-1 h-12 text-2xl" />
                                    <InputOTPSlot index={5} className="flex-1 h-12 text-2xl" />
                                </InputOTPGroup>
                            </InputOTP>
                        </div>
                        <Button
                            type="submit"
                            className="w-full"
                            disabled={verifyStatus === 'loading'}
                        >
                            {verifyStatus === 'loading' ? (
                                <>
                                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                    Verifying...
                                </>
                            ) : (
                                'Verify'
                            )}
                        </Button>
                    </form>
                </CardContent>
            </Card>
        </div>
    );
};

export default TwoFactorVerify;