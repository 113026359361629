import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { withAdminAuth } from '../../../contexts/adminAuthContext';
import { getTotalUsers, getTotalJobs, getTotalCandidates, getRecentUsers, getRecentJobs } from '../../../services/adminService';
import { Briefcase, Users, BarChart } from 'lucide-react';
import ErrorAlert from '../../common/ErrorAlert';
import { handleError } from '../../../utils/errorHandler';
import { AnimatedPage } from '../../../utils/animations';

import { Card, CardContent, CardHeader, CardTitle, CardFooter } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";

const AdminDashboard = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [stats, setStats] = useState({ users: 0, jobs: 0, candidates: 0 });
    const [recentUsers, setRecentUsers] = useState([]);
    const [recentJobs, setRecentJobs] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [users, jobs, candidates, newUsers, newJobs] = await Promise.all([
                    getTotalUsers(),
                    getTotalJobs(),
                    getTotalCandidates(),
                    getRecentUsers(),
                    getRecentJobs()
                ]);
                setStats({ users, jobs, candidates });
                setRecentUsers(newUsers);
                setRecentJobs(newJobs);
            } catch (err) {
                const { message } = handleError(err);
                setError(message);
            }
            setLoading(false);
        };
        fetchData();
    }, []);

    const StatCard = ({ title, value, icon: Icon, color }) => (
        <Card>
            <CardContent className="flex items-center p-6">
                <div className={`${color} p-3 rounded-full mr-4`}>
                    <Icon className="h-6 w-6 text-white" />
                </div>
                <div>
                    <CardTitle className="text-lg font-semibold text-gray-600">{title}</CardTitle>
                    <p className="text-2xl font-bold text-primary">{value}</p>
                </div>
            </CardContent>
        </Card>
    );

    const StatCardSkeleton = () => (
        <Card>
            <CardContent className="flex items-center p-6">
                <Skeleton className="w-12 h-12 rounded-full mr-4" />
                <div>
                    <Skeleton className="h-5 w-24 mb-1" />
                    <Skeleton className="h-8 w-16" />
                </div>
            </CardContent>
        </Card>
    );

    const RecentActivityCard = ({ title, items, viewAllLink }) => (
        <Card className="flex flex-col h-full">
            <CardHeader>
                <CardTitle>{title}</CardTitle>
            </CardHeader>
            <CardContent className="flex-grow">
                {items.length > 0 ? (
                    <ul>
                        {items.map((item, index) => (
                            <li key={index} className="mb-2">
                                <p className="font-semibold">{item.name || item.title}</p>
                                <p className="text-sm text-gray-600">{item.email || item.company}</p>
                                <p className="text-sm text-gray-600">Created: {formatDate(item.createdAt)}</p>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No recent activity.</p>
                )}
            </CardContent>
            <CardFooter>
                <Button onClick={() => navigate(viewAllLink)} className="w-full">
                    View All
                </Button>
            </CardFooter>
        </Card>
    );

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const date = dateString.toDate ? dateString.toDate() : new Date(dateString);
        if (isNaN(date.getTime())) return 'Invalid Date';
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };

    if (loading) {
        return (
            <AnimatedPage>
                <div className="space-y-6">
                    <Skeleton className="h-10 w-48 mb-6" />
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                        {[...Array(4)].map((_, index) => (
                            <StatCardSkeleton key={index} />
                        ))}
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                        <Skeleton className="h-64" />
                        <Skeleton className="h-64" />
                    </div>
                </div>
            </AnimatedPage>
        );
    }

    if (error) return <ErrorAlert message={error} />;

    return (
        <AnimatedPage>
            <div className="space-y-6">
                <h1 className="text-3xl font-bold text-primary">Admin Dashboard</h1>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                    <StatCard title="Total Users" value={stats.users} icon={Users} color="bg-blue-500" />
                    <StatCard title="Total Jobs" value={stats.jobs} icon={Briefcase} color="bg-green-500" />
                    <StatCard title="Total Candidates" value={stats.candidates} icon={Users} color="bg-yellow-500" />
                    <StatCard
                        title="Job/User Ratio"
                        value={stats.users > 0 ? (stats.jobs / stats.users).toFixed(2) : '0'}
                        icon={BarChart}
                        color="bg-purple-500"
                    />
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                    <RecentActivityCard
                        title="Recent Users"
                        items={recentUsers}
                        viewAllLink="/admin/users"
                    />
                    <RecentActivityCard
                        title="Recent Jobs"
                        items={recentJobs}
                        viewAllLink="/admin/jobs"
                    />
                </div>
            </div>
        </AnimatedPage>
    );
};

export default withAdminAuth(AdminDashboard);