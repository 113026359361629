import React from 'react';
import { Link } from 'react-router-dom';
import {
    Users, BarChart, Clock, Settings,
    Shield, Globe, DollarSign, HeadphonesIcon
} from 'lucide-react';
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { AnimatedPage } from '../utils/animations';
const ServicesPage = () => {
    const services = [
        {
            icon: Users,
            title: "Candidate Management",
            description: "Easily organize and track candidates throughout the entire hiring process."
        },
        {
            icon: BarChart,
            title: "Applicant Tracking",
            description: "Streamline your recruitment process with our intuitive applicant tracking system."
        },
        {
            icon: Clock,
            title: "Job Posting",
            description: "Create and publish job offers simply and efficiently."
        },
        {
            icon: Settings,
            title: "Custom Configuration",
            description: "Adapt the platform to your specific hiring needs."
        },
        {
            icon: Shield,
            title: "Data Security",
            description: "Keep your candidates' information secure with our data protection measures."
        },
        {
            icon: Globe,
            title: "Custom Career Page",
            description: "Create a unique career page for your company and showcase your job offers."
        },
        {
            icon: DollarSign,
            title: "Cost-Effective Hiring",
            description: "Reduce your cost-per-hire and improve ROI with our efficient recruitment solution."
        },
        {
            icon: HeadphonesIcon,
            title: "User Profiles",
            description: "Manage access and roles for your recruitment team."
        }
    ];

    return (
        <AnimatedPage>
            <div className="space-y-0">
                {/* Hero Section */}
                <section className="bg-gradient-to-tl from-primary to-muted-foreground text-primary-foreground py-20">
                    <div className="container mx-auto px-4 text-center">
                        <h1 className="text-5xl font-bold mb-4">Our Services</h1>
                        <p className="text-xl mb-8 max-w-2xl mx-auto">Discover how PrimeApplicants can streamline your hiring process with our comprehensive suite of recruitment tools.</p>
                        <Button asChild>
                            <Link to="/register">Start Free Trial</Link>
                        </Button>
                    </div>
                </section>

                <section className="py-20">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold text-center mb-12">Comprehensive Recruitment Solutions</h2>
                        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
                            {services.map((service, index) => (
                                <Card key={index}>
                                    <CardHeader>
                                        <service.icon className="h-8 w-8 mb-2" />
                                        <CardTitle>{service.title}</CardTitle>
                                    </CardHeader>
                                    <CardContent>
                                        <p>{service.description}</p>
                                    </CardContent>
                                </Card>
                            ))}
                        </div>
                    </div>
                </section>

                <section className="bg-secondary py-20">
                    <div className="container mx-auto px-4">
                        <div className="flex flex-col md:flex-row items-center">
                            <div className="md:w-1/2 mb-8 md:mb-0">
                                <img src="/assets/img/services.jpg" alt="Applicant Tracking" className="rounded-lg shadow-md" />
                            </div>
                            <div className="md:w-1/2 md:pl-12">
                                <h2 className="text-3xl font-bold mb-4">Efficient Applicant Tracking</h2>
                                <p className="text-xl mb-6">Our applicant tracking system helps you manage your recruitment process effectively. Organize candidates, track applications, and streamline your hiring workflow.</p>
                                <ul className="list-disc list-inside space-y-2">
                                    <li>Centralized candidate database</li>
                                    <li>Application status tracking</li>
                                    <li>Customizable hiring stages</li>
                                    <li>Collaborative hiring tools</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="bg-secondary text-secondary-foreground py-20 text-center">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold mb-4">Ready to Improve Your Hiring Process?</h2>
                        <p className="text-xl mb-8">Join the companies already optimizing their recruitment with PrimeApplicants.</p>
                        <Button size="lg">
                            <Link to="/register">Start Your Free Trial Today</Link>
                        </Button>
                    </div>
                </section>
            </div>
        </AnimatedPage>
    );
};

export default ServicesPage;