// src/services/interviewService.js

import { db } from '../config/firebase.config';
import { collection, addDoc, serverTimestamp, query, where, getDocs, doc as firestoreDoc, getDoc, deleteDoc, updateDoc, limit } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { FIRESTORE_COLLECTIONS } from '../constants/appConstants';
import { handleError } from '../utils/errorHandler';

const INTERVIEWS_COLLECTION = FIRESTORE_COLLECTIONS.INTERVIEWS;
const CANDIDATES_COLLECTION = FIRESTORE_COLLECTIONS.CANDIDATES;
const JOBS_COLLECTION = FIRESTORE_COLLECTIONS.JOBS;

export const scheduleInterview = async (candidateId, jobId, interviewDateTime) => {
    const auth = getAuth(); // Get the current authentication state
    const user = auth.currentUser; // Retrieve the current user

    if (!user) {
        throw handleError('User not authenticated'); // Handle error if user is not authenticated
    }

    try {
        const newInterviewRef = await addDoc(collection(db, INTERVIEWS_COLLECTION), {
            candidateId,
            jobId,
            interviewDateTime,
            scheduledBy: user.uid,
            createdAt: serverTimestamp(),
            status: 'scheduled'
        });

        const newInterviewSnap = await getDoc(newInterviewRef);
        return { id: newInterviewSnap.id, ...newInterviewSnap.data() };
    } catch (error) {
        throw handleError(error);
    }
};

export const getInterviews = async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
        throw handleError('User not authenticated');
    }

    try {
        const interviewsRef = collection(db, INTERVIEWS_COLLECTION);
        const q = query(interviewsRef, where('scheduledBy', '==', user.uid));
        const querySnapshot = await getDocs(q);

        const interviews = [];
        for (const doc of querySnapshot.docs) {
            const interviewData = doc.data(); // Get the interview data
            // Get the candidate and job documents
            const candidateDoc = await getDoc(firestoreDoc(db, CANDIDATES_COLLECTION, interviewData.candidateId));
            let jobTitle = 'No job assigned';

            if (interviewData.jobId) {
                const jobDoc = await getDoc(firestoreDoc(db, JOBS_COLLECTION, interviewData.jobId));
                if (jobDoc.exists()) {
                    jobTitle = jobDoc.data().title;
                } else {
                    jobTitle = 'Unknown';
                }
            }

            interviews.push({
                id: doc.id,
                ...interviewData,
                candidateName: candidateDoc.exists() ? candidateDoc.data().name : 'Unknown',
                jobTitle
            });
        }

        return interviews;
    } catch (error) {
        throw handleError(error);
    }
};

export const getInterviewForCandidate = async (candidateId) => {
    try {
        const interviewsRef = collection(db, INTERVIEWS_COLLECTION);
        const q = query(
            interviewsRef,
            where('candidateId', '==', candidateId),
            where('status', '==', 'scheduled'),
            limit(1)
        );
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            const interviewDoc = querySnapshot.docs[0];
            const interviewData = interviewDoc.data();
            const jobDoc = await getDoc(firestoreDoc(db, JOBS_COLLECTION, interviewData.jobId));

            return {
                id: interviewDoc.id,
                ...interviewData,
                jobTitle: jobDoc.exists() ? jobDoc.data().title : 'Unknown'
            };
        }

        return null;
    } catch (error) {
        throw handleError(error);
    }
};

export const getInterviewsForCandidate = async (candidateId) => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
        throw handleError('User not authenticated');
    }

    try {
        const interviewsRef = collection(db, INTERVIEWS_COLLECTION);
        const q = query(interviewsRef,
            where('candidateId', '==', candidateId),
            where('scheduledBy', '==', user.uid)
        );
        const querySnapshot = await getDocs(q);

        const interviews = [];
        for (const doc of querySnapshot.docs) {
            const interviewData = doc.data();
            let jobTitle = 'No job assigned';
            if (interviewData.jobId) {
                const jobDoc = await getDoc(firestoreDoc(db, JOBS_COLLECTION, interviewData.jobId));
                if (jobDoc.exists()) {
                    jobTitle = jobDoc.data().title;
                } else {
                    jobTitle = 'Unknown';
                }
            }
            interviews.push({
                id: doc.id,
                ...interviewData,
                jobTitle
            });
        }

        return interviews;
    } catch (error) {
        throw handleError(error);
    }
};

export const deleteInterview = async (interviewId) => {
    try {
        await deleteDoc(firestoreDoc(db, INTERVIEWS_COLLECTION, interviewId));
    } catch (error) {
        throw handleError(error);
    }
};

export const markInterviewAsCompleted = async (interviewId) => {
    try {
        await updateDoc(firestoreDoc(db, INTERVIEWS_COLLECTION, interviewId), {
            status: 'completed'
        });
    } catch (error) {
        throw handleError(error);
    }
};

export const checkAndUpdateInterviewStatus = async (interview) => {
    if (interview.status !== 'completed' && new Date(interview.interviewDateTime) < new Date()) {
        await markInterviewAsCompleted(interview.id);
        return { ...interview, status: 'completed' };
    }
    return interview;
};