import React from 'react';
import { Link } from 'react-router-dom';
import { CalendarDays, Clock, User, ExternalLink, ChevronRight } from 'lucide-react';
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { AnimatedPage } from '../../utils/animations';

export const articleMeta = {
    title: "10 Best Free Job Posting Sites for USA-Based Recruiters",
    description: "Discover the top free job posting sites in the USA to help you find the right talent without breaking the bank.",
    date: "August 15, 2024",
    readTime: "4 min read",
};

const FreeJobPostingSitesUSA = () => {
    const { title, description, date, readTime } = articleMeta;

    const jobSites = [
        {
            name: "Indeed",
            description: "One of the most popular job boards with over 250 million monthly visitors. Post unlimited jobs for free and access a vast resume database.",
            features: ["Unlimited free job postings", "Large resume database", "Sponsored job options available"]
        },
        {
            name: "Google for Jobs",
            description: "A search engine that aggregates job listings from various sources. Follow guidelines to have your jobs appear in Google search results.",
            features: ["Wide reach through Google search", "Integration with your existing job postings", "Enhanced visibility for your openings"]
        },
        {
            name: "LinkedIn",
            description: "Professional network with over 774 million members. Post jobs for free through your company page and share with your network.",
            features: ["Large professional network", "Free job posting on company page", "Paid options for increased visibility"]
        },
        {
            name: "Monster",
            description: "One of the oldest job boards with millions of resumes. Post one job for free for seven days and access a limited number of resumes.",
            features: ["Trusted platform", "Free 7-day job posting", "Access to resume database"]
        },
        {
            name: "Jora",
            description: "Global job board operating in 36 countries. Post unlimited jobs for free and access a resume database of over 10 million candidates.",
            features: ["Unlimited free job postings", "Large candidate database", "Global reach"]
        },
        {
            name: "Glassdoor",
            description: "Platform for company reviews and job listings. Post 10 jobs for free for seven days and reach candidates who value company culture.",
            features: ["Company reviews alongside job postings", "Free 7-day posting for 10 jobs", "Attract culture-conscious candidates"]
        },
        {
            name: "SimplyHired",
            description: "Aggregator of job listings from various sources. Post unlimited jobs for free and access a large resume database.",
            features: ["Unlimited free job postings", "Large resume database", "Wide distribution of listings"]
        },
        {
            name: "Handshake",
            description: "Platform connecting employers with students and recent graduates. Post unlimited jobs for free and reach over 6 million students.",
            features: ["Access to fresh talent", "Unlimited free job postings", "Connect with specific universities"]
        }
    ];

    return (
        <AnimatedPage>
            <article className="max-w-5xl mx-auto px-4 py-12">
                {/* Breadcrumb */}
                <nav className="flex mb-8 text-sm" aria-label="Breadcrumb">
                    <ol className="inline-flex items-center space-x-1 md:space-x-3">
                        <li className="inline-flex items-center">
                            <Link to="/" className="inline-flex items-center text-muted-foreground hover:text-primary">
                                Home
                            </Link>
                        </li>
                        <li>
                            <div className="flex items-center">
                                <ChevronRight className="w-4 h-4 text-muted-foreground" />
                                <Link to="/blog" className="ml-1 text-muted-foreground hover:text-primary md:ml-2">
                                    Blog
                                </Link>
                            </div>
                        </li>
                        <li aria-current="page">
                            <div className="flex items-center">
                                <ChevronRight className="w-4 h-4 text-muted-foreground" />
                                <span className="ml-1 text-primary md:ml-2">Free Job Posting Sites USA</span>
                            </div>
                        </li>
                    </ol>
                </nav>

                <header className="mb-12 text-center">
                    <h1 className="text-5xl font-bold mb-6">{title}</h1>
                    <div className="flex items-center justify-center text-muted-foreground mb-6">
                        <User className="mr-2 h-5 w-5" />
                        <span className="mr-4">PrimeApplicants Team</span>
                        <CalendarDays className="mr-2 h-5 w-5" />
                        <span className="mr-4">{date}</span>
                        <Clock className="mr-2 h-5 w-5" />
                        <span>{readTime}</span>
                    </div>
                    <p className="text-xl text-muted-foreground max-w-3xl mx-auto">{description}</p>
                </header>

                <section className="prose max-w-none mb-12">
                    <p>In today's competitive job market, finding the right talent doesn't have to be expensive. There are numerous free job posting sites available to USA-based recruiters that can help you reach a wide pool of qualified candidates. This article explores the best free job boards and platforms to post your job openings, helping you maximize your recruitment efforts without straining your budget.</p>
                </section>

                <section className="mb-16">
                    <h2 className="text-3xl font-bold mb-8">Top Free Job Posting Sites in the USA</h2>
                    <div className="grid md:grid-cols-2 gap-8">
                        {jobSites.map((site, index) => (
                            <Card key={index} className="hover:shadow-lg transition-shadow duration-300">
                                <CardHeader>
                                    <CardTitle className="flex items-center">
                                        <span className="mr-2">{index + 1}.</span> {site.name}
                                    </CardTitle>
                                </CardHeader>
                                <CardContent>
                                    <p className="mb-4">{site.description}</p>
                                    <ul className="list-disc pl-5 space-y-1">
                                        {site.features.map((feature, fIndex) => (
                                            <li key={fIndex}>{feature}</li>
                                        ))}
                                    </ul>
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                </section>

                {/* Banner de llamado a la acción adicional */}
                <section className="bg-primary text-primary-foreground py-12 px-8 rounded-lg mb-16">
                    <h2 className="text-3xl font-bold mb-4">Streamline Your Job Posting Process</h2>
                    <p className="mb-6">While these free sites are great, managing multiple platforms can be time-consuming. PrimeApplicants helps you post to multiple job boards with a single click.</p>
                    <Button asChild size="lg" variant="secondary">
                        <Link to="/register">Try PrimeApplicants for Free</Link>
                    </Button>
                </section>

                <section className="prose max-w-none mb-12">
                    <h2 className="text-3xl font-bold mb-6">Tips for Leveraging Free Job Posting Sites</h2>
                    <ul className="list-disc pl-5 space-y-4">
                        <li><strong>Optimize your job titles and descriptions:</strong> Use clear, searchable job titles and include relevant keywords in your descriptions.</li>
                        <li><strong>Regularly refresh your listings:</strong> Many sites prioritize newer listings, so update your posts regularly.</li>
                        <li><strong>Utilize all available fields:</strong> Fill out all information fields to make your listing as complete and attractive as possible.</li>
                        <li><strong>Track your results:</strong> Monitor which sites bring in the best candidates to focus your efforts.</li>
                        <li><strong>Combine with other recruitment strategies:</strong> Use free job boards alongside other methods like employee referrals and social media outreach.</li>
                    </ul>
                </section>

                <section className="prose max-w-none mb-12">
                    <h2 className="text-3xl font-bold mb-6">Conclusion</h2>
                    <p>Free job posting sites offer an excellent opportunity for USA-based recruiters to reach a wide audience of potential candidates without incurring significant costs. By leveraging these platforms effectively and following best practices in job posting, you can attract top talent and streamline your recruitment process.</p>
                    <p>Remember, while these sites are free, your time is valuable. Consider using an applicant tracking system like PrimeApplicants to manage your postings across multiple platforms efficiently, saving you time and helping you find the best candidates faster.</p>
                </section>
            </article>
            {/* Llamado a la acción estándar */}
            <section className="bg-secondary text-secondary-foreground py-20 text-center">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold mb-4">Ready to Optimize Your Job Posting Strategy?</h2>
                    <p className="text-xl mb-8">Let PrimeApplicants help you manage your job postings across multiple free and premium sites.</p>
                    <Button size="lg" asChild>
                        <Link to="/register">Start Your Free Trial Today</Link>
                    </Button>
                </div>
            </section>
        </AnimatedPage>
    );
};

export default FreeJobPostingSitesUSA;