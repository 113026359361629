import React, { useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { generateSecret, verifyAndEnableTwoFactor, verifyToken, disableTwoFactor } from '../../../services/twoFactorService';
import { useAuth } from '../../../contexts/AuthContext';
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "@/components/ui/dialog";
import ErrorAlert from '../../common/ErrorAlert';
import { handleError } from '../../../utils/errorHandler';
import { generateTwoFactorToken, removeTwoFactorToken } from '../../../utils/twoFactorToken';
import { Loader2 } from 'lucide-react';

const TwoFactorSetup = () => {
    const [secret, setSecret] = useState('');
    const [qrCode, setQrCode] = useState('');
    const [token, setToken] = useState('');
    const [error, setError] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDisableModalOpen, setIsDisableModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { user, updateUser } = useAuth();

    const handleGenerateSecret = () => {
        const newSecret = generateSecret();
        setSecret(newSecret);
        setQrCode(`otpauth://totp/PrimeApplicants:${user.email}?secret=${newSecret}&issuer=PrimeApplicants`);
        setIsModalOpen(true);
    };

    const handleEnableTwoFactor = async () => {
        setIsLoading(true);
        try {
            const success = await verifyAndEnableTwoFactor(user.id, token, secret);
            if (success) {
                await generateTwoFactorToken(user.id, true);
                await updateUser({ ...user, twoFactorEnabled: true, twoFactorSecret: secret });
                setIsModalOpen(false);
                setToken('');
            } else {
                setError('Invalid token. Please try again.');
            }
        } catch (error) {
            const { message } = handleError(error);
            setError(message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDisableTwoFactor = async () => {
        setIsLoading(true);
        try {
            const isValid = await verifyToken(token, user.twoFactorSecret);
            if (isValid) {
                await disableTwoFactor(user.id);
                await updateUser({ ...user, twoFactorEnabled: false, twoFactorSecret: null });
                removeTwoFactorToken();
                setIsDisableModalOpen(false);
                setToken('');
            } else {
                setError('Invalid token. Please try again.');
            }
        } catch (error) {
            const { message } = handleError(error);
            setError(message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            {user.twoFactorEnabled ? (
                <div>
                    <p className="mb-4">Two-Factor Authentication is currently enabled.</p>
                    <Button variant="destructive" onClick={() => setIsDisableModalOpen(true)}>Disable 2FA</Button>
                </div>
            ) : (
                <div>
                    <p className="mb-4">Two-Factor Authentication is currently disabled.</p>
                    <Button onClick={handleGenerateSecret}>Enable 2FA</Button>
                </div>
            )}
            <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Set up Two-Factor Authentication</DialogTitle>
                    </DialogHeader>
                    <ErrorAlert message={error} />
                    <p>Scan this QR code with your authenticator app:</p>
                    <div className="flex justify-center my-4">
                        {qrCode && <QRCodeSVG value={qrCode} size={200} />}
                    </div>
                    <p className="mt-4">Or enter this code manually:</p>
                    <p className="font-mono bg-secondary p-2 rounded mt-2 text-center">{secret}</p>
                    <Input
                        type="text"
                        value={token}
                        onChange={(e) => setToken(e.target.value)}
                        placeholder="Enter token"
                        className="mt-4"
                    />
                    <DialogFooter>
                        <Button onClick={handleEnableTwoFactor} disabled={isLoading}>
                            {isLoading ? (
                                <>
                                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                    Enabling...
                                </>
                            ) : (
                                'Enable 2FA'
                            )}
                        </Button>
                        <Button variant="outline" onClick={() => setIsModalOpen(false)}>Cancel</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
            <Dialog open={isDisableModalOpen} onOpenChange={setIsDisableModalOpen}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Disable Two-Factor Authentication</DialogTitle>
                    </DialogHeader>
                    <ErrorAlert message={error} />
                    <p>Please enter your 2FA token to confirm disabling:</p>
                    <Input
                        type="text"
                        value={token}
                        onChange={(e) => setToken(e.target.value)}
                        placeholder="Enter token"
                        className="mt-4"
                    />
                    <DialogFooter>
                        <Button variant="destructive" onClick={handleDisableTwoFactor} disabled={isLoading}>
                            {isLoading ? (
                                <>
                                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                    Disabling...
                                </>
                            ) : (
                                'Disable 2FA'
                            )}
                        </Button>
                        <Button variant="outline" onClick={() => setIsDisableModalOpen(false)}>Cancel</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default TwoFactorSetup;