// src/services/authService.js

import { auth, db } from '../config/firebase.config';
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
    sendEmailVerification,
    sendPasswordResetEmail,
    updatePassword as firebaseUpdatePassword,
    reauthenticateWithCredential,
    EmailAuthProvider,
    setPersistence,
    browserLocalPersistence,
    confirmPasswordReset
} from 'firebase/auth';
import { doc, setDoc, getDoc, updateDoc, collection, query, where, getDocs, runTransaction } from 'firebase/firestore';
import { handleError, isNetworkError } from '../utils/errorHandler';
import { updateUserProfile } from './userService';
import { verifyToken } from './twoFactorService';
import { sendEmail } from './emailService';
import { generateVerificationToken } from '../utils/tokenGenerator';
import { CONTACT_EMAIL } from '../constants/appConstants';
import { SESSION_CONFIG } from '../constants/appConstants';
import { generateTwoFactorToken, verify2FAToken, removeTwoFactorToken } from '../utils/twoFactorToken';

// Función para generar un slug único
const generateUniqueSlug = () => {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
};

export const getTwoFactorVerified = () => {
    return verify2FAToken();
};

export const signUp = async (userData) => {
    const { email, password, firstName, lastName, companyName, jobTitle, companySize, companyWebsite } = userData;

    try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        const verificationToken = generateVerificationToken();
        const slug = generateUniqueSlug();

        const userProfile = {
            email,
            firstName,
            lastName,
            companyName,
            jobTitle,
            companySize,
            companyWebsite,
            createdAt: new Date(),
            twoFactorEnabled: false,
            emailVerified: false,
            verificationToken,
            slug,
            careerPageUrl: `careers/${slug}`,
            lastActivity: new Date().getTime(),
            disabled: false
        };

        await setDoc(doc(db, 'users', user.uid), userProfile);
        await sendVerificationEmail(email, `${firstName} ${lastName}`, verificationToken);

        return { user: { ...userProfile, id: user.uid } };
    } catch (error) {
        throw handleError(error);
    }
};

export const signIn = async (email, password, remember) => {
    try {
        await setPersistence(auth, browserLocalPersistence);

        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        if (!user || !user.uid) {
            throw handleError('User authentication failed');
        }
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (!userDoc.exists()) {
            await auth.signOut();
            throw new Error('User not found');
        }
        const userData = userDoc.data();

        // Verificar si la cuenta está deshabilitada
        if (userData.disabled) {
            // Cerrar sesión inmediatamente si la cuenta está deshabilitada
            await auth.signOut();
            throw new Error('ACCOUNT_DISABLED');
        }

        await updateDoc(doc(db, 'users', user.uid), {
            lastActivity: Date.now(),
            rememberedSession: remember,
        });

        if (!userData.emailVerified) {
            return { requiresEmailVerification: true, userId: user.uid };
        }

        if (userData.twoFactorEnabled) {
            await generateTwoFactorToken(user.uid, false);
            return { requiresTwoFactor: true, userId: user.uid };
        }

        return {
            user: {
                ...userData,
                id: user.uid,
            }
        };
    } catch (error) {
        if (error.message === 'ACCOUNT_DISABLED') {
            throw handleError({ code: 'auth/account-disabled' });
        }
        if (error.message === 'User not found') {
            throw handleError({ code: 'auth/user-not-found' });
        }
        if (isNetworkError(error)) {
            throw handleError({ code: 'network-error' });
        }
        throw handleError(error);
    }
};

export const sendVerificationEmail = async (email, name, token) => {
    const verificationLink = `${process.env.REACT_APP_BASE_URL}/verify-email?token=${token}`;
    const result = await sendEmail({
        from: 'PrimeApplicants <noreply@primeapplicants.com>',
        to: [email],
        subject: 'Verify your email - PrimeApplicants',
        template: 'confirmation email',
        templateVariables: {
            userName: name,
            confirmLink: verificationLink,
            contactEmail: CONTACT_EMAIL
        }
    });
    if (!result) {
        throw new Error('Failed to send verification email');
    }
};

export const verifyEmail = async (token) => {
    try {
        const usersRef = collection(db, 'users');
        const q = query(usersRef, where('verificationToken', '==', token));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
            throw new Error('Invalid verification token');
        }

        const userDoc = querySnapshot.docs[0];
        const userId = userDoc.id;

        await updateDoc(doc(db, 'users', userId), {
            emailVerified: true,
            verificationToken: null
        });

        return { userId };
    } catch (error) {
        throw handleError(error);
    }
};

export const resendVerificationEmail = async (userId) => {
    try {
        const userDoc = await getDoc(doc(db, 'users', userId));
        if (!userDoc.exists()) {
            throw new Error('User not found');
        }
        const userData = userDoc.data();

        // Generate a new verification token
        const newVerificationToken = generateVerificationToken();

        // Update the token in Firestore
        await updateDoc(doc(db, 'users', userId), {
            verificationToken: newVerificationToken
        });

        // Send the new verification email
        await sendVerificationEmail(userData.email, userData.firstName, newVerificationToken);

        return true;
    } catch (error) {
        console.error('Error resending verification email:', error);
        throw handleError(error);
    }
};

export const verifyTwoFactorToken = async (userId, token) => {
    try {
        const userDoc = await getDoc(doc(db, 'users', userId));
        if (!userDoc.exists()) {
            throw new Error('User not found');
        }
        const userData = userDoc.data();
        if (!verifyToken(token, userData.twoFactorSecret)) {
            throw new Error('Invalid 2FA token');
        }
        await generateTwoFactorToken(userId, true);
        return { user: { ...userData, id: userId } };
    } catch (error) {
        if (error.message === 'Invalid 2FA token') {
            throw { code: '2fa/invalid-token', message: 'The two-factor authentication code is invalid. Please try again.' };
        }
        throw handleError(error);
    }
};

export const logOut = async (userId) => {
    try {
        if (userId) {
            await updateUserProfile(userId, { lastActivity: Date.now() });
        }
        removeTwoFactorToken();
        await signOut(auth);
    } catch (error) {
        throw handleError(error);
    }
};

export const updatePassword = async (user, currentPassword, newPassword) => {
    try {
        const credential = EmailAuthProvider.credential(user.email, currentPassword);
        await reauthenticateWithCredential(auth.currentUser, credential);
        await firebaseUpdatePassword(auth.currentUser, newPassword);
    } catch (error) {
        throw handleError(error);
    }
};

export const updateUserActivity = async (userId) => {
    await updateDoc(doc(db, 'users', userId), { lastActivity: Date.now() });
};

export const checkSessionExpiration = async (userId) => {
    try {
        const userDoc = await getDoc(doc(db, 'users', userId));
        if (!userDoc.exists()) {
            throw new Error('User not found');
        }
        const userData = userDoc.data();
        const lastActivity = userData.lastActivity;
        const rememberedSession = userData.rememberedSession;
        const currentTime = Date.now();
        const inactiveTime = currentTime - lastActivity;
        const maxInactiveTime = rememberedSession
            ? SESSION_CONFIG.REMEMBERED_SESSION_EXPIRATION
            : SESSION_CONFIG.NORMAL_SESSION_EXPIRATION;


        if (inactiveTime > maxInactiveTime) {
            return false;
        }
        return true;
    } catch (error) {
        console.error('Error checking session expiration:', error);
        return false;
    }
};

export const resetPassword = async (email) => {
    try {
        await sendPasswordResetEmail(auth, email);
        return true;
    } catch (error) {
        throw handleError(error);
    }
};

export const resetPasswordWithCode = async (oobCode, newPassword) => {
    try {
        await confirmPasswordReset(auth, oobCode, newPassword);
        return true;
    } catch (error) {
        throw handleError(error);
    }
};