import React from 'react';
import { Link } from 'react-router-dom';
import { CalendarDays, Clock, User, ChevronRight, Zap, Users, Briefcase, TrendingUp, Globe, BookOpen } from 'lucide-react';
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { AnimatedPage } from '../../utils/animations';

export const articleMeta = {
    title: "6 Strategies to Recruit the Best Digital Talent",
    description: "Discover effective strategies to attract and retain top digital talent in today's competitive job market.",
    date: "August 25, 2024",
    readTime: "4 min read",
};

const DigitalTalentRecruitment = () => {
    const { title, description, date, readTime } = articleMeta;

    const strategies = [
        {
            icon: Zap,
            title: "Employer Branding",
            description: "Build a strong digital presence that showcases your company culture and values to attract tech-savvy candidates."
        },
        {
            icon: Users,
            title: "Competitive Compensation Packages",
            description: "Offer attractive salaries, benefits, and perks that align with the expectations of digital talent."
        },
        {
            icon: Briefcase,
            title: "Targeted Recruitment Strategies",
            description: "Utilize specialized job boards, tech meetups, and social media platforms to reach digital professionals."
        },
        {
            icon: TrendingUp,
            title: "Professional Development Opportunities",
            description: "Highlight growth prospects, training programs, and cutting-edge projects to appeal to career-driven digital talent."
        },
        {
            icon: Globe,
            title: "Embrace Diversity and Inclusion",
            description: "Foster an inclusive work environment that values diverse perspectives and backgrounds."
        },
        {
            icon: BookOpen,
            title: "Continuous Feedback and Recognition",
            description: "Implement regular feedback mechanisms and recognition programs to keep digital talent engaged and motivated."
        }
    ];

    return (
        <AnimatedPage>
            <article className="max-w-5xl mx-auto px-4 py-12">
                {/* Breadcrumb */}
                <nav className="flex mb-8 text-sm" aria-label="Breadcrumb">
                    <ol className="inline-flex items-center space-x-1 md:space-x-3">
                        <li className="inline-flex items-center">
                            <Link to="/" className="inline-flex items-center text-muted-foreground hover:text-primary">
                                Home
                            </Link>
                        </li>
                        <li>
                            <div className="flex items-center">
                                <ChevronRight className="w-4 h-4 text-muted-foreground" />
                                <Link to="/blog" className="ml-1 text-muted-foreground hover:text-primary md:ml-2">
                                    Blog
                                </Link>
                            </div>
                        </li>
                        <li aria-current="page">
                            <div className="flex items-center">
                                <ChevronRight className="w-4 h-4 text-muted-foreground" />
                                <span className="ml-1 text-primary md:ml-2">Digital Talent Recruitment</span>
                            </div>
                        </li>
                    </ol>
                </nav>

                <header className="mb-12 text-center">
                    <h1 className="text-5xl font-bold mb-6">{title}</h1>
                    <div className="flex items-center justify-center text-muted-foreground mb-6">
                        <User className="mr-2 h-5 w-5" />
                        <span className="mr-4">PrimeApplicants Team</span>
                        <CalendarDays className="mr-2 h-5 w-5" />
                        <span className="mr-4">{date}</span>
                        <Clock className="mr-2 h-5 w-5" />
                        <span>{readTime}</span>
                    </div>
                    <p className="text-xl text-muted-foreground max-w-3xl mx-auto">{description}</p>
                </header>

                <section className="prose max-w-none mb-12">
                    <p>In the rapidly evolving digital landscape, attracting and retaining top digital talent has become a critical challenge for organizations across industries. With the demand for digital skills skyrocketing, companies need to adopt innovative strategies to stand out and appeal to the best candidates in the tech world.</p>
                    <p>This article explores six proven strategies that can help you recruit and retain the cream of the digital talent crop, ensuring your organization stays competitive in the digital age.</p>
                </section>

                <section className="mb-16">
                    <h2 className="text-3xl font-bold mb-8">Key Strategies for Recruiting Digital Talent</h2>
                    <div className="grid md:grid-cols-2 gap-8">
                        {strategies.map((strategy, index) => (
                            <Card key={index} className="hover:shadow-lg transition-shadow duration-300">
                                <CardHeader>
                                    <strategy.icon className="h-12 w-12 text-primary mb-4" />
                                    <CardTitle className="text-2xl mb-2">{strategy.title}</CardTitle>
                                </CardHeader>
                                <CardContent>
                                    <p>{strategy.description}</p>
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                </section>

                {/* Banner de llamado a la acción adicional */}
                <section className="bg-primary text-primary-foreground py-12 px-8 rounded-lg mb-16">
                    <h2 className="text-3xl font-bold mb-4">Streamline Your Digital Talent Recruitment</h2>
                    <p className="mb-6">Use PrimeApplicants to implement these strategies effectively and attract top digital talent. Start your free trial today and transform your recruitment process.</p>
                    <Button asChild size="lg" variant="secondary">
                        <Link to="/register">Start Free Trial</Link>
                    </Button>
                </section>

                <section className="prose max-w-none mb-12">
                    <h2 className="text-3xl font-bold mb-6">Implementing These Strategies Effectively</h2>
                    <p>To make the most of these strategies, consider the following tips:</p>
                    <ul className="list-disc pl-5 space-y-2">
                        <li><strong>Understand your target audience:</strong> Research the preferences and motivations of digital professionals in your industry.</li>
                        <li><strong>Leverage technology:</strong> Use AI-powered recruitment tools to streamline your hiring process and improve candidate matching.</li>
                        <li><strong>Showcase your tech stack:</strong> Highlight the innovative technologies and tools your team uses to attract tech-savvy candidates.</li>
                        <li><strong>Offer flexible work arrangements:</strong> Many digital professionals value work-life balance and the option to work remotely.</li>
                        <li><strong>Create a strong online presence:</strong> Maintain active social media profiles and a compelling careers page to attract digital talent.</li>
                        <li><strong>Partner with educational institutions:</strong> Build relationships with universities and coding bootcamps to access fresh digital talent.</li>
                    </ul>
                </section>

                <section className="prose max-w-none mb-12">
                    <h2 className="text-3xl font-bold mb-6">The Future of Digital Talent Recruitment</h2>
                    <p>As the digital landscape continues to evolve, so too will the strategies for recruiting top talent. Stay ahead of the curve by:</p>
                    <ul className="list-disc pl-5 space-y-2">
                        <li>Embracing emerging technologies like VR and AR in your recruitment process</li>
                        <li>Focusing on soft skills alongside technical abilities</li>
                        <li>Offering continuous learning opportunities to keep your digital talent engaged and up-to-date</li>
                        <li>Creating a strong employer brand that resonates with the values of digital professionals</li>
                        <li>Leveraging data analytics to refine and optimize your recruitment strategies over time</li>
                    </ul>
                </section>

                <section className="prose max-w-none mb-12">
                    <h2 className="text-3xl font-bold mb-6">Conclusion</h2>
                    <p>Recruiting top digital talent is crucial for organizations looking to thrive in the digital age. By implementing these six strategies and staying adaptable to the changing landscape, you can position your company as an employer of choice for digital professionals.</p>
                    <p>Remember, the key to successful digital talent recruitment lies in understanding the unique needs and motivations of tech-savvy candidates and creating an environment where they can thrive and grow.</p>
                </section>
            </article>
            {/* Llamado a la acción estándar */}
            <section className="bg-secondary text-secondary-foreground py-20 text-center">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold mb-4">Ready to Revolutionize Your Digital Talent Recruitment?</h2>
                    <p className="text-xl mb-8">Try PrimeApplicants and leverage our AI-powered tools to attract and retain the best digital professionals.</p>
                    <Button size="lg" asChild>
                        <Link to="/register">Start Your Free Trial Today</Link>
                    </Button>
                </div>
            </section>
        </AnimatedPage>
    );
};

export default DigitalTalentRecruitment;