import React, { createContext, useState, useEffect, useContext, useCallback } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { initializeFirebase, getFirebaseAuth } from '../config/firebase.config';
import * as authService from '../services/authService';
import { updateUserProfile, getUserProfile } from '../services/userService';
import { handleError } from '../utils/errorHandler';
import { verify2FAToken } from '../utils/twoFactorToken';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [pendingTwoFactor, setPendingTwoFactor] = useState(false);
    const [verificationEmailSent, setVerificationEmailSent] = useState(false);

    useEffect(() => {
        const initAuth = async () => {
            await initializeFirebase();
            const auth = getFirebaseAuth();
            
            const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
                if (firebaseUser) {
                    try {
                        const userProfile = await getUserProfile(firebaseUser.uid);
                        const updatedUser = {
                            ...userProfile,
                            id: firebaseUser.uid,
                            emailVerified: firebaseUser.emailVerified || userProfile.emailVerified,
                        };

                        setUser(updatedUser);
                        setPendingTwoFactor(false);
                    } catch (error) {
                        console.error('Error fetching user profile:', error);
                        setUser(null);
                        setPendingTwoFactor(false);
                    }
                } else {
                    setUser(null);
                    setPendingTwoFactor(false);
                }
                setLoading(false);
            });

            return () => unsubscribe();
        };

        initAuth();
    }, []);

    const updateUserActivity = useCallback(async () => {
        if (user?.id) {
            try {
                await authService.updateUserActivity(user.id);
            } catch (error) {
                console.error('Error updating user activity:', error);
            }
        }
    }, [user]);

    const logOut = async (userId) => {
        try {
            await authService.logOut(userId);
            setUser(null);
        } catch (error) {
            console.error('Logout error:', error);
        } finally {
            setLoading(false);
        }
    };

    const checkSessionExpiration = useCallback(async () => {
        if (user?.id) {
            try {
                const isSessionValid = await authService.checkSessionExpiration(user.id);
                return isSessionValid;
            } catch (error) {
                console.error('Error checking session expiration:', error);
                return false;
            }
        }
        return false;
    }, [user, logOut]);

    const signUp = async (userData) => {
        try {
            const result = await authService.signUp(userData);
            setUser(result.user);
            return result;
        } catch (error) {
            throw handleError(error);
        } finally {
            setLoading(false);
        }
    };

    const signIn = async (email, password, remember) => {
        try {
            const result = await authService.signIn(email, password, remember);
            if (result.requiresEmailVerification) {
                return { requiresEmailVerification: true, userId: result.userId };
            }
            if (result.requiresTwoFactor) {
                return { requiresTwoFactor: true, userId: result.userId };
            }
            setUser(result.user);
            setPendingTwoFactor(false);
            return { user: result.user };
        } catch (error) {
            throw handleError(error);
        } finally {
            setLoading(false);
        }
    };

    const verifyTwoFactorToken = async (userId, token) => {
        try {
            const result = await authService.verifyTwoFactorToken(userId, token);
            setUser(result.user);
            setPendingTwoFactor(false);
            return result.user;
        } catch (error) {
            const handledError = handleError(error);
            throw handledError;
        }
    };

    const updatePassword = (currentPassword, newPassword) => {
        return authService.updatePassword(user, currentPassword, newPassword);
    };

    const updateUser = async (userData) => {
        try {
            await updateUserProfile(user.id, userData);
            setUser({ ...user, ...userData });
        } catch (error) {
            throw handleError(error);
        } finally {
            setLoading(false);
        }
    };

    const sendVerificationEmail = async (email, name, token) => {
        await authService.sendVerificationEmail(email, name, token);
        setVerificationEmailSent(true);
    };

    const resendVerificationEmail = async () => {
        if (user && user.id) {
            try {
                await authService.resendVerificationEmail(user.id);
                return true;
            } catch (error) {
                console.error('Error resending verification email:', error);
                throw handleError(error);
            }
        } else {
            throw new Error('No user is currently signed in');
        }
    };

    const resetPassword = async (email) => {
        try {
            await authService.resetPassword(email);
            return true;
        } catch (error) {
            throw handleError(error);
        }
    };

    const resetPasswordWithCode = async (oobCode, newPassword) => {
        try {
            await authService.resetPasswordWithCode(oobCode, newPassword);
            return true;
        } catch (error) {
            throw handleError(error);
        }
    };

    const value = {
        user,
        loading,
        pendingTwoFactor,
        signUp,
        signIn,
        verifyTwoFactorToken,
        logOut,
        updatePassword,
        updateUser,
        setUser,
        getUserProfile,
        sendVerificationEmail,
        verificationEmailSent,
        updateUserActivity,
        checkSessionExpiration,
        resendVerificationEmail,
        verify2FAToken,
        resetPassword,
        resetPasswordWithCode
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
};