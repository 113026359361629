import React from 'react';
import { Link } from 'react-router-dom';
import { CalendarDays, Clock, User, CheckCircle, ArrowRight, ChevronRight } from 'lucide-react';
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { AnimatedPage } from '../../utils/animations';

export const articleMeta = {
    title: "How to Write Job Descriptions That Attract Top Talent",
    description: "Master the art of crafting compelling job descriptions that resonate with high-quality candidates and elevate your recruitment efforts.",
    date: "August 9, 2024",
    readTime: "4 min read",
};

const EffectiveJobDescriptionsArticle = () => {
    const { title, description, date, readTime } = articleMeta;

    const tips = [
        {
            title: "Use Clear and Concise Language",
            description: "Avoid jargon and write in a clear, straightforward manner that resonates with your target candidates.",
            details: "Use industry-specific terms where appropriate, but ensure the overall language is accessible. Break down complex ideas into simple, easy-to-understand concepts."
        },
        {
            title: "Highlight Company Culture",
            description: "Showcase your company's values, mission, and work environment to attract candidates who align with your organizational culture.",
            details: "Include information about team dynamics, company traditions, and any unique perks or benefits that reflect your culture."
        },
        {
            title: "Be Specific About Requirements",
            description: "Clearly outline necessary skills, qualifications, and experience required for the role.",
            details: "Distinguish between 'must-have' and 'nice-to-have' requirements to encourage a diverse range of qualified candidates to apply."
        },
        {
            title: "Include Salary Range",
            description: "Transparency about compensation attracts serious candidates and saves time for both parties.",
            details: "If providing a specific range isn't possible, consider including information about your overall compensation philosophy or structure."
        },
        {
            title: "Detail Day-to-Day Responsibilities",
            description: "Give a realistic preview of the role's duties to help candidates understand what the job truly entails.",
            details: "Use action verbs and be specific about tasks, projects, and expectations. This helps candidates envision themselves in the role."
        },
        {
            title: "Use SEO-Friendly Keywords",
            description: "Incorporate relevant industry terms to improve visibility in job search engines and attract more qualified candidates.",
            details: "Research common search terms used by job seekers in your industry and naturally incorporate them throughout the description."
        },
        {
            title: "Emphasize Growth Opportunities",
            description: "Highlight potential for career advancement and skill development within your organization.",
            details: "Mention specific training programs, mentorship opportunities, or paths for progression to appeal to ambitious candidates."
        },
        {
            title: "Customize for Your Target Audience",
            description: "Tailor the tone and content to appeal to your ideal candidates, considering factors like experience level and industry norms.",
            details: "Adjust your language and focus based on whether you're targeting entry-level graduates, experienced professionals, or executive-level candidates."
        }
    ];

    return (
        <AnimatedPage>
            <article className="max-w-5xl mx-auto px-4 py-12">
                {/* Breadcrumb */}
                <nav className="flex mb-8 text-sm" aria-label="Breadcrumb">
                    <ol className="inline-flex items-center space-x-1 md:space-x-3">
                        <li className="inline-flex items-center">
                            <Link to="/" className="inline-flex items-center text-muted-foreground hover:text-primary">
                                Home
                            </Link>
                        </li>
                        <li>
                            <div className="flex items-center">
                                <ChevronRight className="w-4 h-4 text-muted-foreground" />
                                <Link to="/blog" className="ml-1 text-muted-foreground hover:text-primary md:ml-2">
                                    Blog
                                </Link>
                            </div>
                        </li>
                        <li aria-current="page">
                            <div className="flex items-center">
                                <ChevronRight className="w-4 h-4 text-muted-foreground" />
                                <span className="ml-1 text-primary md:ml-2">Effective Job Descriptions</span>
                            </div>
                        </li>
                    </ol>
                </nav>

                <header className="mb-12 text-center">
                    <h1 className="text-5xl font-bold mb-6">{title}</h1>
                    <div className="flex items-center justify-center text-muted-foreground mb-6">
                        <User className="mr-2 h-5 w-5" />
                        <span className="mr-4">PrimeApplicants Team</span>
                        <CalendarDays className="mr-2 h-5 w-5" />
                        <span className="mr-4">{date}</span>
                        <Clock className="mr-2 h-5 w-5" />
                        <span>{readTime}</span>
                    </div>
                    <p className="text-xl text-muted-foreground max-w-3xl mx-auto">{description}</p>
                </header>

                <section className="prose max-w-none mb-12">
                    <p>In today's competitive job market, a well-crafted job description is your first opportunity to make a lasting impression on potential candidates. It's not just about listing requirements and responsibilities; it's about telling a story that captures the essence of the role and your company culture.</p>
                    <p>This comprehensive guide will walk you through the process of creating job descriptions that not only accurately represent the position but also appeal to the best candidates in your industry. Whether you're a seasoned recruiter or new to the hiring process, these tips and strategies will help you craft job descriptions that stand out and attract the right talent for your organization.</p>
                </section>

                <section className="mb-16">
                    <h2 className="text-3xl font-bold mb-8">Key Tips for Effective Job Descriptions</h2>
                    <div className="grid md:grid-cols-2 gap-8">
                        {tips.map((tip, index) => (
                            <Card key={index} className="hover:shadow-lg transition-shadow duration-300">
                                <CardContent className="p-6">
                                    <h3 className="text-2xl font-semibold mb-4 flex items-center">
                                        <CheckCircle className="mr-3 h-6 w-6 text-green-500 flex-shrink-0" />
                                        {tip.title}
                                    </h3>
                                    <p className="mb-4">{tip.description}</p>
                                    <p className="text-muted-foreground">{tip.details}</p>
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                </section>

                {/* Banner de llamado a la acción adicional */}
                <section className="bg-primary text-primary-foreground py-12 px-8 rounded-lg mb-16">
                    <h2 className="text-3xl font-bold mb-4">Elevate Your Job Descriptions</h2>
                    <p className="mb-6">Use PrimeApplicants to create compelling job descriptions that attract top talent. Try our platform and see the difference in your recruitment results.</p>
                    <Button asChild size="lg" variant="secondary">
                        <Link to="/register">Start Free Trial</Link>
                    </Button>
                </section>

                <section className="prose max-w-none mb-12">
                    <h2 className="text-3xl font-bold mb-6">Best Practices for Job Description Writing</h2>
                    <ul className="list-disc pl-5 space-y-4">
                        <li><strong>Start with a compelling job title:</strong> Use clear, industry-standard titles that accurately reflect the role and are optimized for search engines.</li>
                        <li><strong>Use a clear structure:</strong> Organize your job description with clear headings for different sections like "About Us," "Responsibilities," "Requirements," and "Benefits."</li>
                        <li><strong>Incorporate your employer brand:</strong> Weave in elements of your company's unique culture, values, and mission throughout the description.</li>
                        <li><strong>Be inclusive:</strong> Use gender-neutral language and focus on essential qualifications to encourage a diverse range of applicants.</li>
                        <li><strong>Highlight the impact:</strong> Explain how the role contributes to the company's goals and makes a difference.</li>
                        <li><strong>Address potential concerns:</strong> Anticipate and address common questions candidates might have about the role or company.</li>
                        <li><strong>Include a call-to-action:</strong> Clearly state the next steps for interested candidates and make the application process easy to understand.</li>
                        <li><strong>Regularly review and update:</strong> Keep your job descriptions current, reflecting any changes in the role or company over time.</li>
                    </ul>
                </section>

                <section className="prose max-w-none mb-12">
                    <h2 className="text-3xl font-bold mb-6">The Impact of Well-Crafted Job Descriptions</h2>
                    <p>Investing time and effort into creating compelling job descriptions can yield significant benefits for your recruitment process:</p>
                    <ul>
                        <li><strong>Attract higher quality candidates:</strong> Clear, detailed descriptions help candidates self-select, increasing the likelihood of attracting those who are truly qualified and interested.</li>
                        <li><strong>Improve efficiency:</strong> Well-written descriptions can reduce the number of unqualified applicants, saving time in the screening process.</li>
                        <li><strong>Enhance your employer brand:</strong> Thoughtful, engaging job descriptions reflect positively on your company, improving overall perception among job seekers.</li>
                        <li><strong>Set clear expectations:</strong> Detailed descriptions help align candidate expectations with the reality of the role, potentially improving retention rates.</li>
                        <li><strong>Facilitate better interviews:</strong> A comprehensive job description provides a solid foundation for structuring interviews and evaluating candidates.</li>
                    </ul>
                </section>

                <section className="prose max-w-none mb-12">
                    <h2 className="text-3xl font-bold mb-6">Conclusion</h2>
                    <p>Crafting effective job descriptions is both an art and a science. By following these tips and best practices, you can create compelling job postings that attract top talent and set the stage for successful hiring.</p>
                    <p>Remember, a great job description is often the first point of contact between your company and potential candidates. Make it count by being clear, engaging, and authentic.</p>
                </section>
            </article>
            {/* Llamado a la acción estándar actualizado */}
            <section className="bg-secondary text-secondary-foreground py-20 text-center">
                <div className="mx-auto px-4">
                    <h2 className="text-3xl font-bold mb-4">Ready to Elevate Your Job Descriptions?</h2>
                    <p className="text-xl mb-8">Start attracting top talent with PrimeApplicants' powerful tools and insights.</p>
                    <Button size="lg" asChild>
                        <Link to="/register">Start Your Free Trial Today</Link>
                    </Button>
                </div>
            </section>
        </AnimatedPage>
    );
};

export default EffectiveJobDescriptionsArticle;