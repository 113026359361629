import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { Button } from "@/components/ui/button";
import ThemeToggle from "../common/ThemeToggle";
import {
    HomeIcon, BriefcaseIcon, UsersIcon, ClipboardListIcon,
    CalendarIcon, CogIcon, LogOutIcon, UserIcon, MenuIcon
} from 'lucide-react';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

const Navbar = () => {
    const { logOut, user } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const handleLogout = async () => {
        try {
            await logOut(user.id);
            navigate('/login');
        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    const navItems = [
        { name: 'Dashboard', icon: HomeIcon, path: '/dashboard' },
        { name: 'Jobs', icon: BriefcaseIcon, path: '/jobs' },
        { name: 'Candidates', icon: UsersIcon, path: '/candidates' },
        { name: 'Recruitment', icon: ClipboardListIcon, path: '/recruitment' },
        { name: 'Interviews', icon: CalendarIcon, path: '/interviews' },
    ];

    return (
        <nav className="bg-background border-b border-border">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16">
                    <div className="flex items-center">
                        <Link to="/dashboard">
                            <img src="/assets/img/logoBlack.png" alt="PrimeApplicants" className="h-6 w-auto sm:h-8 md:h-8 lg:h-9 dark:hidden" />
                            <img src="/assets/img/logoWhite.png" alt="PrimeApplicants" className="h-6 w-auto sm:h-8 md:h-8 lg:h-9 hidden dark:block" />
                        </Link>
                        <div className="hidden md:ml-10 md:flex md:items-baseline md:space-x-4">
                            {navItems.map((item) => (
                                <Link
                                    key={item.path}
                                    to={item.path}
                                    className={`flex items-center px-3 py-2 rounded-md text-sm font-medium ${
                                        location.pathname === item.path
                                            ? 'bg-primary text-primary-foreground'
                                            : 'text-foreground hover:bg-accent hover:text-accent-foreground'
                                    }`}
                                >
                                    <item.icon className="mr-1.5 h-5 w-5" />
                                    {item.name}
                                </Link>
                            ))}
                        </div>
                    </div>
                    <div className="flex items-center">
                        <ThemeToggle />
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <Button variant="ghost" size="icon" className="ml-4">
                                    <UserIcon className="h-5 w-5" />
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent align="end">
                                <DropdownMenuItem className="text-sm font-medium text-muted-foreground">
                                    {user.companyName}
                                </DropdownMenuItem>
                                <DropdownMenuSeparator />
                                <DropdownMenuItem 
                                    onClick={() => navigate('/profile/settings')}
                                    className="cursor-pointer hover:bg-accent hover:text-accent-foreground"
                                >
                                    <CogIcon className="mr-2 h-4 w-4" />
                                    Settings
                                </DropdownMenuItem>
                                <DropdownMenuItem 
                                    onClick={handleLogout}
                                    className="cursor-pointer hover:bg-accent hover:text-accent-foreground"
                                >
                                    <LogOutIcon className="mr-2 h-4 w-4" />
                                    Log out
                                </DropdownMenuItem>
                            </DropdownMenuContent>
                        </DropdownMenu>
                        <div className="md:hidden">
                            <Button variant="ghost" size="icon" className="ml-4" onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                                <MenuIcon className="h-5 w-5" />
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Menú móvil */}
            {mobileMenuOpen && (
                <div className="md:hidden">
                    <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                        {navItems.map((item) => (
                            <Link
                                key={item.path}
                                to={item.path}
                                className={`block px-3 py-2 rounded-md text-base font-medium ${
                                    location.pathname === item.path
                                        ? 'bg-primary text-primary-foreground'
                                        : 'text-foreground hover:bg-accent hover:text-accent-foreground'
                                }`}
                                onClick={() => setMobileMenuOpen(false)}
                            >
                                <item.icon className="inline-block mr-1.5 h-5 w-5" />
                                {item.name}
                            </Link>
                        ))}
                    </div>
                </div>
            )}
        </nav>
    );
};

export default Navbar;