import React, { useState, useEffect } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useTheme } from 'next-themes';
import ThemeToggle from "../common/ThemeToggle";
import { Button } from "@/components/ui/button";
import SupportChat from "../common/SupportChat";
import { Menu } from 'lucide-react';

const GeneralPublicLayout = () => {
    const { theme } = useTheme();
    const location = useLocation();
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    const navItems = ['Home', 'Services', 'Integrations', 'Resources', 'Pricing', 'FAQ', 'Contact'];

    return (
        <div className="flex flex-col min-h-screen bg-background">
            <header className="border-b border-border">
                <div className="container mx-auto px-4">
                    <nav className="flex items-center justify-between py-4">
                        <Link to="/" className="flex-shrink-0">
                            <img
                                src={theme === 'dark' ? "/assets/img/logoWhite.png" : "/assets/img/logoBlack.png"}
                                alt="PrimeApplicants"
                                className="h-6 w-auto sm:h-8 md:h-8 lg:h-9"
                            />
                        </Link>
                        <div className="hidden lg:flex space-x-4">
                            {navItems.map((item) => (
                                <Link key={item} to={item === 'Home' ? '/' : `/${item.toLowerCase()}`} className="text-foreground hover:text-primary">
                                    {item}
                                </Link>
                            ))}
                        </div>
                        <div className="flex items-center space-x-4">
                            <div className="hidden lg:block">
                                <Button asChild variant="default">
                                    <Link to="/login">Login / Register</Link>
                                </Button>
                            </div>
                            <ThemeToggle />
                            <Button
                                variant="ghost"
                                size="icon"
                                className="lg:hidden"
                                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                            >
                                <Menu className="h-5 w-5" />
                            </Button>
                        </div>
                    </nav>
                </div>
            </header>

            {/* Menú móvil */}
            {mobileMenuOpen && (
                <div className="lg:hidden bg-background border-b border-border">
                    <div className="container mx-auto px-4 py-2 space-y-2">
                        {navItems.map((item) => (
                            <Link
                                key={item}
                                to={item === 'Home' ? '/' : `/${item.toLowerCase()}`}
                                className="block py-2 text-foreground hover:text-primary"
                                onClick={() => setMobileMenuOpen(false)}
                            >
                                {item}
                            </Link>
                        ))}
                        <Button asChild variant="default" className="w-full">
                            <Link to="/login" onClick={() => setMobileMenuOpen(false)}>Login / Register</Link>
                        </Button>
                    </div>
                </div>
            )}

            <main className="flex-grow">
                <Outlet />
            </main>

            <footer className="bg-primary text-primary-foreground">
                <div className="container mx-auto px-6 py-8">
                    <div className="grid grid-cols-1 md:grid-cols-5 gap-8">
                        <div>
                            <img src={theme === 'dark' ? "/assets/img/logoBlack.png" : "/assets/img/logoWhite.png"}
                                alt="PrimeApplicants"
                                className="h-6 w-auto sm:h-6 md:h-5 lg:h-7 mb-2" />
                            <p className="text-sm">Simplifying your hiring process.</p>
                        </div>
                        {[
                            {
                                title: 'Quick Links', links: [
                                    { name: 'Services', path: '/services' },
                                    { name: 'Integrations', path: '/integrations' },
                                    { name: 'Resources', path: '/resources' },
                                    { name: 'Pricing', path: '/pricing' },
                                    { name: 'FAQ', path: '/faq' },
                                    { name: 'Contact', path: '/contact' }
                                ]
                            },
                            {
                                title: 'Resources', links: [
                                    { name: 'Best Practices', path: '/resources/best-practices' },
                                    { name: 'Employer Branding', path: '/resources/employer-branding' },
                                    { name: 'Interview Strategies', path: '/resources/interview-strategies' },
                                    { name: 'Talent Analytics', path: '/resources/talent-analytics' },
                                    { name: 'Blog', path: '/blog' }
                                ]
                            },
                            {
                                title: 'Legal', links: [
                                    { name: 'Privacy Policy', path: '/privacy-policy' },
                                    { name: 'Terms of Service', path: '/terms-of-service' }
                                ]
                            },
                        ].map((section, index) => (
                            <div key={index}>
                                <h3 className="text-lg font-bold mb-2">{section.title}</h3>
                                <ul className="text-sm space-y-1">
                                    {section.links.map((link) => (
                                        <li key={link.name}>
                                            <Link to={link.path} className="hover:underline">
                                                {link.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                        <div>
                            <h3 className="text-lg font-bold mb-2">Connect with Us</h3>
                            <p className="text-sm mb-2">Email: info@primeapplicants.com</p>
                        </div>
                    </div>
                    <div className="mt-8 text-center text-sm">
                        <p>&copy; {new Date().getFullYear()} JA Media LLC. All rights reserved.</p>
                    </div>
                </div>
            </footer>
            <SupportChat />
        </div>
    );
};

export default GeneralPublicLayout;