import React from 'react';
import { Link } from 'react-router-dom';
import {
    PieChart,
    Clock,
    DollarSign,
    Users,
    TrendingUp,
    Zap,
    BarChart2,
    LineChart
} from 'lucide-react';
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { AnimatedPage } from '../../utils/animations';

const TalentAnalyticsPage = () => {
    const keyMetrics = [
        {
            icon: Clock,
            title: "Time-to-Hire",
            description: "The average time it takes to fill a position from job posting to offer acceptance."
        },
        {
            icon: DollarSign,
            title: "Cost-per-Hire",
            description: "The total cost associated with hiring a new employee."
        },
        {
            icon: Users,
            title: "Offer Acceptance Rate",
            description: "The percentage of job offers that are accepted by candidates."
        },
        {
            icon: TrendingUp,
            title: "Quality of Hire",
            description: "A measure of how well new hires meet the job requirements and contribute to the company."
        }
    ];

    const analyticsBenefits = [
        {
            icon: PieChart,
            title: "Predictive Hiring",
            description: "Use historical data to predict which candidates are most likely to succeed in a role."
        },
        {
            icon: Users,
            title: "Improved Diversity",
            description: "Track and improve diversity metrics throughout the hiring process."
        },
        {
            icon: Zap,
            title: "Optimized Sourcing",
            description: "Identify the most effective channels for finding quality candidates."
        },
        {
            icon: LineChart,
            title: "Enhanced Decision Making",
            description: "Make data-driven decisions to improve overall recruitment strategies."
        }
    ];

    return (
        <AnimatedPage>
            <div className="space-y-0">
                {/* Hero Section */}
                <section className="bg-gradient-to-tl from-primary to-muted-foreground text-primary-foreground py-20">
                    <div className="container mx-auto px-4 text-center">
                        <h1 className="text-5xl font-bold mb-4">Talent Analytics</h1>
                        <p className="text-xl mb-8 max-w-2xl mx-auto">
                            Harness the power of data to transform your recruitment process and make informed hiring decisions.
                        </p>
                        <div className="space-x-4">
                            <Button asChild>
                                <Link to="/register">Start Free Trial</Link>
                            </Button>
                            <Button variant="secondary" asChild>
                                <Link to="/services">Explore Our Services</Link>
                            </Button>
                        </div>
                    </div>
                </section>

                {/* Key Metrics Section */}
                <section className="py-20">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold text-center mb-12">Key Recruitment Metrics</h2>
                        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
                            {keyMetrics.map((metric, index) => (
                                <Card key={index}>
                                    <CardHeader>
                                        <metric.icon className="h-8 w-8 mb-2" />
                                        <CardTitle>{metric.title}</CardTitle>
                                    </CardHeader>
                                    <CardContent>
                                        <p>{metric.description}</p>
                                    </CardContent>
                                </Card>
                            ))}
                        </div>
                    </div>
                </section>

                {/* Analytics Benefits Section */}
                <section className="bg-muted py-20">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold text-center mb-12">How Analytics Improves Recruitment</h2>
                        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
                            {analyticsBenefits.map((benefit, index) => (
                                <Card key={index}>
                                    <CardHeader>
                                        <benefit.icon className="h-8 w-8 mb-2" />
                                        <CardTitle>{benefit.title}</CardTitle>
                                    </CardHeader>
                                    <CardContent>
                                        <p>{benefit.description}</p>
                                    </CardContent>
                                </Card>
                            ))}
                        </div>
                    </div>
                </section>

                {/* How PrimeApplicants Helps Section */}
                <section className="py-20">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold text-center mb-12">How PrimeApplicants Powers Your Talent Analytics</h2>
                        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
                            {[
                                { title: "Comprehensive Dashboards", description: "Access real-time analytics dashboards that provide a holistic view of your recruitment process." },
                                { title: "Custom Reporting", description: "Generate tailored reports to track the metrics that matter most to your organization." },
                                { title: "Predictive Analytics", description: "Leverage machine learning to predict hiring outcomes and optimize your recruitment strategies." },
                                { title: "Data Integration", description: "Seamlessly integrate data from multiple sources to provide a comprehensive view of your talent pipeline." }
                            ].map((feature, index) => (
                                <Card key={index}>
                                    <CardHeader>
                                        <CardTitle>{feature.title}</CardTitle>
                                    </CardHeader>
                                    <CardContent>
                                        <p>{feature.description}</p>
                                    </CardContent>
                                </Card>
                            ))}
                        </div>
                    </div>
                </section>

                {/* CTA Section */}
                <section className="bg-secondary text-secondary-foreground py-20 text-center">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold mb-4">Transform Your Recruitment with Data-Driven Insights</h2>
                        <p className="text-xl mb-8">Experience how PrimeApplicants can elevate your talent analytics and improve your hiring decisions.</p>
                        <Button size="lg" asChild>
                            <Link to="/register">Start Your Free Trial Today</Link>
                        </Button>
                    </div>
                </section>
            </div>
        </AnimatedPage>
    );
};

export default TalentAnalyticsPage;